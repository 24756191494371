import {
  Component,
  Input,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from '@angular/core'
import { format } from 'date-fns'

import { WidgetComponent } from '../../view'
import { RowField } from '../../config'

/**
 * can use for: date, datetime
 */
@Component({
  selector: 'bp--preset--search-date-widget',
  template: `
    <nz-range-picker
      [ngModel]="dateRange"
      (ngModelChange)="dateChange($event)"
    ></nz-range-picker>
  `,
})
export class BpSearchDateWidget extends WidgetComponent implements OnChanges {
  static meta = {
    type: 'preset_search',
    name: 'date',
  }

  @Input() value: string
  @Input() field: RowField

  change = new EventEmitter<string>()
  dateRange: Date[] | string[] = []

  ngOnChanges(changes: SimpleChanges) {
    if (changes.value) {
      const currentValue = changes.value.currentValue
      this.dateRange = currentValue ? currentValue.split(',') : []
    }
  }

  dateChange(dates: Date[]) {
    this.change.emit(
      dates[0]
        ? dates.map(date => format(date, 'yyyy-MM-dd')).toString()
        : null,
    )
  }
}
