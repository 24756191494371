import { Pipe, PipeTransform } from '@angular/core'
import artTemplate from 'art-template/lib/template-web.js'

artTemplate.defaults.escape = false
artTemplate.defaults.minimize = false

const templates = []

const findTemplateCache = function(tplStr) {
  return templates.find(tpl => tpl.str === tplStr)
}
const transform = function(str = '', data) {
  str = str.trim()

  const cache = findTemplateCache(str)
  let render = null

  try {
    if (cache) return cache.render(data)

    render = artTemplate.compile(str)
    templates.push({
      str,
      render,
      renderError: false,
    })
    return render(data)
  } catch (e) {
    console.warn(e)
    return '暂无数据'
  }
}

@Pipe({
  name: 'template',
})
export class TemplatePipe implements PipeTransform {
  static transform = transform
  transform = transform
}
