import {
  Component,
  Input,
  OnInit,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from '@angular/core'

import { WidgetComponent } from '../../view'
import { RowField } from '../../config'

/**
 * can use for: checkbox
 */
@Component({
  selector: 'bp--preset--form-checkbox-widget',
  template: `
    <nz-checkbox-group
      [nzDisabled]="readonly"
      [ngModel]="checkbox"
      (ngModelChange)="onChange($event)"
    ></nz-checkbox-group>
  `,
})
export class BpFormCheckboxWidget
  extends WidgetComponent implements OnInit, OnChanges {
  static meta = {
    type: 'preset_form',
    name: 'checkbox',
  }

  @Input() entity: any
  @Input() value: string[]
  @Input() field: RowField

  change = new EventEmitter<string[]>()
  readonly = false
  checkbox: Checkbox[] = []

  ngOnChanges(changes: SimpleChanges) {
    if (changes.value) this._updateChekcbox()
  }

  ngOnInit() {
    this.readonly = this.field.feature.includes('readonly')
    this.checkbox = Object.entries(this.field.extra || {}).map(
      ([key, val]: [string, string]) => {
        return {
          label: val,
          value: key,
          checked: false,
        }
      },
    )

    this._updateChekcbox()
  }

  onChange(value: Checkbox[]) {
    const checked = value.filter(v => v.checked).map(val => val.value)

    this.change.emit(checked)
  }

  private _updateChekcbox() {
    if (!this.value) return

    this.checkbox.forEach(checkbox => {
      checkbox.checked = this.value.includes(checkbox.value)
    })
  }
}

interface Checkbox {
  label: string
  value: string
  checked: boolean
}
