import { ComponentFactory, Type, ComponentFactoryResolver } from '@angular/core'

import { Entity } from '../util/entity'
import { DynamicComponent } from './definition'

export interface DynamicComponentFactory {
  meta: {
    type?: string
    locations?: string[]
    name: string
  }
  factory: ComponentFactory<any>
}

export class DynamicComponentLoader {
  private _factories = new Entity<DynamicComponentFactory>([], {
    selectId: data => data.factory.selector,
  })

  constructor(private _resolver: ComponentFactoryResolver) {}

  loadComponents(components: Type<any>[]) {
    components.forEach(c => {
      const componentFactory = this._resolver.resolveComponentFactory(c)

      this._saveFactory(componentFactory)
    })
  }

  get(type: string, name: string) {
    return this._getByMeta(type, name)
  }

  getBySelector(selector) {
    return this._factories.getOne(selector)
  }

  getAll() {
    return this._factories.getAll()
  }

  getByType(type: string) {
    return this._factories.getAll().filter(f => f.meta.type === type)
  }

  private _saveFactory(f: ComponentFactory<any>) {
    const _componentType = f.componentType as typeof DynamicComponent
    const { type, name, locations } = _componentType.meta || {
      type: null,
      name: null,
      locations: [],
    }
    const exists = this._getByMeta(type, name)

    if (exists) {
      console.warn(
        `You have registered the dynamic component which selector is: ${
          exists.factory.selector
        }`,
      )
      return
    }

    if (!name) console.warn(`You have not give a meta name in: ${f.selector}`)

    this._factories.updateOne({
      meta: {
        type: type || 'custom',
        name,
        locations,
      },
      factory: f,
    })
  }

  private _getByMeta(type: string, name: string) {
    return this._factories
      .getAll()
      .filter(f => f.meta.type === type && f.meta.name === name)[0]
  }
}
