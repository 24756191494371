import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy,
  Input,
} from '@angular/core'

@Component({
  selector: 'app-sms',
  templateUrl: './sms.component.html',
  styleUrls: ['./sms.component.styl'],
})
export class SmsComponent implements OnInit, OnDestroy {
  @Output() countStart = new EventEmitter()
  @Output() countOver = new EventEmitter()

  private _countOver: Function
  private _timer

  count = 0

  constructor() {}

  ngOnInit() {}

  sendMsg() {
    if (this.count > 0) return

    this.count = 60
    this.countStart.emit()
    this._countOver = () => this.countOver.emit()
    this._counting()
  }

  ngOnDestroy() {
    this.count = 0
    this._countOver = null
    clearTimeout(this._timer)
  }

  private _counting() {
    if (this.count > 0) {
      this.count--
      this._timer = setTimeout(() => this._counting(), 1000)
    } else {
      if (this._countOver) this._countOver()
      this._countOver = null
    }
  }
}
