import {
  Component,
  Input,
  ChangeDetectionStrategy,
  OnInit,
  OnDestroy,
  ViewChild,
  TemplateRef,
  EventEmitter,
  ChangeDetectorRef,
  OnChanges,
  SimpleChanges,
} from '@angular/core'

import { WidgetComponent } from '../../view'
import { RowField } from '../../config'
import { NzModalService, NzModalRef } from 'ng-zorro-antd'

/**
 * can use for: image
 */
@Component({
  selector: 'bp--preset--list-image-widget',
  template: `
    <img
      *ngFor="let img of imgs"
      class="list-img"
      [ngStyle]="{ width: size.width, height: size.height }"
      [src]="img"
      (click)="openModal(img)"
    />
    <ng-template #imageTpl>
      <img [src]="modalImg" style="width: 100%" />
    </ng-template>
  `,
  styles: [
    `
      .list-img {
        border: 1px solid #eee;
        border-radius: 3px;
        cursor: pointer;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BpListImageWidget extends WidgetComponent
  implements OnInit, OnChanges, OnDestroy {
  static meta = {
    type: 'preset_list',
    name: 'image',
  }

  @Input() entity: any
  @Input() value: string | string[]
  @Input() field: RowField

  @ViewChild('imageTpl', { static: false }) imageTpl: TemplateRef<any>

  size = {
    width: '64px',
    height: '64px',
  }
  imgs = []
  modalImg = ''

  private _modal: NzModalRef = null

  constructor(
    private _cdRef: ChangeDetectorRef,
    private _modalService: NzModalService,
  ) {
    super()
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.value) {
      this._updateValue()
      this._cdRef.detectChanges()
    }
  }

  ngOnInit() {}

  ngOnDestroy() {
    this._modal && this._modal.destroy()
  }

  openModal(src: string) {
    this.modalImg = src
    this._modal = this._modalService.create({
      nzContent: this.imageTpl,
      nzFooter: null,
      nzClosable: false,
    })
  }

  private _updateValue() {
    if (Array.isArray(this.value)) {
      this.imgs = this.value
    } else {
      this.imgs = [this.value]
    }
  }
}
