import {
  Component,
  Input,
  OnInit,
  EventEmitter,
  OnDestroy,
} from '@angular/core'
import { FormGroup } from '@angular/forms'
import { Subscription } from 'rxjs'
import { WidgetComponent, RowField, FieldType } from '@bp/core'

import { ShowComponentPipe } from './show-component.pipe'

const EXTRA_MAP = {
  password: 'password',
  number: 'number',
  color: 'color',
  datetime: 'datetime',
  province: 'province',
  city: 'city',
  district: 'district',
  select: 'map',
  checkbox: 'map',
  custom: 'text',
  image: 'text',
  file: 'text',
}

@Component({
  selector: 'bp--preset--field-form-extra',
  templateUrl: './form-extra.html',
})
export class BpFieldFormExtra extends WidgetComponent
  implements OnInit, OnDestroy {
  static meta = {
    name: 'field-form-extra',
  }

  @Input() entity: RowField
  @Input() value: never
  @Input() field: RowField
  @Input() formGroup: FormGroup

  change = new EventEmitter<string>()
  readonly = false
  displayExtra = ''
  cloneEntity = null

  private _subs = new Subscription()

  ngOnInit() {
    this.readonly = this.field.feature.includes('readonly')
    this.cloneEntity = { ...this.entity }

    const typeControl = this.formGroup.get('type')

    setTimeout(() => {
      const extraData = EXTRA_MAP[typeControl.value]

      this.displayExtra = extraData || '-'
    }, 0)
    this._subs.add(typeControl.valueChanges.subscribe(v => this._setExtra(v)))
  }

  ngOnDestroy() {
    this._subs.unsubscribe()
  }

  private _setExtra(val: FieldType) {
    const extraData = EXTRA_MAP[val]

    this.displayExtra = extraData || '-'

    if (extraData) {
      this.change.emit(extraData)
    } else {
      this.change.emit('')
    }

    if (ShowComponentPipe.transform(extraData)) {
      this.change.emit(null)
      this.cloneEntity.extra = null
    }
  }
}
