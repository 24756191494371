import { Injectable } from '@angular/core'
import { NzMessageService } from 'ng-zorro-antd'

@Injectable({ providedIn: 'root' })
export class ClipboardService {
  constructor(private _msgService: NzMessageService) {}

  copy(str: string) {
    // Create new element
    const el: any = document.createElement('textarea')
    // Set value (string to be copied)
    el.value = str
    // Set non-editable to avoid focus and move outside of view
    el.setAttribute('readonly', '')
    el.style = { position: 'absolute', left: '-9999px', width: 0, height: 0 }
    document.body.appendChild(el)
    // Select text inside element
    el.select()
    // Copy text to clipboard
    document.execCommand('copy')
    // Remove temporary element
    document.body.removeChild(el)

    this._msgService.success('复制成功')
  }
}
