import { Provider } from '@angular/core'
import { HTTP_INTERCEPTORS } from '@angular/common/http'

import { globalConfig } from '~/startup.config'
import { ErrorInterceptor } from './error-interceptor.service'
import { GraphqlErrorInterceptor } from './graphql-error-interceptor.service'

export function provideInterceptors(): Provider {
  return [
    globalConfig.netType === 'graphql'
      ? {
          provide: HTTP_INTERCEPTORS,
          useClass: GraphqlErrorInterceptor,
          multi: true,
        }
      : { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ]
}
