import { Component, OnInit } from '@angular/core'
import { Apollo } from 'apollo-angular'
import gql from 'graphql-tag'
import { HookComponent, AclService } from '@bp/core'
import { switchMap } from 'rxjs/operators'

@Component({
  selector: 'bp--preset--hook-send-acl',
  template: `
    <button nz-button nzType="default" type="button" (click)="sync()">
      同步ACL
    </button>
  `,
})
export class BpHookSendAcl extends HookComponent implements OnInit {
  static meta = {
    type: 'hooks',
    name: 'send-acl',
    locations: ['dev/module.list.action'],
  }

  constructor(private _apollo: Apollo, private _aclService: AclService) {
    super()
  }

  ngOnInit() {}

  sync() {
    this._aclService
      .getPermissions()
      .pipe(
        switchMap(ps =>
          this._apollo.mutate({
            mutation: gql`
              mutation Blueprint($ps: [Permission]) {
                syncPermission(permissions: $ps)
              }
            `,
            variables: { ps },
          }),
        ),
      )
      .subscribe()
  }
}
