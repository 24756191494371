import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  TemplateRef,
  OnDestroy,
} from '@angular/core'
import { ActionComponent, ActionComponentDef, Payload } from '@bp/core'
import deepcopy from 'deepcopy'
import { FormBuilder, Validators } from '@angular/forms'
import { NzModalRef, NzModalService } from 'ng-zorro-antd'

const EXCLUDE_KEY = ['_level', '_expand', '_parent', '_children']

@Component({
  selector: 'bp--preset--module-action-copy',
  template: `
    <span>复制</span>
    <ng-template #formTpl>
      <form
        nz-form
        [formGroup]="validateForm"
        class="login-form"
        (ngSubmit)="handleOk()"
      >
        <nz-form-item>
          <nz-form-control nzErrorTip="请输入模块新名称!">
            <nz-input-group>
              <input
                type="text"
                nz-input
                formControlName="name"
                placeholder="名称"
              />
            </nz-input-group>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-control nzErrorTip="请输入模块新标识符!">
            <nz-input-group>
              <input
                type="text"
                nz-input
                formControlName="identifier"
                placeholder="标识符"
              />
            </nz-input-group>
          </nz-form-control>
        </nz-form-item>
      </form>
    </ng-template>
  `,
})
export class BpModuleActionCopy extends ActionComponent
  implements OnInit, OnDestroy, ActionComponentDef {
  static meta = {
    type: 'custom_action',
    name: 'module-action-copy',
  }

  @ViewChild('formTpl', { static: false })
  private formTpl: TemplateRef<any>

  @Input() entity: any
  @Output() dispatch = new EventEmitter<Payload>()

  routeData = null
  isVisible = false
  validateForm = this._fb.group({
    name: [null, [Validators.required]],
    identifier: [null, [Validators.required]],
  })

  private _formModalRef: NzModalRef = null

  bpOnClick = () => {
    this.isVisible = true
    this._formModalRef = this._modal.create({
      nzTitle: '变更',
      nzContent: this.formTpl,
      nzOnOk: () => this.handleOk(),
      nzOnCancel: () => this.handleCancel(),
      // nzAfterClose: this._afterCloseModal,
    })
  }

  constructor(private _fb: FormBuilder, private _modal: NzModalService) {
    super()
  }

  ngOnInit() {}

  ngOnDestroy() {
    this._formModalRef && this._formModalRef.destroy()
  }

  handleOk(): void {
    const {
      value: { name, identifier },
      valid,
      controls,
    } = this.validateForm

    for (const i in controls) {
      if (i) {
        controls[i].markAsDirty()
        controls[i].updateValueAndValidity()
      }
    }

    if (!valid) return

    const data = deepcopy(this.entity)

    EXCLUDE_KEY.forEach(k => delete data[k])

    this.dispatch.emit({ type: 'create', data: { ...data, name, identifier } })

    this.isVisible = false
  }

  handleCancel(): void {
    this.isVisible = false
  }
}
