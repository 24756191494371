import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from '@angular/core'
import { FormGroup } from '@angular/forms'
import { NzFormatEmitEvent } from 'ng-zorro-antd'

import { WidgetComponent } from '../../view'
import { RowField } from '../../config'
import { AclService } from '../acl_service'
import { map } from 'rxjs/operators'

@Component({
  selector: 'bp--acl--role-manage',
  templateUrl: './role_manage.html',
})
export class BpAclRoleManage extends WidgetComponent
  implements OnInit, OnChanges {
  static meta = {
    name: 'role-manage',
  }

  @Input() entity: RowField
  @Input() value: never
  @Input() field: RowField
  @Input() formGroup: FormGroup

  change = new EventEmitter<string[]>()
  readonly = false
  defaultCheckedKeys = []
  // Nodes without role module
  nodes = this._aclService.getPermissionTree()

  constructor(private _aclService: AclService) {
    super()
  }

  ngOnInit() {
    this.readonly = this.field.feature.includes('readonly')

    if (this.value) this._initEdit()
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.value) {
      this.defaultCheckedKeys = changes.value.currentValue
    }
  }

  checkChange(e: NzFormatEmitEvent) {
    this.change.emit(e.keys)
  }

  private _initEdit() {
    this.defaultCheckedKeys = this.value
  }
}
