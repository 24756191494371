import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  private cache: []

  constructor() {}

  set(config) {
    this.cache = config
  }

  get() {
    return this.cache
  }
}

