import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'hook',
})
export class HookNamePipe implements PipeTransform {
  constructor() {}

  transform(aclPrefix: string[], location: string) {
    return `${aclPrefix.join('.')}.${location}`
  }
}
