import { NgModule, ApplicationRef } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { registerLocaleData } from '@angular/common'
import zh from '@angular/common/locales/zh'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { StoreModule } from '@ngrx/store'
import { EffectsModule } from '@ngrx/effects'
import { StoreRouterConnectingModule } from '@ngrx/router-store'
import { StoreDevtoolsModule } from '@ngrx/store-devtools'
import {
  removeNgStyles,
  createNewHosts,
  createInputTransfer,
} from '@angularclass/hmr'
import { BlueprintModule } from '@bp/core'

import { environment } from 'src/environments/environment'
import { AppRoutingModule } from '~/app-routing.module'
import { AppComponent } from '~/app.component'
import { reducers, CustomSerializer } from '~/app-reducers'
import { CoreModule } from '~/core/core.module'
import { globalConfig } from '~/startup.config'
import { provideInterceptors } from '~/core/http'
import { AuthModule } from '~/auth/auth.module'
import { UtilModule } from '~/util/util.module'

registerLocaleData(zh)

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    StoreModule.forRoot(reducers),
    EffectsModule.forRoot([]),
    StoreRouterConnectingModule.forRoot({
      serializer: CustomSerializer,
    }),
    environment.production
      ? []
      : StoreDevtoolsModule.instrument({ maxAge: 25 }),
    CoreModule,
    AuthModule,
    UtilModule,
    AppRoutingModule,
    BlueprintModule.forRoot({
      staticConfig: 'assets/blueprint.json',
      http: {
        baseURL: globalConfig.baseUrl,
        type: globalConfig.netType as any,
      },
      production: environment.production,
    }),
  ],
  providers: [provideInterceptors()],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(public appRef: ApplicationRef) {}

  hmrOnInit(store) {
    if (environment.production) return

    if (!store || !store.state) return

    console.log('HMR store', store)
    console.log('store.state.data:', store.state.data)
    // inject AppStore here and update it
    // this.AppStore.update(store.state)
    if ('restoreInputValues' in store) {
      store.restoreInputValues()
    }
    // change detection
    this.appRef.tick()
    delete store.state
    delete store.restoreInputValues
  }
  hmrOnDestroy(store) {
    if (environment.production) return

    const cmpLocation = this.appRef.components.map(
      cmp => cmp.location.nativeElement,
    )
    // recreate elements
    store.disposeOldHosts = createNewHosts(cmpLocation)
    // inject your AppStore and grab state then set it on store
    // var appState = this.AppStore.get()
    store.state = { ...store.state }
    // store.state = Object.assign({}, appState)
    // save input values
    store.restoreInputValues = createInputTransfer()
    // remove styles
    removeNgStyles()
  }
  hmrAfterDestroy(store) {
    if (environment.production) return

    // display new elements
    store.disposeOldHosts()
    delete store.disposeOldHosts
    // anything you need done the component is removed
  }
}
