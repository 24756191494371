import { Provider } from '@angular/core'
import { HTTP_INTERCEPTORS } from '@angular/common/http'

import { AuthInterceptor } from './auth-interceptor.service'

export function provideInterceptors(): Provider {
  return [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  ]
}
