import { Pipe, PipeTransform } from '@angular/core'

export type ColorConfig = {
  value: string
  condition: string
  fn: Function
}

@Pipe({
  name: 'listColor',
})
export class ListColorPipe implements PipeTransform {
  constructor() {}

  transform(data: any, config: ColorConfig[] = []) {
    let value = ''

    config.some(conf => {
      const needColor = conf.fn.call(data)
      if (needColor) value = conf.value

      return needColor
    })

    return value || '#fff'
  }
}
