import { Pipe, PipeTransform } from '@angular/core'

import { DistrictService, Level } from '../services'

@Pipe({
  name: 'district',
})
export class DistrictPipe implements PipeTransform {
  constructor(private _districtService: DistrictService) {}

  transform(code: string, level: Level) {
    return this._districtService.getLabels(level, code).join('/')
  }
}
