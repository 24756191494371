import {
  createSelector,
  createFeatureSelector,
  ActionReducerMap,
} from '@ngrx/store'

import * as fromAuth from './reducers'

export interface AuthState {
  status: fromAuth.State
}

export const reducers: ActionReducerMap<AuthState> = {
  status: fromAuth.reducer,
}

export const selectAuthState = createFeatureSelector<AuthState>('auth')

export const selectAuthStatusState = createSelector(
  selectAuthState,
  state => state.status,
)
export const getLoggedIn = createSelector(
  selectAuthStatusState,
  fromAuth.getLoggedIn,
)
export const getUser = createSelector(
  selectAuthStatusState,
  fromAuth.getUser,
)
