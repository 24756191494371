import { Component, OnInit, Input, EventEmitter } from '@angular/core'
import { ActionComponent, Module, Payload } from '@bp/core'
import { FormGroup, Validators, FormBuilder } from '@angular/forms'
import { SharedHttpService } from '~/shared/http/shared-http.service'
import { Router } from '@angular/router'
import { NzMessageService } from 'ng-zorro-antd'

@Component({
  selector: 'app-template-refresh-category-id',
  templateUrl: './template-refresh-category-id.component.html',
  styleUrls: ['./template-refresh-category-id.component.styl'],
})
export class TemplateRefreshCategoryIdComponent extends ActionComponent implements OnInit {
  static meta = {
    type: 'custom_action',
    name: 'template-refresh-category-id',
  }

  validateForm = this._fb.group({
    category_ids: [null, Validators.required],
  })

  dispatch = new EventEmitter<Payload>()

  formStyle = {
    offset: 5,
    span: 15,
  }

  fields = [
    {
      name: '分类',
      identifier: 'category_ids',
      type: 'relations',
      extra: '',
      place: [],
      feature: [],
      form: {
        type: 'relations',
        label_key: 'name',
        relation_key: 'id',
      },
    },
  ]

  @Input() entity: any
  @Input() module: Module

  showModal = false

  bpOnClick = () => {
    this.openModal()
  }

  constructor(
    private _fb: FormBuilder,
    private _router: Router,
    private _sharedHttp: SharedHttpService,
    private _msgService: NzMessageService,
  ) {
    super()
  }

  ngOnInit() {}

  openModal() {
    this.showModal = true
    this.validateForm.reset()

  }

  closeModal() {
    this.showModal = false
  }

  handleOk() {
    if (this.entity) {
      this._sharedHttp.templateBatch(
        this.entity.id,
        'refresh',
        { category_ids: this.validateForm.get('category_ids').value }
      ).subscribe(res => {
        this._msgService.success('操作成功')
        this.dispatch.emit({ type: 'refresh' })
      })
    } else {
      this.dispatch.emit({
        type: 'batch',
        batchType: 'refresh',
        data: { category_ids: this.validateForm.get('category_ids').value },
      })
    }

    this.showModal = false
  }
}
