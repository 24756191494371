import { Pipe, PipeTransform } from '@angular/core'
import deep from 'deep-get-set'

function transform(object: any, identifier: string): string {
  const transformed = deep(object, identifier)
  return transformed
}

@Pipe({
  name: 'deep',
})
export class DeepPipe implements PipeTransform {
  static transform = transform
  transform = transform
}
