import { Injectable } from '@angular/core'
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http'

import { Observable, throwError } from 'rxjs'
import { catchError, tap } from 'rxjs/operators'

import { NzMessageService } from 'ng-zorro-antd'

@Injectable()
export class GraphqlErrorInterceptor implements HttpInterceptor {
  constructor(private messageService: NzMessageService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap((event: any) => {
        if (event.type === 4) {
          const body = event.body

          if (body.errors) {
            this._bodyError(body.errors)
          }
        }
      }),
      catchError(event => {
        this._errorMessage(event)
        return throwError(event)
      }),
    )
  }

  private _errorMessage(err) {
    let res = err.error
    console.log(res)

    if (typeof res !== 'object') {
      try {
        res = JSON.parse(res)
      } catch (e) {
        this.messageService.error('[non-object] 未知错误，请重试')
        return
      }
    }

    if (err.status === 422) {
      if (res.data) {
        const errors = res.data

        errors.forEach(error => this.messageService.error(error.content))
      } else {
        const error = res.message

        this.messageService.error(error)
      }
    } else if (res) {
      this.messageService.error(res.message)
    } else {
      this.messageService.error('[unknow] 未知错误，请重试')
    }
  }

  private _bodyError(err) {
    const firstErr = err[0]
    const valiErrorKeys = Object.keys(firstErr.validation)

    if (valiErrorKeys && valiErrorKeys.length) {
      this.messageService.error(firstErr.validation[valiErrorKeys[0]][0])
    } else {
      this.messageService.error(firstErr.message)
    }
  }
}
