import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  ChangeDetectorRef,
} from '@angular/core'
import { FormGroup, FormControl } from '@angular/forms'
import { ActivatedRoute } from '@angular/router'
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout'
import { Observable } from 'rxjs'
import { map, take, tap } from 'rxjs/operators'
import { NzMessageService } from 'ng-zorro-antd'

import { RouteData, RowField } from '../../../config'
import { DataAccessorService } from '../../../init_accessor'

@Component({
  selector: 'bp-form-page',
  templateUrl: './form_page.html',
  styleUrls: ['./form_page.styl'],
})
export class BpFormPage implements OnInit {
  @Input() id: number | string
  // TODO: route mode parentId come from?
  @Input() parentId: number | string
  @Output() afterConfirm = new EventEmitter()

  mode: 'edit' | 'create' = 'create'
  entityId: number | string
  form = new FormGroup({})
  formStyle = {
    offset: 5,
    span: 15,
  }
  remoteData = {}
  loading = false

  // bp
  bpConfig = this._route.snapshot.data as RouteData
  bpModule = this.bpConfig.module
  bpPrimaryKey = this.bpModule.primary_key || 'id'
  bpField = this.bpConfig.module.field || []
  fields: RowField[] = []

  isHandset$: Observable<boolean>

  constructor(
    private _breakpointObserver: BreakpointObserver,
    private _route: ActivatedRoute,
    private _dataAccessorService: DataAccessorService,
    private _msgService: NzMessageService,
    private _cdRef: ChangeDetectorRef,
  ) {
    // init breakpoint
    this.isHandset$ = _breakpointObserver
      .observe(Breakpoints.Handset)
      .pipe(map(({ matches }) => matches))
  }

  ngOnInit() {
    this.entityId = this.id || this._route.snapshot.params.id || this._getLatestSegmentId()
    this.mode = !!this.entityId ? 'edit' : 'create'

    console.log(this.entityId)

    // Init fields
    if (this.mode === 'edit') {
      this.fields = this.bpField.filter(f => f.place.includes('edit'))
    } else {
      this.fields = this.bpField.filter(f => f.place.includes('create'))
    }
    this._initForm()

    // Edit form isn't popup in list page, so need init data accessor service
    this._dataAccessorService.init({
      route: this._route,
      fields: this.fields,
    })

    /**
     * Init edit mode in quick mode at first time or page mode
     */
    if (this.mode === 'edit') this._initEditMode()
  }

  reset() {
    this.form.reset()
  }

  confirm() {
    let observable: Observable<any>

    if (this.mode === 'edit') {
      observable = this._dataAccessorService.accessor
        .updateById(this.entityId, this.form.value)
        .pipe(tap(() => this._msgService.success('更新成功!')))
    } else {
      const data = { ...this.form.value }

      if (this.parentId) data.parent_id = this.parentId

      observable = this._dataAccessorService.accessor
        .create(data)
        .pipe(tap(() => this._msgService.success('新增成功!')))
    }

    observable.pipe(tap(r => this.afterConfirm.emit(r))).subscribe()
  }

  private _initForm() {
    this.fields.forEach(field => {
      /** Standalone custome form should init form control itself */
      if (field.form.standalone) return

      this.form.addControl(
        field.identifier,
        new FormControl(field.form.default || null),
      )
    })
  }

  private _initEditMode() {
    const id = this.entityId

    if (!id) return

    this.loading = true

    this._dataAccessorService.accessor
      .getOneById(id)
      .pipe(take(1))
      .subscribe(data => {
        this.remoteData = data

        this.fields.forEach(field => {
          /** Standalone custome form should init form control itself */
          if (field.form.standalone) return

          this.form.get(field.identifier).setValue(data[field.identifier])
        })
        this.loading = false
      })
  }

  // Cuz segment data key always is id in list page template, so needn't use bpPrimaryKey
  private _getLatestSegmentId(): string {
    const urlSegments = this._route.snapshot.url
    const latestSegment = urlSegments[urlSegments.length - 1]
    const segmentParamsId =
      latestSegment && latestSegment.parameters && latestSegment.parameters.id

    return segmentParamsId
  }
}
