import { Injectable } from '@angular/core'
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router'
import { Store } from '@ngrx/store'
import { Observable, of } from 'rxjs'
import { map, take, switchMap, catchError } from 'rxjs/operators'

import * as fromAuth from './store'
import * as authAction from './store/actions'
import { TokenService } from './services'
import { AuthHttpService } from './http'

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private store: Store<fromAuth.AuthState>,
    private tokenService: TokenService,
    private _authHttp: AuthHttpService,
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.store.select(fromAuth.getLoggedIn).pipe(
      take(1),
      switchMap(authed => {
        // if not logged in
        if (!authed) {
          const token = this.tokenService.get()

          // if has token
          if (token) {
            return this._authHttp.session().pipe(
              map(user => {
                this.store.dispatch(new authAction.AuthSuccess({ user }))
                return true
              }),
              catchError(err => {
                this.store.dispatch(new authAction.AuthFailure(err))
                return of(false)
              }),
            )
          } else {
            this.store.dispatch(
              new authAction.AuthFailure(new Error('No token')),
            )
          }
        } else {
          return of(true)
        }
      }),
    )
  }
}
