import { Component } from '@angular/core'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.styl'],
})
export class AppComponent {
  // TODO: move unload event
  // @HostListener('window:beforeunload')
  // onBeforeUnload(e) {
  //   e = e || window.event

  //   if (e) {
  //     e.returnValue = '关闭提示'
  //   }

  //   return '关闭提示'
  // }

  constructor() {}
}
