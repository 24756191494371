import {
  Component,
  Input,
  ChangeDetectionStrategy,
  OnInit,
  ChangeDetectorRef,
  OnChanges,
  SimpleChanges,
} from '@angular/core'

import { WidgetComponent } from '../../view'
import { RowField } from '../../config'

/**
 * can use for: tag
 */
@Component({
  selector: 'bp--preset--list-tag-widget',
  template: `
    <nz-tag *ngFor="let tag of tags">
      {{ tag | slice }}
    </nz-tag>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BpListTagWidget extends WidgetComponent
  implements OnInit, OnChanges {
  static meta = {
    type: 'preset_list',
    name: 'tag',
  }

  @Input() entity: any
  @Input() value: string[]
  @Input() field: RowField

  tags = []

  constructor(private _cdRef: ChangeDetectorRef) {
    super()
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.value) {
      if (this.value) this.tags = this.value
      this._cdRef.detectChanges()
    }
  }

  ngOnInit() {}
}
