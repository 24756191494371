import { RowField } from '@bp/core'

export const routeFields: RowField[] = [
  {
    name: '路由类型',
    identifier: 'type',
    type: 'select',
    place: [],
    feature: [],
    extra: {
      list: '列表页',
      form: '表单页',
      profile: '详情页',
    },
    form: {
      type: 'select',
      default: 'list',
    },
  },
]

export const listRouteFields: RowField[] = [
  {
    name: '列表特性',
    identifier: 'feature',
    type: 'checkbox',
    place: [],
    feature: [],
    extra: {
      create: '新增',
      pagination: '分页',
      tree: '多级',
      drag: '调序',
    },
    form: {
      type: 'checkbox',
      default: ['create', 'pagination'],
    },
  },
  {
    name: '单行变色',
    identifier: 'color',
    type: 'map',
    place: [],
    feature: [],
    extra: {
      placeholder: {
        key: '颜色',
        value: '条件',
      },
    },
    form: {
      type: 'map',
      comment: '单行数据根据条件变色',
    },
  },
]

export const entryFields: RowField[] = [
  {
    name: '入口类型',
    identifier: 'type',
    type: 'select',
    place: [],
    feature: [],
    extra: {
      form: '编辑',
      delete: '删除',
      profile: '详情',
      link: '链接',
      custom: '自定义',
    },
    form: {
      type: 'select',
      default: 'form',
    },
  },
  {
    name: '下拉',
    identifier: 'hide',
    type: 'switch',
    place: [],
    feature: [],
    form: {
      type: 'switch',
      default: false,
    },
  },
  {
    name: '条件展示',
    identifier: 'condition',
    type: 'text',
    place: [],
    feature: [],
    form: {
      type: 'text',
      comment: 'this为该列数据',
    },
  },
  {
    name: '附加信息',
    identifier: 'extra',
    type: 'text',
    place: [],
    feature: [],
    form: {
      type: 'text',
    },
  },
]

export const batchFields: RowField[] = [
  {
    name: '批量类型',
    identifier: 'type',
    type: 'select',
    place: [],
    feature: [],
    extra: {
      delete: '删除',
      custom: '自定义',
    },
    form: {
      type: 'select',
      default: 'delete',
    },
  },
  {
    name: '附加信息',
    identifier: 'extra',
    type: 'text',
    place: [],
    feature: [],
    form: {
      type: 'text',
    },
  },
]
