import {
  InjectionToken,
  StaticProvider,
  ComponentFactoryResolver,
} from '@angular/core'

import { DynamicComponentLoader } from './dynamic_component_loader'

export const WIDGET_LOADER = new InjectionToken<DynamicComponentLoader>(
  'Loader.widget',
)
export const HOOK_LOADER = new InjectionToken<DynamicComponentLoader>(
  'Loader.hook',
)

export function loaderFactory(r: ComponentFactoryResolver) {
  return new DynamicComponentLoader(r)
}

export function provideLoader(): StaticProvider {
  return [
    {
      provide: WIDGET_LOADER,
      useFactory: loaderFactory,
      deps: [ComponentFactoryResolver],
    },
    {
      provide: HOOK_LOADER,
      useFactory: loaderFactory,
      deps: [ComponentFactoryResolver],
    },
  ]
}
