import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'

import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

import { Response } from '@bp/http'

@Injectable({
  providedIn: 'root',
})
export class UploadHttpService {
  constructor(private http: HttpClient) {}

  getAuthorization({ method, path }: AuthRequest): Observable<any> {
    return this.http
      .get<Response<any>>('file', { params: { method, path } })
      .pipe(map(res => res.data.signature))
  }

  // 上传文件
  ossUpload(file, config): Observable<any> {
    const formData = new FormData();
    formData.append('key', config.dir);
    formData.append('success_action_status', '200');
    formData.append('policy', config.policy);
    formData.append('OSSAccessKeyId', config.accessid);
    formData.append('Signature', config.signature);
    formData.append('file', file, file.name);
    return this.http.post(config.host, formData, {
      params: { skipAuth: '1' },
    });
  }

  uploadFileViaApi(endpoint, file): Observable<any> {
    const formData = new FormData();
    formData.append('image', file, file.name);
    return this.http.post(endpoint, formData);
  }

  saveFile({
    name,
    path,
    mime,
  }: SaveFileRequest): Observable<Response<SaveFileSuccess>> {
    return this.http.post<Response<SaveFileSuccess>>('file', {
      name,
      path,
      mime,
    })
  }

  delFile(id: number): Observable<Response<any>> {
    return this.http.delete<Response<any>>(`file/${id}`)
  }
}

export interface AuthRequest {
  method: string
  path: string
}

export interface SaveFileRequest {
  name: string
  path: string
  mime: string
}

export interface SaveFileSuccess {
  created_at: string
  id: number
  mine: string
  path: string
  updated_at: string
  uploader_id: number
  uploader_type: string
}
