import { Component, Input, OnInit, EventEmitter } from '@angular/core'

import { WidgetComponent } from '../../view'
import { RowField } from '../../config'
import { isNone } from '../../util/is_none'
import { Payload } from '../../interface'

const NULL_KEY = '__null__'

/**
 * can use for: select, switch
 */
@Component({
  selector: 'bp--preset--search-select-widget',
  template: `
    <nz-select [ngModel]="selectValue" (ngModelChange)="valueChange($event)">
      <nz-option *ngFor="let m of maps" [nzLabel]="m.label" [nzValue]="m.value">
      </nz-option>
    </nz-select>
  `,
})
export class BpSearchSelectWidget extends WidgetComponent implements OnInit {
  static meta = {
    type: 'preset_search',
    name: 'select',
  }

  @Input() entity: any
  @Input() value: string
  @Input() field: RowField

  change = new EventEmitter<string>()
  dispatch = new EventEmitter<Payload>()
  maps: {
    label: string
    value: string
  }[] = []
  defaultMaps = [{ label: '全部', value: NULL_KEY }]

  get selectValue() {
    if (isNone(this.value)) {
      return NULL_KEY
    } else {
      return this.value
    }
  }

  ngOnInit() {
    const fieldType = this.field.type
    let maps = []

    if (this.field.extra) {
      maps = Object.entries(this.field.extra).map(([value, label]) => ({
        label,
        value,
      }))
    } else {
      if (fieldType === 'switch') {
        maps = [{ label: '是', value: 1 }, { label: '否', value: 0 }]
      }
    }

    this.maps = [...this.defaultMaps, ...maps]
  }

  valueChange(e: string) {
    if (e === NULL_KEY) {
      this.change.emit(null)
    } else {
      this.change.emit(e)
    }

    this.dispatch.emit({ type: 'search' })
  }
}
