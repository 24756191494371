import { Injectable, InjectionToken, Inject } from '@angular/core'
import { Router, NavigationStart, ActivatedRoute } from '@angular/router'
import { filter, take } from 'rxjs/operators'

@Injectable({
  providedIn: 'root',
})
export class RedirectService {
  private url = ''
  private _loginURL = ''
  private _needntSaveURL = []

  constructor(private _router: Router, private _route: ActivatedRoute) {}

  init(options: { loginURL: string; needtSaveURL: string[] }) {
    this._loginURL = options.loginURL
    this._needntSaveURL = options.needtSaveURL

    this._router.events
      .pipe(
        filter(event => event instanceof NavigationStart),
        take(1),
      )
      .subscribe((event: NavigationStart) => {
        this.setMemoryURL(event.url)
      })
  }

  setMemoryURL(url: string) {
    if (this.needSave(url)) this.url = url
  }

  clearMemoryURL() {
    this.url = ''
  }

  redirect() {
    // if have memory url(first screen), redirect to memory url.
    // else redirect to saved query's url
    if (this.url) {
      this._router.navigateByUrl(this.url)
    } else {
      const redirectURL = this._route.snapshot.queryParams.redirectURL || '/'

      this._router.navigate([redirectURL])
    }
    this.clearMemoryURL()
  }

  redirectToLogin(saveURL = false) {
    const redirectURL = this._router.routerState.snapshot.url

    saveURL && this.needSave(redirectURL)
      ? this._router.navigate([this._loginURL], {
          queryParams: { redirectURL },
        })
      : this._router.navigate([this._loginURL])
  }

  private needSave(url: string) {
    url = url.split('?')[0]

    return url && !this._needntSaveURL.some(_url => _url === url)
  }
}
