import { Pipe, PipeTransform } from '@angular/core'

export const SHOW_COMPONENT_MAP = ['map', 'text']

function transform(type: string): boolean {
  return SHOW_COMPONENT_MAP.some(t => t === type)
}

@Pipe({
  name: 'showComponent',
})
export class ShowComponentPipe implements PipeTransform {
  static transform = transform
  transform = transform
}
