import { enableProdMode } from '@angular/core'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'
import { hmrModule, bootloader } from '@angularclass/hmr'

import { environment } from './environments/environment'
import { AppModule } from './app/app.module'
import { preloaderFinished } from './preloader'

if (environment.production) enableProdMode()

bootloader(() => {
  const promise = platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .then(mod => {
      preloaderFinished()
      return mod
    })

  if (environment.hmr) {
    ;(<any>module).hot.accept()

    promise.then(mod => {
      return hmrModule(mod, <any>module)
    })
  }
})
