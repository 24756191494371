import {
  Component,
  Input,
  OnInit,
  EventEmitter,
  OnDestroy,
  OnChanges,
  SimpleChanges,
} from '@angular/core'
import { FormGroup, FormControl } from '@angular/forms'
import { Subscription } from 'rxjs'
import { WidgetComponent, RowField, FieldType } from '@bp/core'

import {
  listFields,
  searchFields,
  formFields,
  profileFields,
  defaultFieldMap,
  formTypes,
  listTypes,
  profileTypes,
  searchTypes,
} from './fields'
import { filter } from 'rxjs/operators'

@Component({
  selector: 'bp--preset--field-form-more',
  templateUrl: './form-more.html',
})
export class BpFieldFormMore extends WidgetComponent
  implements OnInit, OnDestroy, OnChanges {
  static meta = {
    name: 'field-form-more',
  }

  @Input() entity: RowField
  @Input() value: never
  @Input() field: RowField
  @Input() formGroup: FormGroup

  change = new EventEmitter<string>()
  readonly = false
  showMore = false
  formStyle = {
    offset: 5,
    span: 15,
  }
  inited = false

  // form
  listForm = new FormGroup({
    type: new FormControl(null),
    template: new FormControl(null),
  })
  searchForm = new FormGroup({
    type: new FormControl(null),
    relation_key: new FormControl(null),
    label_key: new FormControl(null),
    template: new FormControl(null),
    placeholder: new FormControl(null),
    advise: new FormControl(null),
  })
  formForm = new FormGroup({
    type: new FormControl(null),
    relation_key: new FormControl(null),
    label_key: new FormControl(null),
    template: new FormControl(null),
    condition: new FormControl(null),
    placeholder: new FormControl(null),
    comment: new FormControl(null),
    display: new FormControl(null),
    default: new FormControl(null),
    standalone: new FormControl(false),
  })
  profileForm = new FormGroup({
    type: new FormControl(null),
  })
  // form fields
  listFields = listFields
  searchFields = searchFields
  formFields = formFields
  profileFields = profileFields
  // field types
  formTypes = formTypes
  listTypes = listTypes
  profileTypes = profileTypes
  searchTypes = searchTypes

  showListTemplate = false
  listTemplateField = {
    name: '模板',
    identifier: 'template',
    type: 'text',
    place: [],
    feature: [],
    form: {
      type: 'text',
      comment: '配置列表模版',
    },
  }

  showFormTemplate = false
  formTemplateField = {
    name: '模板',
    identifier: 'template',
    type: 'text',
    place: [],
    feature: [],
    form: {
      type: 'text',
      comment: '配置编辑时模版',
    },
  }
  showFormRKey = false
  formRKeyField = [
    {
      name: '关联项Key',
      identifier: 'relation_key',
      type: 'text',
      place: [],
      feature: [],
      form: {
        type: 'text',
        comment: '配置关联数据该使用的key，用于获取数据',
      },
    },
    {
      name: 'Label',
      identifier: 'label_key',
      type: 'text',
      place: [],
      feature: [],
      form: {
        type: 'text',
        comment: '配置关联数据该使用的label，用于展示数据',
      },
    },
  ]

  showSearchTemplate = true
  searchTemplateField = {
    name: '模板',
    identifier: 'template',
    type: 'text',
    place: [],
    feature: [],
    form: {
      type: 'text',
      comment: '`文本`开启搜索建议时有效，`关联`、`级联`类型有效',
    },
  }
  showSearchRKey = false
  searchRKeyField = [
    {
      name: '关联项Key',
      identifier: 'relation_key',
      type: 'text',
      place: [],
      feature: [],
      form: {
        type: 'text',
        comment: '配置关联数据该使用的key，用于获取数据',
      },
    },
    {
      name: 'Label',
      identifier: 'label_key',
      type: 'text',
      place: [],
      feature: [],
      form: {
        type: 'text',
        comment: '配置关联数据该使用的label，用于展示数据',
      },
    },
  ]

  private _subs = new Subscription()

  ngOnChanges(changes: SimpleChanges) {
    if (changes.entity) this._fillData()
  }

  ngOnInit() {
    this.readonly = this.field.feature.includes('readonly')

    this._initForm()
    this._fillData()

    this.inited = true
  }

  ngOnDestroy() {
    this._subs.unsubscribe()
  }

  private _initForm() {
    this.formGroup.addControl('list', this.listForm)
    this.formGroup.addControl('search', this.searchForm)
    this.formGroup.addControl('form', this.formForm)
    this.formGroup.addControl('profile', this.profileForm)

    const typeControl = this.formGroup.get('type')

    this._setDefault(typeControl.value)

    // Main type
    this._subs.add(
      typeControl.valueChanges.subscribe((val: FieldType) => {
        if (!this.inited) return

        this._setDefault(val)
      }),
    )

    // list template
    this._subs.add(
      this.listForm.get('type').valueChanges.subscribe(type => {
        const showTemplate = ['template'].includes(type)

        if (showTemplate) {
          this.showListTemplate = true
        } else {
          this.showListTemplate = false
        }
      }),
    )
    // form template
    this._subs.add(
      this.formForm.get('type').valueChanges.subscribe(type => {
        const showTemplate = [
          'relation',
          'relations',
          'city',
          'province',
          'district',
          'cascader',
        ].includes(type)
        const showRKey = ['relation', 'relations', 'cascader'].includes(type)

        if (showTemplate) {
          this.showFormTemplate = true
          this.formForm.get('template').setValue('{{name}}')
        } else {
          this.showFormTemplate = false
          this.formForm.get('template').setValue('')
        }

        if (showRKey) {
          this.showFormRKey = true
          this.formForm.get('label_key').setValue('name')
          this.formForm.get('relation_key').setValue('id')
        } else {
          this.showFormRKey = false
          this.formForm.get('label_key').setValue('')
          this.formForm.get('relation_key').setValue('')
        }
      }),
    )
    // search template
    this._subs.add(
      this.searchForm.get('type').valueChanges.subscribe(type => {
        const showTemplate = [
          'text',
          'relation',
          'city',
          'province',
          'district',
          'cascader',
        ].includes(type)
        const showRKey = ['relation', 'cascader'].includes(type)

        if (showTemplate) {
          this.showSearchTemplate = true
          this.searchForm.get('template').setValue('{{name}}')
        } else {
          this.showSearchTemplate = false
          this.searchForm.get('template').setValue('')
        }

        if (showRKey) {
          this.showSearchRKey = true
          this.searchForm.get('label_key').setValue('name')
        } else {
          this.showSearchRKey = false
          this.searchForm.get('label_key').setValue('')
        }
      }),
    )

    this._subs.add(
      this.searchForm.get('advise').valueChanges.subscribe(advise => {
        console.log(advise)
        const showRKey = advise === 1

        if (showRKey) {
          this.showSearchRKey = true
          this.searchForm.get('label_key').setValue('name')
        } else {
          this.showSearchRKey = false
          this.searchForm.get('label_key').setValue('')
        }
      }),
    )
  }

  private _fillData() {
    const data = this.entity

    if (!data) return

    this.listForm.patchValue(data.list || {})
    this.searchForm.patchValue(data.search || {})
    this.formForm.patchValue(data.form || {})
    this.profileForm.patchValue(data.profile || {})
  }

  private _setDefault(type: FieldType) {
    this.listForm.get('type').setValue(defaultFieldMap[type].list)
    this.searchForm.get('type').setValue(defaultFieldMap[type].search)
    this.formForm.get('type').setValue(defaultFieldMap[type].form)
    this.profileForm.get('type').setValue(defaultFieldMap[type].profile)
  }
}
