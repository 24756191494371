import { Provider } from '@angular/core'
import { Observable, ReplaySubject } from 'rxjs'

import { BpConfig } from './model'

export class CurrentConfig extends Observable<BpConfig> {}

export class DefaultConfig extends ReplaySubject<BpConfig> {}

export function getConfig() {
  return new DefaultConfig(1)
}

export function provideConfig(): Provider {
  return [{ provide: CurrentConfig, useFactory: getConfig }]
}
