import { Injectable } from '@angular/core'

import { Observable } from 'rxjs'

import COS from 'cos-js-sdk-v5'
import { format } from 'date-fns'

import { UploadHttpService, SaveFileSuccess } from './upload_http_service'

@Injectable({
  providedIn: 'root',
})
export class CosService {
  private config: any

  constructor(
    private uploadHttpService: UploadHttpService
  ) {}

  setConfig(config) {
    this.config = config
  }

  uploadFile(file: File): Observable<CosResponse> {
    return new Observable(observer => {
      const cos = this.initCOS()
      const fileKey = this.getFileKey(file)

      cos.putObject(
        {
          Bucket: this.config.bucket,
          Region: this.config.region,
          Key: fileKey,
          StorageClass: 'STANDARD',
          Body: file,
          onProgress: progressData => {
            observer.next({
              status: 'progress',
              data: progressData,
              progress: progressData,
            })
          },
        },
        (err, data) => {
          if (!err && data.statusCode === 200) {
            this.uploadHttpService
              .saveFile({
                name: file.name ? file.name : Math.random().toString(36).substr(2, 8) + '.jpg',
                path: fileKey,
                mime: file.type
              })
              .subscribe(res => {
                observer.next({
                  status: 'completed',
                  data: res.data,
                  progress: null,
                })
                observer.complete()
              })
          } else {
            observer.error({ status: 'error', data: err })
          }
        },
      )
    })
  }

  private getFileKey(file: File): string {
    const fileName = file.name
    const now = new Date()
    const ymd = format(now, 'yyyy/MM/dd')
    const timestamp = parseInt((now.getTime() / 1000).toString(), 10)
    const randomStr = Math.random()
      .toString(36)
      .substr(2, 8)
    const ext = fileName ? fileName.substring(fileName.lastIndexOf('.')) : '.jpg'
    const key = `uploads/${ymd}/${timestamp}${randomStr}${ext}`

    return key
  }

  private initCOS(): any {
    return new COS({
      getAuthorization: (options, callback) => {
        this.uploadHttpService
          .getAuthorization({
            method: (options.Method || 'get').toLowerCase(),
            path: '/' + (options.Key || ''),
          })
          .subscribe(authorization => callback(authorization))
      },
    })
  }
}

export type CosResponse = {
  status: 'progress' | 'completed' | 'error'
  data: SaveFileSuccess
  progress: ProgressData
}

export interface ProgressData {
  loaded: number
  percent: number
  speed: number
  total: number
}
