import { Component, OnInit } from '@angular/core'

import { globalConfig } from '~/startup.config'

@Component({
  selector: 'app-default-layout',
  templateUrl: './default.component.html',
  styleUrls: ['./default.component.styl'],
})
export class DefaultLayoutComponent implements OnInit {
  globalConfig = globalConfig

  constructor() {}

  ngOnInit() {}
}
