import {
  // Directive,
  Inject,
  ViewContainerRef,
  OnInit,
  ComponentRef,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
  Component,
} from '@angular/core'
import { Subscription } from 'rxjs'

import { DynamicComponentLoader } from '../dynamic_component_loader'
import { WIDGET_LOADER } from '../loader'
import { HookPayload } from '../../interface'
import { HookComponentDef } from '../definition'

// usage for template, can't use @Directive
@Component({ selector: 'bp-hook', template: '' })
export class BpHook implements OnInit, OnDestroy {
  @Input() transform: {
    location: string
  }
  @Input() entity: any

  @Output() dispatch = new EventEmitter<HookPayload>()

  private _components: ComponentRef<HookComponentDef>[] = []
  private _subs = new Subscription()

  constructor(
    @Inject(WIDGET_LOADER) private _loader: DynamicComponentLoader,
    private _vcRef: ViewContainerRef,
  ) {}

  ngOnInit() {
    this._destroy()

    if (!this.transform)
      throw new Error(`Need give a transform name in bp-hook component`)

    // TODO: cache
    const hooks = this._loader.getByType('hooks')
    const comps = hooks.filter(c =>
      c.meta.locations.includes(this.transform.location),
    )

    if (!comps || !comps.length) return

    comps.forEach(comp => {
      const _comp = this._vcRef.createComponent(comp.factory)

      this._components.push(_comp)
      this._initInstance(_comp)
    })
  }

  ngOnDestroy() {
    this._destroy()
  }

  private _initInstance(comp: ComponentRef<HookComponentDef>) {
    const instance = comp.instance

    instance.entity = this.entity

    if (instance.dispatch) {
      const dispatchObservable = instance.dispatch.asObservable()

      this._subs.add(
        dispatchObservable.subscribe(payload => {
          this.dispatch.emit(payload)
        }),
      )
    }
  }

  private _destroy() {
    this._vcRef.clear()

    if (!this._components.length) return

    this._components.forEach(comp => comp.destroy())
    this._components = []
  }
}
