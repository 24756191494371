import { Injectable, Inject, InjectionToken, Injector } from '@angular/core'
import { Routes, Router } from '@angular/router'

export const BP_ROUTER_PLACEHOLDER = new InjectionToken<any>(
  'Bp Router Placeholder',
)

export const BP_DEFAULT_ROUTER_PLACEHOLDER = '$bp'

@Injectable({
  providedIn: 'root',
})
export class RouterLoader {
  private _router: Router

  private _routerDirty = false
  private _path: number[] = []
  private _originRoutes: Routes
  private _injectRoutes: Routes
  private _bpRoutes: Routes = []

  constructor(
    private _injector: Injector,
    @Inject(BP_ROUTER_PLACEHOLDER) private _placeholder: string,
  ) {}

  load(routes: Routes) {
    const router = (this._router = this._injector.get(Router))

    if (!this._originRoutes) {
      /**
       * Custom route inject, as -> {
       *   path: ...,
       *   component: ...,
       *   data: { injectTo: 'xxx' }
       * }
       * transform -> {
       *   path: `${r.data.injectTo}/${r.path}`,
       * }
       */
      const injectRoutes = this._router.config
        .filter(r => r.data && r.data.injectTo)
        .map(r => ({ ...r, path: `${r.data.injectTo}/${r.path}` }))
      const originRoutes = this._router.config.filter(
        r => !r.data || !r.data.injectTo,
      )

      this._originRoutes = originRoutes
      this._injectRoutes = injectRoutes
    }

    this._bpRoutes = routes
    this._replaceRoutes()
  }

  private _replaceRoutes() {
    let config: Routes
    if (!this._path.length) {
      // have not find match placeholder next round
      if (this._routerDirty) return
      this._routerDirty = true

      config = this._findRoute([...this._originRoutes])
    } else {
      // recover by record path
      config = this._alongThePath(this._originRoutes, this._path.length - 1)
    }

    // if havn't placeholder, dont reset config
    if (!config.length) return

    this._router.resetConfig(config)
  }

  /**
   * BFS, find the placeholder route, replace routes in found chian
   */
  private _findRoute(routes: Routes) {
    let _routes: Routes = []

    const found = routes.some((route, i) => {
      if (route.path === this._placeholder) {
        _routes = [
          ...routes.slice(0, i),
          // Custom route inject
          ...this._injectRoutes,
          ...this._bpRoutes,
          ...routes.slice(i + 1),
        ]
        this._path.push(i)

        return true
      } else {
        return false
      }
    })

    if (!found) {
      routes.some((route, i) => {
        if (route.children && route.children.length) {
          const foundRoutes = this._findRoute(route.children)

          if (foundRoutes && foundRoutes.length) {
            _routes = [
              ...routes.slice(0, i),
              {
                ...route,
                children: foundRoutes,
              },
              ...routes.slice(i + 1),
            ]
            this._path.push(i)

            return true
          }
        }

        return false
      })
    }

    return _routes
  }

  private _alongThePath(routes: Routes, i: number): Routes {
    let _routes: Routes = []
    const _path = this._path[i]

    if (i > 0) {
      _routes = [
        ...routes.slice(0, _path),
        {
          ...routes[_path],
          children: this._alongThePath(routes[_path].children, i - 1),
        },
        ...routes.slice(i + 1),
      ]
    } else {
      _routes = [
        ...routes.slice(0, _path),
        // Custom route inject
        ...this._injectRoutes,
        ...this._bpRoutes,
        ...routes.slice(i + 1),
      ]
    }

    return _routes
  }
}
