import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { ReactiveFormsModule } from '@angular/forms'
import { StoreModule } from '@ngrx/store'
import { EffectsModule } from '@ngrx/effects'

import { SharedModule } from '~/shared/shared.module'
import { AuthRoutingModule } from './auth-routing.module'
import { DefaultLayoutComponent } from './layout/default.component'
import { LoginComponent } from './views/login.component'
import { RegisterComponent } from './views/register.component'
import { provideInterceptors } from './http'
import { reducers } from './store'
import { AuthEffect } from './store/effects'

@NgModule({
  declarations: [DefaultLayoutComponent, LoginComponent, RegisterComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SharedModule,
    AuthRoutingModule,
    StoreModule.forFeature('auth', reducers),
    EffectsModule.forFeature([AuthEffect]),
  ],
  providers: [provideInterceptors()],
})
export class AuthModule {}
