import { Component, Input, OnInit, EventEmitter } from '@angular/core'

import { WidgetComponent } from '../../view'
import { RowField } from '../../config'

/**
 * can use for: select
 */
@Component({
  selector: 'bp--preset--form-select-widget',
  template: `
    <nz-select
      [ngModel]="value"
      (ngModelChange)="valueChange($event)"
      [nzDisabled]="readonly"
      [nzPlaceHolder]="field.form.placeholder"
    >
      <nz-option
        *ngFor="let m of maps"
        [nzLabel]="m.label"
        [nzValue]="m.value"
      ></nz-option>
    </nz-select>
  `,
})
export class BpFormSelectWidget extends WidgetComponent implements OnInit {
  static meta = {
    type: 'preset_form',
    name: 'select',
  }

  @Input() entity: any
  @Input() value: string
  @Input() field: RowField

  change = new EventEmitter<string>()
  readonly = false
  maps: {
    label: string
    value: string
  }[] = []

  ngOnInit() {
    this.readonly = this.field.feature.includes('readonly')

    if (typeof this.value === "number") {
      this.value = String(this.value)
    }

    this.maps = Object.entries(this.field.extra).map<any>(([value, label]) => ({
      label,
      value,
    }))
  }

  valueChange(e: string) {
    this.change.emit(e)
  }
}
