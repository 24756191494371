import { Action } from '@ngrx/store'
import { Update, EntityMap, Predicate } from '@ngrx/entity'
import { Module } from '../config'

export enum ListActionTypes {
  LOAD_ALL = '[Bp List] Load All',
  ADD_ONE = '[Bp List] Add One',
  ADD_MANY = '[Bp List] Add Many',
  UPSERT_ONE = '[Bp List] Upsert One',
  UPSERT_MANY = '[Bp List] Upsert Many',
  UPDATE_ONE = '[Bp List] Update One',
  UPDATE_MANY = '[Bp List] Update Many',
  MAP = '[Bp List] Map',
  DELETE_ONE = '[Bp List] Delete One',
  DELETE_MANY = '[Bp List] Delete Many',
  DELETE_MANY_BY_PREDICATE = '[Bp List] Delete Many By Predicate',
  CLEAR_ALL = '[Bp List] Clear All',
}

export interface ListAction extends Action {
  identifier: string
}

export class LoadAll implements ListAction {
  readonly type = ListActionTypes.LOAD_ALL

  constructor(
    public primaryKey = 'id',
    public identifier: string,
    public payload: { data: any[] },
  ) {}
}

export class AddOne implements ListAction {
  readonly type = ListActionTypes.ADD_ONE

  constructor(public identifier: string, public payload: { data: any }) {}
}

export class AddMany implements ListAction {
  readonly type = ListActionTypes.ADD_MANY

  constructor(public identifier: string, public payload: { data: any[] }) {}
}

export class UpsertOne implements ListAction {
  readonly type = ListActionTypes.UPSERT_ONE

  constructor(public identifier: string, public payload: { data: any }) {}
}

export class UpsertMany implements ListAction {
  readonly type = ListActionTypes.UPSERT_MANY

  constructor(public identifier: string, public payload: { data: any[] }) {}
}

export class UpdateOne implements ListAction {
  readonly type = ListActionTypes.UPDATE_ONE

  constructor(
    public identifier: string,
    public payload: { data: Update<any> },
  ) {}
}

export class UpdateMany implements ListAction {
  readonly type = ListActionTypes.UPDATE_MANY

  constructor(
    public identifier: string,
    public payload: { data: Update<any>[] },
  ) {}
}

export class Map implements ListAction {
  readonly type = ListActionTypes.MAP

  constructor(
    public identifier: string,
    public payload: { entityMap: EntityMap<any> },
  ) {}
}

export class DeleteOne implements ListAction {
  readonly type = ListActionTypes.DELETE_ONE

  constructor(
    public identifier: string,
    public payload: { id: string | number },
  ) {}
}

export class DeleteMany implements ListAction {
  readonly type = ListActionTypes.DELETE_MANY

  constructor(
    public identifier: string,
    public payload: { ids: string[] | number[] },
  ) {}
}

export class DeleteManyByPredicate implements ListAction {
  readonly type = ListActionTypes.DELETE_MANY_BY_PREDICATE

  constructor(
    public identifier: string,
    public payload: { predicate: Predicate<any> },
  ) {}
}

export class ClearAll implements ListAction {
  readonly type = ListActionTypes.CLEAR_ALL

  constructor(public identifier: string) {}
}

export type ListActionsUnion =
  | LoadAll
  | AddOne
  | AddMany
  | UpsertOne
  | UpsertMany
  | UpdateOne
  | UpdateMany
  | Map
  | DeleteOne
  | DeleteMany
  | DeleteManyByPredicate
  | ClearAll
