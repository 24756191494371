import { Injectable } from '@angular/core'
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpErrorResponse,
} from '@angular/common/http'
import { Store } from '@ngrx/store'
import { Observable } from 'rxjs'
import { tap } from 'rxjs/operators'

import { TokenService } from '../../services'
import * as fromAuth from '../../store'
import * as authAction from '../../store/actions'

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private _tokenService: TokenService,
    private _store: Store<fromAuth.AuthState>,
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    const noauth = req.params.get('skipAuth')
    let cloneReq: HttpRequest<any>

    if (!noauth) {
      // set cookie
      const cookie = this._tokenService.get()
      if (cookie) {
        cloneReq = req.clone({
          headers: req.headers.set('Authorization', `Bearer ${cookie}`),
        })
      } else {
        cloneReq = req
      }
    } else {
      cloneReq = req.clone({
        params: req.params.delete('skipAuth'),
      })
    }

    return next.handle(cloneReq).pipe(
      tap(
        event => {},
        err => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
              this._store.dispatch(new authAction.AuthFailure(err))
            }
          }
        },
      ),
    )
  }
}
