export function preloaderFinished() {
  setTimeout(() => {
    const preloader = document.querySelector('.preloader')

    // preloader value null when running --hmr
    if (!preloader) return
    preloader.addEventListener('transitionend', function() {
      preloader.className = 'preloader-hidden'
    })

    preloader.className += ' preloader-hidden-add preloader-hidden-add-active'
  }, 100)
}
