import {
  Directive,
  Inject,
  ViewContainerRef,
  OnInit,
  ComponentRef,
  OnDestroy,
  Input,
  forwardRef,
  OnChanges,
  SimpleChanges,
  SimpleChange,
  Output,
  EventEmitter,
} from '@angular/core'
import {
  NG_VALUE_ACCESSOR,
  ControlValueAccessor,
  FormGroup,
} from '@angular/forms'
import { Subscription } from 'rxjs'

import { DynamicComponentLoader } from '../dynamic_component_loader'
import { WIDGET_LOADER } from '../loader'
import { RowField, FieldFeature, Module } from '../../config'
import { WidgetComponentDef, WidgetState } from '../definition'
import { Payload } from '../../interface'

@Directive({
  selector: 'bp-widget',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BpWidget),
      multi: true,
    },
  ],
})
export class BpWidget
  implements OnInit, OnChanges, OnDestroy, ControlValueAccessor {
  @Input() transform: {
    type: string
    name: string
  }
  // Completed data about entity, for list
  @Input() entity: any
  @Input() field: RowField
  @Input() state: WidgetState
  // For form & search
  @Input() formGroup: FormGroup
  @Input() module: Module
  // For search
  @Output() dispatch = new EventEmitter<Payload>()

  private _latestValue: any
  private _component: ComponentRef<WidgetComponentDef>
  private _subs = new Subscription()

  onChange: (value: any | any[]) => void = () => null
  onTouched: () => void = () => null

  constructor(
    @Inject(WIDGET_LOADER) private _loader: DynamicComponentLoader,
    private _vcRef: ViewContainerRef,
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    this._instanceChange(changes)
  }

  ngOnInit() {
    if (!this.transform)
      throw new Error(`Need give a transform name in bp-widget component`)

    const comp = this._loader.get(this.transform.type, this.transform.name)
    if (!comp)
      throw new Error(
        `Can't find the component: ${JSON.stringify(this.transform)}`,
      )

    this._component = this._vcRef.createComponent(comp.factory)
    this._initInstance()
  }

  ngOnDestroy() {
    if (this._component) this._component.destroy()
    this._subs.unsubscribe()
  }

  // ControlValueAccessor
  writeValue(value: any | any[]) {
    if (!this._component) return (this._latestValue = value)

    this._instanceChange({
      value: new SimpleChange(this._component.instance.value, value, false),
    })
  }
  setDisabledState(isDisabled: boolean) {
    const featureWithoutRO = this.field.feature.filter(f => f !== 'readonly')
    const feature: FieldFeature[] = isDisabled
      ? [...featureWithoutRO, 'readonly']
      : featureWithoutRO

    this._instanceChange({
      field: new SimpleChange(
        this.field,
        {
          ...this.field,
          feature,
        },
        false,
      ),
    })
  }
  registerOnChange(fn: (value: any | any[]) => void) {
    this.onChange = fn
  }
  registerOnTouched(fn: () => void) {
    this.onTouched = fn
  }

  private _initInstance() {
    const instance = this._component.instance

    instance.value =
      this._latestValue || (this.entity && this.entity[this.field.identifier])
    instance.field = this.field
    instance.entity = this.entity
    instance.state = this.state
    instance.formGroup = this.formGroup
    instance.module = this.module

    if (instance.change) {
      const changeObservable = instance.change.asObservable()

      this._subs.add(
        changeObservable.subscribe(value => {
          if (value !== this._component.instance.value) {
            instance.value = value
            this.onChange(value)
          }
        }),
      )
    }
    if (instance.touch) {
      const touchObservable = instance.touch.asObservable()

      this._subs.add(touchObservable.subscribe(() => this.onTouched()))
    }
    if (instance.dispatch) {
      const dispatchObservable = instance.dispatch.asObservable()

      this._subs.add(
        dispatchObservable.subscribe(payload => {
          this.dispatch.emit(payload)
        }),
      )
    }
  }

  private _instanceChange(changes: SimpleChanges) {
    if (!this._component) return

    Object.entries(changes).forEach(([key, value]) => {
      this._component.instance[key] = value.currentValue
    })
    this._component.instance.ngOnChanges &&
      this._component.instance.ngOnChanges(changes)
  }
}
