import { Injectable } from '@angular/core'

export type EventNames = 'create' | 'delete' | 'updateById' | 'sequence'

export type Event = {
  prev: any
  current: any
  updateId?: any
}

export type Subscriber = (payload: Event) => any

@Injectable({ providedIn: 'root' })
export class AccessorEvent {
  private _events: {
    [props: string]: Subscriber
  } = {}

  subscribe(moduleIdentifier: string, eventName: EventNames, fn: Subscriber) {
    const _eventName = this._getEventName(moduleIdentifier, eventName)

    this._events[_eventName] = fn

    return () => {
      this._events[_eventName] = null
    }
  }

  emit(moduleIdentifier: string, eventName: EventNames, data: Event) {
    const _eventName = this._getEventName(moduleIdentifier, eventName)
    const event = this._events[_eventName]

    return event && event(data)
  }

  private _getEventName(identifier: string, eventName: EventNames): string {
    return `${identifier}_${eventName}`
  }
}
