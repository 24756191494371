import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
} from '@angular/common/http'

import { Observable } from 'rxjs'

export class BaseURLInterceptor implements HttpInterceptor {
  constructor(private baseURL: string = '') {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    req = req.clone({ url: (/^((http|https):\/\/)/.test(req.url) ? '' : this.baseURL) + req.url })
    return next.handle(req)
  }
}
