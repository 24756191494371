import { EventEmitter } from '@angular/core'

import { DynamicComponent } from './dynamic_component'
import { Payload } from '../../interface'
import { Module } from '../../config'

export interface ActionComponentDef {
  entity?: any | any[]
  module?: Module
  // HostListener
  bpOnClick?: () => void

  dispatch?: EventEmitter<Payload>
}

export class ActionComponent extends DynamicComponent {}
