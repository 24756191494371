import {
    Component,
    OnInit,
    Input,
    ViewChild,
    TemplateRef
  } from '@angular/core'
  import { FormGroup } from '@angular/forms'
  import { WidgetComponent, RowField } from '@bp/core'
  import { NzModalService } from 'ng-zorro-antd'

  @Component({
    selector: 'app-template-video',
    templateUrl: './template-video.component.html',
    styleUrls: ['./template-video.component.styl'],
  })
  export class TemplateVideoComponent extends WidgetComponent
    implements OnInit {
    static meta = {
      type: 'custom',
      name: 'template-video',
    }
    @ViewChild('image', { static: true }) imageTpl: TemplateRef<any>
    @Input() entity: any
    @Input() value: never
    @Input() field: RowField
    @Input() formGroup: FormGroup

    imageTplPath: string
    // medias = []

    constructor(private modalService: NzModalService) {
      super()
    }

    openModal(path: string) {
      this.imageTplPath = path
      this.modalService.create({
        nzContent: this.imageTpl,
        nzFooter: null,
        nzClosable: false,
      })
    }

    ngOnInit() {
      if (!this.entity) return
      // this.medias = this.entity.medias.map(media => ({
      //   ...media,
      //   path: `${media.path}`,
      // }))
    }
  }
