import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { RouterModule } from '@angular/router'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { NZ_I18N, zh_CN, NzConfig, NZ_CONFIG } from 'ng-zorro-antd'
import { LoadDevPresetModule } from '@bp/dev'
import { BlueprintDeclarationModule } from '@bp/core'

import { environment } from 'src/environments/environment'
import { LAYOUTS } from './layout'
import { COMPONENTS } from './components'
import { VIEWS } from './views'
import { SharedModule } from '~/shared/shared.module'

const ngZorroConfig: NzConfig = {
  message: { nzDuration: 3000 },
  notification: { nzDuration: 3000 },
}

@NgModule({
  declarations: [LAYOUTS, COMPONENTS, VIEWS],
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    BlueprintDeclarationModule,
    environment.production ? [] : LoadDevPresetModule,
  ],
  providers: [
    { provide: NZ_CONFIG, useValue: ngZorroConfig },
    { provide: NZ_I18N, useValue: zh_CN },
  ],
  exports: [LAYOUTS],
})
export class CoreModule {
  constructor() {}
}
