import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { CommonModule } from '@angular/common'

import { BlueprintModule, BlueprintDeclarationModule } from '@bp/core'
import { CommonModule as BpCommonModule } from '@bp/common'
import { BpActionFormExtra } from './action/form-extra'
import { BpFieldFormExtra } from './field/form-extra'
import { ShowComponentPipe } from './field/show-component.pipe'
import { BpFieldFormMore } from './field/form-more'
import { TypeSelectComponent } from '../components/type-select/type-select'
import { BpModuleListAcl } from './module/list-acl'
import { BpModuleActionCopy } from './module/action-copy'
import { BpHookSendAcl } from './module/send-acl.hook'

const COMPONENTS = [
  BpActionFormExtra,
  BpFieldFormExtra,
  BpFieldFormMore,
  BpModuleListAcl,
  BpModuleActionCopy,
  // hook
  BpHookSendAcl,
]

@NgModule({
  declarations: [COMPONENTS, ShowComponentPipe, TypeSelectComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BpCommonModule,
    BlueprintDeclarationModule,
    BlueprintModule.load({ components: COMPONENTS }),
  ],
})
export class LoadDevPresetModule {}
