import { createSelector } from '@ngrx/store'
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity'

import { ListActionTypes, ListActionsUnion } from './list_actions'

export interface Adapters {
  [props: string]: EntityAdapter<any>
}

export interface State {
  [props: string]: EntityState<any>
}

export const adapters: Adapters = {}

export const initialState: State = {}

export function reducer(state = initialState, action: ListActionsUnion): State {
  const { identifier } = action

  if (action.type !== ListActionTypes.LOAD_ALL && !state[identifier])
    return state

  switch (action.type) {
    case ListActionTypes.LOAD_ALL:
      if (!state[identifier]) {
        const adapter =
          adapters[identifier] ||
          (adapters[identifier] = (function(primaryKey) {
            return createEntityAdapter({
              selectId: data => data[primaryKey],
            })
          })(action.primaryKey))

        state = {
          ...state,
          [identifier]: adapter.getInitialState(),
        }
      }

      return {
        ...state,
        [identifier]: adapters[identifier].addAll(
          action.payload.data,
          state[action.identifier],
        ),
      }

    case ListActionTypes.ADD_ONE:
      return {
        ...state,
        [action.identifier]: adapters[identifier].addOne(
          action.payload.data,
          state[action.identifier],
        ),
      }

    case ListActionTypes.ADD_MANY:
      return {
        ...state,
        [action.identifier]: adapters[identifier].addMany(
          action.payload.data,
          state[action.identifier],
        ),
      }

    case ListActionTypes.UPSERT_ONE:
      return {
        ...state,
        [action.identifier]: adapters[identifier].upsertOne(
          action.payload.data,
          state[action.identifier],
        ),
      }

    case ListActionTypes.UPSERT_MANY:
      return {
        ...state,
        [action.identifier]: adapters[identifier].upsertMany(
          action.payload.data,
          state[action.identifier],
        ),
      }

    case ListActionTypes.UPDATE_ONE:
      return {
        ...state,
        [action.identifier]: adapters[identifier].updateOne(
          action.payload.data,
          state[action.identifier],
        ),
      }

    case ListActionTypes.UPDATE_MANY:
      return {
        ...state,
        [action.identifier]: adapters[identifier].updateMany(
          action.payload.data,
          state[action.identifier],
        ),
      }

    case ListActionTypes.MAP:
      return {
        ...state,
        [action.identifier]: adapters[identifier].map(
          action.payload.entityMap,
          state[action.identifier],
        ),
      }

    case ListActionTypes.DELETE_ONE:
      return {
        ...state,
        [action.identifier]: adapters[identifier].removeOne(
          action.payload.id as string,
          state[action.identifier],
        ),
      }

    case ListActionTypes.DELETE_MANY:
      return {
        ...state,
        [action.identifier]: adapters[identifier].removeMany(
          action.payload.ids as string[],
          state[action.identifier],
        ),
      }

    case ListActionTypes.DELETE_MANY_BY_PREDICATE:
      return {
        ...state,
        [action.identifier]: adapters[identifier].removeMany(
          action.payload.predicate,
          state[action.identifier],
        ),
      }

    case ListActionTypes.CLEAR_ALL:
      return {
        ...state,
        [action.identifier]: adapters[identifier].removeAll({
          ...state[action.identifier],
        }),
      }

    default:
      return state
  }
}

export const selectDataIds = (state: State, identifier: string) =>
  state[identifier].ids

export const selectDataEntities = (state: State, identifier: string) =>
  state[identifier].entities

export const selectAllData = (identifier: string) =>
  createSelector(
    (state: State) => selectDataIds(state, identifier),
    (state: State) => selectDataEntities(state, identifier),
    (ids: string[], entities) => ids.map(id => entities[id]),
  )

export const selectDataTotal = (state: State, identifier: string) =>
  state[identifier].ids.length
