import {
  // Directive,
  Inject,
  ViewContainerRef,
  OnInit,
  ComponentRef,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
  Component,
} from '@angular/core'
import { Subscription } from 'rxjs'

import { DynamicComponentLoader } from '../dynamic_component_loader'
import { WIDGET_LOADER } from '../loader'
import { Payload } from '../../interface'
import { ActionComponentDef } from '../definition'
import { Module } from '../../config'

// usage for template, can't use @Directive
@Component({ selector: 'bp-action', template: '' })
export class BpAction implements OnInit, OnDestroy {
  @Input() transform: {
    type: string
    name: string
  }
  @Input() entity: any
  @Input() module: Module

  @Output() dispatch = new EventEmitter<Payload>()

  private _component: ComponentRef<ActionComponentDef>
  private _subs = new Subscription()

  click: () => any = () => {}

  constructor(
    @Inject(WIDGET_LOADER) private _loader: DynamicComponentLoader,
    private _vcRef: ViewContainerRef,
  ) {}

  ngOnInit() {
    if (!this.transform)
      throw new Error(`Need give a transform name in bp-action component`)

    const comp = this._loader.get(this.transform.type, this.transform.name)
    if (!comp)
      throw new Error(
        `Can't find the component: ${JSON.stringify(this.transform)}`,
      )

    this._component = this._vcRef.createComponent(comp.factory)
    this._initInstance()
  }

  ngOnDestroy() {
    if (this._component) this._component.destroy()
  }

  private _initInstance() {
    const instance = this._component.instance
    const instanceClickHandle = instance.bpOnClick

    if (instanceClickHandle) this.click = instanceClickHandle.bind(instance)

    instance.entity = this.entity
    instance.module = this.module

    if (instance.dispatch) {
      const dispatchObservable = instance.dispatch.asObservable()

      this._subs.add(
        dispatchObservable.subscribe(payload => {
          this.dispatch.emit(payload)
        }),
      )
    }
  }
}
