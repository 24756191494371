import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'slice',
})
export class SlicePipe implements PipeTransform {
  transform(str: string, len = 20) {
    const isLongTag = str.length > len
    return isLongTag ? `${str.slice(0, len)}...` : str
  }
}
