import { EventEmitter } from '@angular/core'

import { DynamicComponent } from './dynamic_component'
import { HookPayload } from '../../interface'

export interface HookComponentDef {
  entity?: any | any[]

  dispatch?: EventEmitter<HookPayload>
}

export class HookComponent extends DynamicComponent {}
