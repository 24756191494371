import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms'
import { tap, finalize } from 'rxjs/operators'
import { Store } from '@ngrx/store'
import { NzMessageService } from 'ng-zorro-antd'

import * as fromAuth from '../store'
import * as authAction from '../store/actions'
import { RegisterRequest, AuthHttpService } from '../http'
import { TokenService } from '../services'

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.styl'],
})
export class RegisterComponent implements OnInit {
  validateForm: FormGroup
  loading = false

  constructor(
    private _fb: FormBuilder,
    private _store: Store<fromAuth.AuthState>,
    private _msgService: NzMessageService,
    private _authService: AuthHttpService,
    private _tokenService: TokenService,
  ) {
    this.validateForm = this._fb.group({
      username: [null, [Validators.required]],
      password: [null, [Validators.required]],
      checkPassword: [null, [Validators.required, this.confirmationValidator]],
      smsCode: [null, [Validators.required]],
      name: [null, [Validators.required]],
    })
  }

  ngOnInit() {}

  submitForm() {
    if (this.loading) return

    const {
      value: {
        username,
        password,
        checkPassword,
        smsCode,
        // name,
      },
      valid,
      controls,
    } = this.validateForm

    for (const i in controls) {
      if (i) {
        controls[i].markAsDirty()
        controls[i].updateValueAndValidity()
      }
    }

    if (valid) {
      this._register({ username, password, sms_code: smsCode })
    }
  }

  updateConfirmValidator() {
    setTimeout(_ => {
      this.validateForm.controls['checkPassword'].updateValueAndValidity()
    })
  }

  sendMsg() {
    console.log('send')
  }

  private confirmationValidator = (
    control: FormControl,
  ): { [status: string]: boolean } => {
    if (!control.value) {
      return { required: true }
    } else if (control.value !== this.validateForm.controls['password'].value) {
      return { confirm: true, error: true }
    }
  }

  private _register(params: RegisterRequest) {
    const msgId = this._msgService.loading('注册中...', { nzDuration: 0 })
      .messageId

    this.loading = true

    this._authService
      .register(params)
      .pipe(
        tap(res => {
          this._msgService.success('注册成功!')
          this._tokenService.set(res.token, res.expires_in)
          this._store.dispatch(new authAction.AuthSuccess({ user: res.user }))
        }),
        finalize(() => {
          this.loading = false
          this._msgService.remove(msgId)
        }),
      )
      .subscribe()
  }
}
