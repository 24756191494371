import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { tap, catchError, finalize } from 'rxjs/operators'
import { Store } from '@ngrx/store'
import { NzMessageService } from 'ng-zorro-antd'

import * as fromAuth from '../store'
import * as authAction from '../store/actions'
import { LoginRequest, AuthHttpService } from '../http'
import { TokenService } from '../services'

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.styl'],
})
export class LoginComponent implements OnInit {
  validateForm: FormGroup = this._fb.group({
    name: [null, [Validators.required]],
    password: [null, [Validators.required]],
    remember: [true],
  })
  loading = false

  constructor(
    private _fb: FormBuilder,
    private _store: Store<fromAuth.AuthState>,
    private _msgService: NzMessageService,
    private _authService: AuthHttpService,
    private _tokenService: TokenService,
  ) {}

  ngOnInit() {}

  submitForm() {
    if (this.loading) return

    const {
      value: { name, password },
      valid,
      controls,
    } = this.validateForm

    for (const i in controls) {
      if (i) {
        controls[i].markAsDirty()
        controls[i].updateValueAndValidity()
      }
    }

    if (valid) {
      this._login({ name, password })
    }
  }

  private _login(params: LoginRequest) {
    const msgId = this._msgService.loading('验证中...', { nzDuration: 0 })
      .messageId

    this.loading = true

    this._authService
      .login(params)
      .pipe(
        tap(res => {
          this._msgService.success('登录成功!')
          this._tokenService.set(res.token, res.expires_in)
          this._store.dispatch(new authAction.AuthSuccess({ user: res.user }))
        }),
        catchError(err => {
          this._store.dispatch(new authAction.AuthFailure(err))
          throw err
        }),
        finalize(() => {
          this.loading = false
          this._msgService.remove(msgId)
        }),
      )
      .subscribe()
  }
}
