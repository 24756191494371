import { InjectionToken, Provider } from '@angular/core'
import { Observable, of } from 'rxjs'

export type AclValue = AclField[]

export type AclField = string[]

export interface AclAccessor {
  get(): Observable<AclValue>

  set(acl: AclValue): void
}
export class AclAccessor {}

export const BP_ACL_ACCESSOR = new InjectionToken<AclAccessor>('ACL Accessor')

export class DefaultAclAccessor implements AclAccessor {
  acls: AclValue = [['*']]

  get() {
    return of(this.acls)
  }

  set(acl: AclValue) {
    this.acls = acl
  }
}

export function provideAclAccessor(): Provider {
  return [
    {
      provide: BP_ACL_ACCESSOR,
      useClass: DefaultAclAccessor,
    },
  ]
}
