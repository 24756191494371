import {
  Component,
  Input,
  OnInit,
  EventEmitter,
  OnDestroy,
  OnChanges,
  SimpleChanges,
} from '@angular/core'
import { FormGroup, FormBuilder } from '@angular/forms'
import { Subscription } from 'rxjs'
import { WidgetComponent, RowField, ActionRouteType, RowAction } from '@bp/core'
import {
  routeFields,
  listRouteFields,
  entryFields,
  batchFields,
} from './fields'

const PATH_MAP = {
  list: '',
  form: 'edit/:id',
  profile: 'p/:id',
}

@Component({
  selector: 'bp--preset--action-form-extra',
  templateUrl: './form-extra.html',
})
export class BpActionFormExtra extends WidgetComponent
  implements OnInit, OnDestroy, OnChanges {
  static meta = {
    name: 'action-form-extra',
  }

  @Input() entity: RowAction
  /** Standalone custom component havn't input value */
  @Input() value: never
  @Input() field: RowField
  @Input() formGroup: FormGroup

  formStyle = {
    offset: 5,
    span: 15,
  }
  change = new EventEmitter<string>()
  readonly = false

  // forms abstract control
  routeForm = this._fb.group({
    path: [null],
    type: [null],
    feature: [[]],
    color: [[]],
  })
  entryForm = this._fb.group({
    type: [null],
    hide: [null],
    extra: [null],
    condition: [null],
  })
  batchForm = this._fb.group({
    type: [null],
    extra: [null],
  })
  // form fields
  routeFields = routeFields
  listRouteFields = listRouteFields
  entryFields = entryFields
  batchFields = batchFields

  private _subs = new Subscription()

  constructor(private _fb: FormBuilder) {
    super()
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.entity) this._fillFormsData()
  }

  ngOnInit() {
    this.readonly = this.field.feature.includes('readonly')

    this._initForms()
  }

  ngOnDestroy() {
    this._subs.unsubscribe()
  }

  private _initForms() {
    const typeField = this.formGroup.get('type')

    this.formGroup.addControl('route', this.routeForm)
    this.formGroup.addControl('entry', this.entryForm)
    this.formGroup.addControl('batch', this.batchForm)
    this._disableForms()

    if (typeField.value === 'route') this._enableRouteForm()
    this._subs.add(
      typeField.valueChanges.subscribe(v => {
        this._disableForms()

        switch (v) {
          case 'route':
            return this._enableRouteForm()
          case 'entry':
            return this._enableEntryForm()
          case 'batch':
            return this._enableBatchForm()
        }
      }),
    )
    this._subs.add(
      this.routeForm
        .get('type')
        .valueChanges.subscribe((v: ActionRouteType) => {
          if (v === 'list') {
            this._enableListForm()
          } else {
            this._disableListForm()
          }

          this.routeForm.get('path').setValue(PATH_MAP[v])
        }),
    )

    this._setDefaultFormsData()
    // Fill data after form init can subscribe action route type change
    this._fillFormsData()
  }

  private _disableForms() {
    this._disableRouteForm()
    this._disableListForm()

    this._disableEntryForm()
    this._disableBatchForm()
  }

  private _enableRouteForm() {
    this.routeForm.enable({ onlySelf: true })
  }

  private _disableRouteForm() {
    this.routeForm.disable({ onlySelf: true, emitEvent: false })
  }

  private _enableListForm() {
    ;['feature', 'color'].forEach(key => {
      this.routeForm.get(key).enable({ onlySelf: true, emitEvent: false })
    })
  }

  private _disableListForm() {
    ;['feature', 'color'].forEach(key => {
      this.routeForm.get(key).disable({ onlySelf: true, emitEvent: false })
    })
  }

  private _enableEntryForm() {
    this.entryForm.enable({ onlySelf: true })
  }

  private _disableEntryForm() {
    this.entryForm.disable({ onlySelf: true, emitEvent: false })
  }

  private _enableBatchForm() {
    this.batchForm.enable({ onlySelf: true })
  }

  private _disableBatchForm() {
    this.batchForm.disable({ onlySelf: true, emitEvent: false })
  }

  private _setDefaultFormsData() {
    const routeForm = this.routeForm

    this.routeFields.forEach(field => {
      const defaultVal = field.form.default
      if (defaultVal) routeForm.get(field.identifier).setValue(defaultVal)
    })
    this.listRouteFields.forEach(field => {
      const defaultVal = field.form.default
      if (defaultVal) routeForm.get(field.identifier).setValue(defaultVal)
    })

    this.entryFields.forEach(field => {
      const defaultVal = field.form.default
      if (defaultVal) this.entryForm.get(field.identifier).setValue(defaultVal)
    })

    this.batchFields.forEach(field => {
      const defaultVal = field.form.default
      if (defaultVal) this.batchForm.get(field.identifier).setValue(defaultVal)
    })
  }

  private _fillFormsData() {
    const data = this.entity
    if (!data) return

    this._disableForms()
    switch (data.type) {
      case 'route':
        this._enableRouteForm()
        break
      case 'entry':
        this._enableEntryForm()
        break
      case 'batch':
        this._enableBatchForm()
        break
    }

    if (data.route) this.routeForm.patchValue(data.route)
    if (data.entry) this.entryForm.patchValue(data.entry)
    if (data.batch) this.batchForm.patchValue(data.batch)
  }
}
