import { Component, OnInit, Input, EventEmitter } from '@angular/core'
import { ActionComponent, Module, Payload } from '@bp/core'
import { NzMessageService } from 'ng-zorro-antd'
import { SharedHttpService } from '~/shared/http/shared-http.service'
import { FormBuilder, Validators } from '@angular/forms'

@Component({
  selector: 'app-template-copy',
  templateUrl: './template-copy.component.html',
  styleUrls: ['./template-copy.component.styl'],
})
export class TemplateCopyComponent extends ActionComponent implements OnInit {
  static meta = {
    type: 'custom_action',
    name: 'template-copy',
  }

  dispatch = new EventEmitter<Payload>()

  @Input() entity: any
  @Input() module: Module

  constructor(
    private _sharedHttp: SharedHttpService,
    private _msgService: NzMessageService,
    private _fb: FormBuilder,
  ) {
    super()
  }

  ngOnInit() {}

  bpOnClick = () => {
    this._sharedHttp.templateBatch(
      this.entity.id,
      'copy',
      { }
    ).subscribe(res => {
      this._msgService.success('操作成功')
      this.dispatch.emit({ type: 'refresh' })
    })
  }
}
