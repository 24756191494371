import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Apollo } from 'apollo-angular'
import gql from 'graphql-tag'
import { Observable, throwError } from 'rxjs'
import { map, catchError } from 'rxjs/operators'

import { User } from '../models'

export type LoginRequest = {
  name: string
  password: string
}

export type LoginResponse = {
  token: string
  expires_in: number
  user: User
}

export type RegisterRequest = {
  username: string
  password: string
  sms_code: string
}

@Injectable({
  providedIn: 'root',
})
export class AuthHttpService {
  constructor(private http: HttpClient, private _apollo: Apollo) {}

  login(params: LoginRequest): Observable<LoginResponse> {
    return this.http
      .post<any>('login', params, {
        params: { skipAuth: '1' },
      })
      .pipe(map(res => res.data))
    // return this._apollo
    //   .mutate({
    //     mutation: gql`
    //       mutation Auth($name: String, $password: String) {
    //         login(name: $name, password: $password) {
    //           token
    //           expires_in
    //           staff {
    //             id
    //             name
    //             acl
    //           }
    //         }
    //       }
    //     `,
    //     variables: {
    //       name: params.username,
    //       password: params.password,
    //     },
    //   })
    //   .pipe(map(res => res.data.login))
  }

  register(params: RegisterRequest): Observable<LoginResponse> {
    return this.http
      .post<any>('register', params, {
        params: { skipAuth: '1' },
      })
      .pipe(map(res => res.data))
    // return this._apollo
    //   .mutate({
    //     mutation: gql`
    //       mutation Auth($name: String, $password: String) {
    //         register(name: $name, password: $password) {
    //           token
    //           expires_in
    //           staff {
    //             id
    //             name
    //           acl
    //         }
    //       }
    //     }
    //   `,
    //   variables: {
    //     name: params.username,
    //     password: params.password,
    //   },
    // })
    // .pipe(map(res => res.data.register))
  }

  logout() {
    return this.http.delete('logout')
    // return this._apollo.mutate({
    //   mutation: gql`
    //     mutation Auth {
    //       logout
    //     }
    //   `,
    // })
  }

  session(): Observable<User> {
    return this.http.get<any>('session')
      .pipe(map(res => res.data))
    // return this._apollo
    //   .query({
    //     query: gql`
    //       query Auth {
    //         session {
    //           id
    //           name
    //           acl
    //         }
    //       }
    //     `,
    //   })
    //   .pipe(map((res: any) => res.data.session))
  }
}
