import { InjectionToken } from '@angular/core'

import { RowModule, RowNav } from './model'

export const BP_PRESET_DEV_MODULE = new InjectionToken<RowModule>(
  'Preset Dev Module',
)
export const BP_PRESET_DEV_NAV = new InjectionToken<RowNav>('Preset Dev Nav')

export const BP_PRESET_PREFIX = 'dev'

export const fieldTypes = {
  text: '文本',
  password: '密码',
  number: '数字',
  textarea: '文本域',
  editor: '富文本',
  color: '颜色',
  date: '日期',
  datetime: '日期时间',
  time: '时间',
  checkbox: '多选框',
  select: '下拉框',
  file: '文件',
  image: '图片',
  switch: '开关',
  province: '省',
  city: '市',
  district: '区',
  cascader: '级联',
  relation: '关联(单选)',
  relations: '关联(多选)',
  template: '模板',
  tag: '标签',
  map: 'KV Map',
  custom: '自定义',
}

export const presetDevModule: RowModule[] = [
  {
    parent_id: null,
    name: '模块管理',
    identifier: `${BP_PRESET_PREFIX}/module`,
    primary_key: 'identifier',
    level: 'system',
    enable: true,
    action: [
      {
        name: '模块列表',
        identifier: 'list',
        type: 'route',
        enable: true,
        route: {
          path: '',
          type: 'list',
          feature: ['create', 'tree', 'drag'],
          color: {
            '#fbf9ea': 'this.level === "system" || this.identifier === "role"',
          },
        },
      },
      {
        name: '操作',
        identifier: 'action',
        type: 'entry',
        enable: true,
        entry: {
          type: 'link',
          hide: false,
          extra: 'action',
        },
      },
      {
        name: '字段',
        identifier: 'field',
        type: 'entry',
        enable: true,
        entry: {
          type: 'link',
          hide: false,
          extra: 'field',
        },
      },
      {
        name: '编辑',
        identifier: 'edit',
        type: 'entry',
        enable: true,
        entry: {
          type: 'form',
          hide: true,
          extra: 'default',
        },
      },
      {
        name: '删除',
        identifier: 'delete',
        type: 'entry',
        enable: true,
        entry: {
          type: 'delete',
          hide: true,
          extra: 'default',
        },
      },
      {
        name: '复制',
        identifier: 'copy',
        type: 'entry',
        enable: true,
        entry: {
          type: 'custom',
          hide: true,
          extra: 'module-action-copy',
        },
      },
    ],
    field: [
      {
        name: '名称',
        identifier: 'name',
        type: 'text',
        place: ['list', 'create', 'edit', 'profile'],
        feature: [],
        list: {
          type: 'text',
        },
        form: {
          type: 'text',
        },
        profile: {
          type: 'text',
        },
        search: {
          type: 'text',
        },
      },
      {
        name: '标识符',
        identifier: 'identifier',
        type: 'text',
        place: ['list', 'create', 'edit', 'profile'],
        feature: [],
        list: {
          type: 'text',
        },
        form: {
          type: 'text',
          comment: '路由起始位置',
        },
        profile: {
          type: 'text',
        },
        search: {
          type: 'text',
        },
      },
      {
        name: '主键',
        identifier: 'primary_key',
        type: 'text',
        place: ['create', 'edit', 'profile'],
        feature: [],
        list: {
          type: 'text',
        },
        form: {
          type: 'text',
          comment: '默认主键为id',
          default: 'id',
        },
        profile: {
          type: 'text',
        },
        search: {
          type: 'text',
        },
      },
      {
        name: '级别',
        identifier: 'level',
        type: 'select',
        place: ['list', 'create', 'edit', 'profile'],
        feature: [],
        extra: {
          system: '系统',
          user: '用户',
        },
        list: {
          type: 'text',
        },
        form: {
          type: 'select',
          default: 'user',
        },
        profile: {
          type: 'text',
        },
        search: {
          type: 'select',
        },
      },
      {
        name: '启用',
        identifier: 'enable',
        type: 'switch',
        place: ['list', 'create', 'edit', 'profile'],
        feature: [],
        list: {
          type: 'switch',
        },
        form: {
          type: 'switch',
          default: 1,
        },
        profile: {
          type: 'switch',
        },
        search: {
          type: 'select',
        },
      },
      {
        name: '排除ACL',
        identifier: 'exclude_acl',
        type: 'switch',
        place: ['create', 'edit', 'profile'],
        feature: [],
        list: {
          type: 'switch',
        },
        form: {
          type: 'switch',
          default: 0,
        },
        profile: {
          type: 'switch',
        },
        search: {
          type: 'select',
        },
      },
      {
        name: '自定义字段',
        identifier: 'custom_field',
        type: 'switch',
        place: ['create', 'edit', 'profile'],
        feature: [],
        list: {
          type: 'tag',
        },
        form: {
          type: 'tag',
          comment: '用于graphql查询',
        },
        profile: {
          type: 'tag',
        },
        search: {
          type: 'text',
        },
      },
      {
        name: 'ACLs',
        identifier: 'acls',
        type: 'custom',
        place: ['list'],
        feature: [],
        list: {
          type: 'module-list-acl',
        },
        form: {
          type: 'text',
        },
        profile: {
          type: 'text',
        },
        search: {
          type: 'text',
        },
      },
    ],
  },
  {
    parent_id: 'dev/module',
    name: '操作管理',
    identifier: 'action',
    primary_key: 'identifier',
    level: 'system',
    enable: true,
    action: [
      {
        name: '操作列表',
        identifier: 'list',
        type: 'route',
        enable: true,
        route: {
          path: '',
          type: 'list',
          feature: ['create', 'drag'],
        },
      },
      {
        name: '编辑',
        identifier: 'edit',
        type: 'entry',
        enable: true,
        entry: {
          type: 'form',
          hide: false,
          extra: 'default',
        },
      },
      {
        name: '删除',
        identifier: 'delete',
        type: 'entry',
        enable: true,
        entry: {
          type: 'delete',
          hide: false,
          extra: 'default',
        },
      },
    ],
    field: [
      {
        name: '名称',
        identifier: 'name',
        type: 'text',
        place: ['list', 'create', 'edit', 'profile'],
        feature: [],
        list: {
          type: 'text',
        },
        form: {
          type: 'text',
        },
        profile: {
          type: 'text',
        },
        search: {
          type: 'text',
        },
      },
      {
        name: '标识符',
        identifier: 'identifier',
        type: 'text',
        place: ['list', 'create', 'edit', 'profile'],
        feature: [],
        list: {
          type: 'text',
        },
        form: {
          type: 'text',
        },
        profile: {
          type: 'text',
        },
        search: {
          type: 'text',
        },
      },
      {
        name: '类型',
        identifier: 'type',
        type: 'select',
        place: ['list', 'create', 'edit', 'profile'],
        feature: [],
        extra: {
          route: '路由',
          entry: '操作入口',
          batch: '批量操作',
          acl: '自定义权限',
        },
        list: {
          type: 'text',
        },
        form: {
          type: 'select',
          default: 'route',
        },
        profile: {
          type: 'text',
        },
        search: {
          type: 'select',
        },
      },
      {
        name: '启用',
        identifier: 'enable',
        type: 'switch',
        place: ['list', 'create', 'edit', 'profile'],
        feature: [],
        list: {
          type: 'switch',
        },
        form: {
          type: 'switch',
          default: 1,
        },
        profile: {
          type: 'switch',
        },
        search: {
          type: 'select',
        },
      },
      {
        name: '排除ACL',
        identifier: 'exclude_acl',
        type: 'switch',
        place: ['create', 'edit', 'profile'],
        feature: [],
        list: {
          type: 'switch',
        },
        form: {
          type: 'switch',
          default: 0,
        },
        profile: {
          type: 'switch',
        },
        search: {
          type: 'select',
        },
      },
      {
        name: '更多',
        identifier: 'extra',
        type: 'custom',
        place: ['create', 'edit', 'profile'],
        feature: [],
        list: {
          type: 'text',
        },
        form: {
          type: 'action-form-extra',
          standalone: true,
        },
        profile: {
          type: 'text',
        },
        search: {
          type: 'text',
        },
      },
    ],
  },
  {
    parent_id: 'dev/module',
    name: '字段管理',
    identifier: 'field',
    primary_key: 'identifier',
    level: 'system',
    enable: true,
    action: [
      {
        name: '字段列表',
        identifier: 'list',
        type: 'route',
        enable: true,
        route: {
          path: '',
          type: 'list',
          feature: ['create', 'drag'],
        },
      },
      {
        name: '编辑',
        identifier: 'edit',
        type: 'entry',
        enable: true,
        entry: {
          type: 'form',
          hide: false,
          extra: 'default',
        },
      },
      {
        name: '删除',
        identifier: 'delete',
        type: 'entry',
        enable: true,
        entry: {
          type: 'delete',
          hide: false,
          extra: 'default',
        },
      },
    ],
    field: [
      {
        name: '名称',
        identifier: 'name',
        type: 'text',
        place: ['list', 'create', 'edit', 'profile'],
        feature: [],
        list: {
          type: 'text',
        },
        form: {
          type: 'text',
        },
        profile: {
          type: 'text',
        },
        search: {
          type: 'text',
        },
      },
      {
        name: '标识符',
        identifier: 'identifier',
        type: 'text',
        place: ['list', 'create', 'edit', 'profile'],
        feature: [],
        list: {
          type: 'text',
        },
        form: {
          type: 'text',
        },
        profile: {
          type: 'text',
        },
        search: {
          type: 'text',
        },
      },
      {
        name: '类型',
        identifier: 'type',
        type: 'select',
        place: ['list', 'create', 'edit', 'profile'],
        feature: [],
        extra: fieldTypes,
        list: {
          type: 'text',
        },
        form: {
          type: 'select',
          default: 'text',
        },
        profile: {
          type: 'text',
        },
        search: {
          type: 'select',
        },
      },
      {
        name: '展示位置',
        identifier: 'place',
        type: 'checkbox',
        place: ['create', 'edit', 'profile'],
        feature: [],
        extra: {
          list: '列表',
          create: '创建',
          edit: '编辑',
          profile: '详情',
        },
        list: {
          type: 'checkbox',
        },
        form: {
          type: 'checkbox',
          default: ['list', 'create', 'edit', 'profile'],
        },
        profile: {
          type: 'checkbox',
        },
        search: {
          type: 'text',
        },
      },
      {
        name: '特性',
        identifier: 'feature',
        type: 'checkbox',
        place: ['list', 'create', 'edit', 'profile'],
        feature: [],
        extra: {
          readonly: '只读',
          sort: '字段排序',
          search: '搜索',
        },
        list: {
          type: 'checkbox',
        },
        form: {
          type: 'checkbox',
          default: ['list'],
        },
        profile: {
          type: 'checkbox',
        },
        search: {
          type: 'text',
        },
      },
      {
        name: '附加信息',
        identifier: 'extra',
        type: 'custom',
        place: ['create', 'edit', 'profile'],
        feature: [],
        list: {
          type: 'text',
        },
        form: {
          type: 'field-form-extra',
        },
        profile: {
          type: 'text',
        },
        search: {
          type: 'text',
        },
      },
      {
        name: '更多',
        identifier: 'more',
        type: 'custom',
        place: ['create', 'edit', 'profile'],
        feature: [],
        list: {
          type: 'text',
        },
        form: {
          type: 'field-form-more',
          standalone: true,
        },
        profile: {
          type: 'text',
        },
        search: {
          type: 'text',
        },
      },
    ],
  },
  {
    parent_id: null,
    name: '导航管理',
    identifier: `${BP_PRESET_PREFIX}/nav`,
    primary_key: 'name',
    level: 'system',
    enable: true,
    action: [
      {
        name: '导航列表',
        identifier: 'list',
        type: 'route',
        enable: true,
        route: {
          path: '',
          type: 'list',
          feature: ['create', 'tree', 'drag'],
          color: {
            '#fbf9ea': 'this.level === "system" || this.path === "role"',
          },
        },
      },
      {
        name: '编辑',
        identifier: 'edit',
        type: 'entry',
        enable: true,
        entry: {
          type: 'form',
          hide: false,
          extra: 'default',
        },
      },
      {
        name: '删除',
        identifier: 'delete',
        type: 'entry',
        enable: true,
        entry: {
          type: 'delete',
          hide: false,
          extra: 'default',
        },
      },
    ],
    field: [
      {
        name: '名称',
        identifier: 'name',
        type: 'text',
        place: ['list', 'create', 'edit', 'profile'],
        feature: [],
        list: {
          type: 'text',
        },
        form: {
          type: 'text',
        },
        profile: {
          type: 'text',
        },
        search: {
          type: 'text',
        },
      },
      {
        name: '图标',
        identifier: 'icon',
        type: 'text',
        place: ['list', 'create', 'edit', 'profile'],
        feature: [],
        list: {
          type: 'text',
        },
        form: {
          type: 'text',
        },
        profile: {
          type: 'text',
        },
        search: {
          type: 'text',
        },
      },
      {
        name: '路径',
        identifier: 'path',
        type: 'text',
        place: ['list', 'create', 'edit', 'profile'],
        feature: [],
        list: {
          type: 'text',
        },
        form: {
          type: 'text',
        },
        profile: {
          type: 'text',
        },
        search: {
          type: 'text',
        },
      },
      {
        name: '主题',
        identifier: 'theme',
        type: 'text',
        place: ['create', 'edit', 'profile'],
        feature: [],
        list: {
          type: 'text',
        },
        form: {
          type: 'text',
          comment: '`outline`、`fill`、`twotone`',
          default: 'outline',
        },
        profile: {
          type: 'text',
        },
        search: {
          type: 'text',
        },
      },
      {
        name: '展示条件',
        identifier: 'display',
        type: 'text',
        place: ['create', 'edit', 'profile'],
        feature: [],
        list: {
          type: 'text',
        },
        form: {
          type: 'text',
          comment: '使用 `this.checkAcl(...)` 来指定acl',
          default: 'this.checkAcl("*")',
        },
        profile: {
          type: 'text',
        },
        search: {
          type: 'text',
        },
      },
    ],
  },
]

export const presetDevNav: RowNav[] = [
  {
    name: '模块管理',
    icon: 'layout',
    path: 'dev/module',
    level: 'system',
  },
  {
    name: '导航管理',
    icon: 'database',
    path: 'dev/nav',
    level: 'system',
  },
]
