import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { ReactiveFormsModule, FormsModule } from '@angular/forms'
import { StoreModule } from '@ngrx/store'
import { EffectsModule } from '@ngrx/effects'
import { BlueprintModule, BlueprintDeclarationModule } from '@bp/core'

import { SharedModule } from '~/shared/shared.module'
import { WIDGETS } from './components'

@NgModule({
  declarations: [WIDGETS],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    BlueprintDeclarationModule,
    BlueprintModule.load({ components: WIDGETS }),
    // OrgsRoutingModule,
    // StoreModule.forFeature('client', reducers),
    // EffectsModule.forFeature([]),
  ],
  providers: [],
})
export class UtilModule {}
