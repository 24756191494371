import {
  RowField,
  FieldType,
  FieldFormType,
  FieldListType,
  FieldProfileType,
  FieldSearchType,
  fieldTypes,
} from '@bp/core'

const fieldTypesObj = { ...fieldTypes }

delete fieldTypesObj.password
delete fieldTypesObj.number
delete fieldTypesObj.color
delete fieldTypesObj.custom
delete fieldTypesObj.template
delete fieldTypesObj.datetime

export const listFields: RowField[] = []

export const searchFields: RowField[] = [
  {
    name: '占位信息',
    identifier: 'placeholder',
    type: 'text',
    place: [],
    feature: [],
    form: {
      type: 'text',
    },
  },
  {
    name: '搜索建议',
    identifier: 'advise',
    type: 'switch',
    place: [],
    feature: [],
    form: {
      type: 'switch',
      comment: '`文本`类型有效',
      default: false,
    },
  },
]

export const formFields: RowField[] = [
  {
    name: '占位信息',
    identifier: 'placeholder',
    type: 'text',
    place: [],
    feature: [],
    form: {
      type: 'text',
    },
  },
  {
    name: '注释',
    identifier: 'comment',
    type: 'text',
    place: [],
    feature: [],
    form: {
      type: 'text',
      comment: '此气泡为示例',
    },
  },
  {
    name: '展示条件',
    identifier: 'display',
    type: 'text',
    place: [],
    feature: [],
    form: {
      type: 'text',
      comment: 'this为该列数据，this.USER为当前用户',
    },
  },
  {
    name: '默认值',
    identifier: 'default',
    type: 'text',
    place: [],
    feature: [],
    form: {
      type: 'text',
    },
  },
  {
    name: '独立组件',
    identifier: 'standalone',
    type: 'switch',
    place: [],
    feature: [],
    form: {
      type: 'switch',
    },
  },
]

export const profileFields: RowField[] = []

export const formTypes = fieldTypesObj
export const listTypes = {
  text: '文本',
  checkbox: '多选框',
  select: '下拉框',
  image: '图片',
  switch: '开关',
  province: '省',
  city: '市',
  district: '区',
  cascader: '级联',
  relation: '关联(单选)',
  relations: '关联(多选)',
  template: '模板',
  tag: '标签',
}
export const searchTypes = {
  text: '文本',
  select: '下拉框',
  relation: '关联',
  date: '日期',
  district: '地区',
}
export const profileTypes = listTypes

export const defaultFieldMap: {
  [props in FieldType]: {
    form: FieldFormType
    list: FieldListType
    profile: FieldProfileType
    search: FieldSearchType
  }
} = {
  text: {
    form: 'text',
    list: 'text',
    profile: 'text',
    search: 'text',
  },
  password: {
    form: 'text',
    list: 'text',
    profile: 'text',
    search: 'text',
  },
  number: {
    form: 'text',
    list: 'text',
    profile: 'text',
    search: 'text',
  },
  textarea: {
    form: 'textarea',
    list: 'text',
    profile: 'text',
    search: 'text',
  },
  color: {
    form: 'text',
    list: 'text',
    profile: 'text',
    search: 'text',
  },
  date: {
    form: 'date',
    list: 'text',
    profile: 'text',
    search: 'date',
  },
  datetime: {
    form: 'date',
    list: 'text',
    profile: 'text',
    search: 'date',
  },
  time: {
    form: 'time',
    list: 'text',
    profile: 'text',
    search: 'text',
  },
  checkbox: {
    form: 'checkbox',
    list: 'checkbox',
    profile: 'checkbox',
    search: 'select',
  },
  select: {
    form: 'select',
    list: 'text',
    profile: 'text',
    search: 'select',
  },
  image: {
    form: 'image',
    list: 'image',
    profile: 'image',
    search: 'text',
  },
  media: {
    form: 'media',
    list: 'media',
    profile: 'media',
    search: 'text',
  },
  file: {
    form: 'file',
    list: 'text',
    profile: 'text',
    search: 'text',
  },
  editor: {
    form: 'editor',
    list: 'text',
    profile: 'text',
    search: 'text',
  },
  switch: {
    form: 'switch',
    list: 'switch',
    profile: 'switch',
    search: 'select',
  },
  province: {
    form: 'district',
    list: 'text',
    profile: 'text',
    search: 'district',
  },
  city: {
    form: 'district',
    list: 'text',
    profile: 'text',
    search: 'district',
  },
  district: {
    form: 'district',
    list: 'text',
    profile: 'text',
    search: 'district',
  },
  relation: {
    form: 'relation',
    list: 'text',
    profile: 'text',
    search: 'relation',
  },
  relations: {
    form: 'relations',
    list: 'tag',
    profile: 'text',
    search: 'relation',
  },
  template: {
    form: 'text',
    list: 'template',
    profile: 'text',
    search: 'text',
  },
  cascader: {
    form: 'cascader',
    list: 'text',
    profile: 'text',
    search: 'text',
  },
  tag: {
    form: 'tag',
    list: 'tag',
    profile: 'tag',
    search: 'text',
  },
  map: {
    form: 'map',
    list: 'text',
    profile: 'text',
    search: 'text',
  },
  custom: {
    form: 'text',
    list: 'text',
    profile: 'text',
    search: 'text',
  },
}
