import { Injectable } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { DataAccessor, Config } from '@bp/http'

import { DevDataAccessor } from './dev_data_accessor'
import { RouteData, Module, RowField } from './config'

@Injectable({ providedIn: 'root' })
export class DataAccessorService {
  bpConfig: RouteData
  bpModule: Module
  moduleIdentifiers: string[] = []

  accessor: DataAccessor

  private _route: ActivatedRoute
  private _baseURL = ''
  private _parentids: string[] = []

  constructor(
    private _devDataAccessor: DevDataAccessor,
    private _dataAccessor: DataAccessor,
  ) {}

  init(inject: {
    baseURL?: string
    route: ActivatedRoute
    fields?: RowField[]
  }) {
    // Get ActivatedRoute in this service will be delay info, so we inject route manual
    this._route = inject.route
    this._baseURL = inject.baseURL || ''
    this._parentids = this._getParentIds()
    this.bpConfig = this._route.snapshot.data as RouteData
    this.bpModule = this.bpConfig.module
    this.moduleIdentifiers = this._getIdentifiers()

    this.accessor =
      this.bpModule.level === 'system'
        ? this._devDataAccessor
        : this._dataAccessor

    let config: Config = {
      baseURL: this._createURL(),
      module: this.bpModule,
      parentIds: this._parentids,
    }

    if (inject.fields) {
      config = {
        ...config,
        fields: inject.fields,
      }
    }

    this.accessor.init(config)
  }

  /**
   * Inited url: ${baseURL} + ?${parentModule{identifier,id}} + ${currentModule.identifier}
   */
  private _createURL(): string {
    let url = this._baseURL
    let parent = this.bpModule.parent

    if (parent) {
      const parentIds = this._getParentIds()

      // Set parent identifier
      while (parent) {
        // Set parent id
        const parentId = parentIds.pop()
        if (parentId) {
          url = `${parent.identifier}/${parentId}/${url}`
        } else {
          url = `${parent.identifier}/${url}`
        } 

        parent = parent.parent
      }
    }

    if (url[url.length - 1] === '/') url = url.slice(0, url.length - 1)

    // Set current module identifier
    url = `${url}/${this.bpModule.identifier}`

    // Remove `/` at first and last
    if (url[url.length - 1] === '/') url = url.slice(0, url.length - 1)
    if (url[0] === '/') url = url.slice(1)

    return url
  }

  private _getParentIds(): string[] {
    return this._route.snapshot.url
      .filter(seg => seg.parameters && seg.parameters.id)
      .map(seg => seg.parameters.id)
  }

  private _getIdentifiers(): string[] {
    let module = this.bpModule
    const ids = []

    while (module) {
      ids.unshift(module.identifier)
      module = module.parent
    }

    return ids
  }
}
