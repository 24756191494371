import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private cache: any

  constructor() {}

  set(config: any) {
    this.cache = config
  }

  get(): any {
    return this.cache || {}
  }
}
