import { InjectionToken } from '@angular/core'

import { RowModule, RowNav } from './model'

export const BP_PRESET_PROD_MODULE = new InjectionToken<RowModule>(
  'Preset Prod Module',
)
export const BP_PRESET_PROD_NAV = new InjectionToken<RowNav>('Preset Prod Nav')

export const presetProdModule: RowModule[] = [
  {
    parent_id: null,
    name: '角色管理',
    identifier: 'role',
    primary_key: 'id',
    level: 'user',
    enable: true,
    exclude_acl: true,
    _preset: true,
    action: [
      {
        name: '角色列表',
        identifier: 'list',
        type: 'route',
        enable: true,
        route: {
          path: '',
          type: 'list',
          feature: ['create'],
        },
      },
      {
        name: '编辑',
        identifier: 'edit',
        type: 'entry',
        enable: true,
        entry: {
          type: 'form',
          hide: false,
          extra: 'default',
        },
      },
      {
        name: '删除',
        identifier: 'delete',
        type: 'entry',
        enable: true,
        entry: {
          type: 'delete',
          hide: false,
          extra: 'default',
        },
      },
    ],
    field: [
      {
        name: 'ID',
        identifier: 'id',
        type: 'text',
        place: [],
        feature: ['readonly'],
        list: {
          type: 'text',
        },
        form: {
          type: 'text',
        },
        profile: {
          type: 'text',
        },
        search: {
          type: 'text',
        },
      },
      {
        name: '角色名',
        identifier: 'name',
        type: 'text',
        place: ['list', 'create', 'edit', 'profile'],
        feature: [],
        list: {
          type: 'text',
        },
        form: {
          type: 'text',
        },
        profile: {
          type: 'text',
        },
        search: {
          type: 'text',
        },
      },
      {
        name: '权限',
        identifier: 'acls',
        type: 'custom',
        place: ['create', 'edit'],
        feature: [],
        form: {
          type: 'role-manage',
        },
      },
    ],
  },
]

export const presetProdNav: RowNav[] = [
  // {
  //   name: '角色管理',
  //   icon: 'lock',
  //   path: 'role',
  //   level: 'user',
  //   display: 'this.checkAcl("role.*")',
  //   _preset: true,
  // },
]
