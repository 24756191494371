import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { Apollo } from 'apollo-angular'
import gql from 'graphql-tag'
import pluralize from 'pluralize'
import { FetchPolicy } from 'apollo-client'

import {
  DataAccessor,
  ListData,
  RelationData,
  RelateInfo,
} from './data_accessor_interface'
import { ActivatedRoute } from '@angular/router'

export type IDType = 'Int' | 'String'

export type Options = {
  fetchPolicy: FetchPolicy
}

@Injectable()
export class GraphqlDataAccessor extends DataAccessor {
  private _query: string

  constructor(private _apollo: Apollo, private _aroute: ActivatedRoute) {
    super(_aroute)
  }

  getOne(): Observable<any> {
    return null
  }

  getOneById(id: number | string, options?: Options): Observable<any> {
    const { fetchPolicy } = options || {
      fetchPolicy: null,
    }
    const pk = this.config.module.primary_key
    const entity = this.config.module.identifier
    const types = `$${pk}: ${this._getPkType(id)}`
    const params = `${pk}: $${pk}`
    const query = this._getQuery()

    let currentQL = `${entity}(${params}) { ${query} }`

    currentQL = this._parentWrap(currentQL)

    return this._apollo
      .query<any>({
        query: gql`
        query Blueprint(${types}) {
            ${currentQL}
        }
      `,
        fetchPolicy: fetchPolicy || 'no-cache',
        variables: {
          [pk]: id,
        },
      })
      .pipe(map(res => res.data[entity]))
  }

  getList(params: any, options?: Options): Observable<ListData<any>> {
    const { fetchPolicy } = options || {
      fetchPolicy: null,
    }
    let paramStr = ''
    const query = this._getQuery()
    const entity = pluralize.plural(this.config.module.identifier)
    const meta: {
      index: number
      size: number
    } = params._meta

    // params
    delete params._meta
    if (meta) {
      paramStr = `page: ${meta.index}, limit: ${meta.size},`
    }
    paramStr = Object.entries(params).reduce((prev, [key, value]) => {
      return `${prev} ${key}: ${value},`
    }, paramStr)

    if (paramStr) paramStr = `(${paramStr})`

    let currentQL = `
      query Blueprint {
        ${entity}${paramStr} {
          data { ${query} }
          ${meta ? 'total' : ''}
        }
      }
  `

    currentQL = this._parentWrap(currentQL)

    return this._apollo
      .query<any>({
        query: gql`
          ${currentQL}
        `,
        fetchPolicy: fetchPolicy || 'no-cache',
      })
      .pipe(
        map(res => {
          const e = res.data[entity]

          return {
            data: e.data,
            meta: {
              count: 0,
              limit: 0,
              offset: 0,
              total: e.total,
            },
          }
        }),
      )
  }

  create(data: any): Observable<any> {
    const pk = this.config.module.primary_key
    let types = ''
    let params = ''
    const identifier = this.config.module.identifier
    const upcase = this._upcaseFirstWord(identifier)

    Object.entries(data).forEach(([k, v]) => {
      types = `${types} $${k}: ${this._getType(v, k)},`
      params = `${params} ${k}: $${k}`
    })

    return this._apollo.mutate({
      mutation: gql`
        mutation Blueprint(${types}) {
          create${upcase}(${params}) { ${pk} }
        }
      `,
      variables: data,
    })
  }

  update(data: any): Observable<any> {
    return null
  }

  updateById(id: number | string, data: any): Observable<any> {
    const pk = this.config.module.primary_key
    let types = `$${pk}: ${this._getPkType(id)},`
    let params = `${pk}: $${pk},`
    const identifier = this.config.module.identifier
    const upcase = this._upcaseFirstWord(identifier)

    Object.entries(data)
      .filter(([k]) => k !== pk)
      .forEach(([k, v]) => {
        types = `${types} $${k}: ${this._getType(v, k)},`
        params = `${params} ${k}: $${k},`
      })

    return this._apollo.mutate({
      mutation: gql`
        mutation Blueprint(${types}) {
          update${upcase}(${params}) { ${pk} }
        }
      `,
      variables: { ...data, [pk]: id },
    })
  }

  delete(): Observable<any> {
    return null
  }

  deleteById(id: number | string): Observable<any> {
    const pk = this.config.module.primary_key
    const types = `$${pk}: ${this._getPkType(id)}`
    const params = `${pk}: $${pk}`
    const identifier = this.config.module.identifier
    const upcase = this._upcaseFirstWord(identifier)

    return this._apollo.mutate({
      mutation: gql`
        mutation Blueprint(${types}) {
          delete${upcase}(${params})
        }
      `,
      variables: { [pk]: id },
    })
  }

  batch(
    action: string,
    keys: number[] | string[],
    params: any,
  ): Observable<any> {
    return null
  }

  sequence(ids: RelationData[]): Observable<any> {
    return null
  }

  relate(data: RelateInfo, options?: Options): Observable<any> {
    const { fetchPolicy } = options || {
      fetchPolicy: null,
    }
    const related_id = this._getLatestSegmentId()

    return this._apollo.query({
      query: gql`
        query Blueprint($type: String, $keyword: String, $field: String, $limit: Int, $related_id: Int) {
          data(type: $type, keyword: $keyword, field: $field, limit: $limit, related_id: $related_id) {}
        }
      `,
      fetchPolicy: fetchPolicy || 'no-cache',
      variables: {
        ...data,
        related_id,
      },
    })
  }

  private _parentWrap(currentQL: string): string {
    let parent = this.config.module.parent

    while (parent) {
      currentQL = `${parent.identifier} { ${currentQL} }`

      parent = parent.parent
    }

    return currentQL
  }

  private _getQuery(): string {
    if (this._query) return this._query

    const customField = this.config.module.custom_field || []
    const queryArr = this.config.fields.map(f => f.identifier)

    queryArr.push(...customField)

    return (this._query = queryArr.join(','))
  }

  private _upcaseFirstWord(str: string): string {
    return str[0].toUpperCase() + str.slice(1)
  }

  private _getType(data: any, key: string): Type {
    switch (typeof data) {
      case 'string':
        return 'String'
      case 'number':
        return 'Float'
      case 'boolean':
        return 'Boolean'
      default:
        // singular the key, example: acls -> acl -> Acl
        const singular = pluralize.singular(key)
        const upcase = this._upcaseFirstWord(singular)

        if (pluralize.isPlural(key)) {
          return `[${upcase}]`
        } else {
          return upcase
        }
    }
  }

  private _getPkType(str: string | number): 'String' | 'Int' {
    return typeof str === 'string' ? 'String' : 'Int'
  }
}

export type Type = 'String' | 'Float' | 'Boolean' | string
