import { Component, Input, OnInit, EventEmitter } from '@angular/core'
import { FormGroup } from '@angular/forms'
import { WidgetComponent, WidgetState, RowField } from '@bp/core'
import { LanguageService } from '~/core/services'

@Component({
  selector: 'app-translation',
  templateUrl: './translation.component.html',
  styleUrls: ['./translation.component.styl'],
})

export class TranslationComponent extends WidgetComponent implements OnInit {
  static meta = {
    name: 'app-translation',
  }

  @Input() entity: any
  @Input() value: object
  @Input() field: RowField
  @Input() formGroup: FormGroup
  @Input() state: WidgetState

  showModal = false
  languages = []
  translations = {}
  change = new EventEmitter<object>()

  constructor(
    private languageService: LanguageService
  ) {
    super()
  }

  ngOnInit() {
    console.log(this.entity)
    this.languages = this.languageService.get()
    if (!this.entity[this.field.identifier]) return

    this.languages.forEach((language) => {
      if (this.entity[this.field.identifier] !== undefined) {
        if (this.entity[this.field.identifier][language.code] === undefined) {
          this.entity[this.field.identifier][language.code] = ''
          this.translations[language.code] = ''
        } else {
          this.translations[language.code] = this.entity[this.field.identifier][language.code]
        }
      }
    })
  }

  valueChange(e: any) {
    // if (!e) return
    this.change.emit(this.translations)
  }
}
