import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'imageCrop'
})
export class ImageCropPipe implements PipeTransform {
  transform(url: string, params: { height: number, width: number }) {
    return `${url}?imageMogr2/crop/${params.width}x${params.height}`
  }
}
