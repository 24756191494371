import {
  Component,
  Input,
  OnInit,
  EventEmitter,
  OnDestroy,
} from '@angular/core'
import { Subject, Subscription } from 'rxjs'

import { WidgetComponent } from '../../view'
import { RowField } from '../../config'
import { debounceTime, tap } from 'rxjs/operators'

export type MapArray = [string, string][]

/**
 * can use for: map
 */
@Component({
  selector: 'bp--preset--form-map-widget',
  template: `
    <div class="input-group" *ngFor="let item of data; let i = index">
      <input
        nz-input
        [disabled]="readonly"
        [(ngModel)]="item[0]"
        (ngModelChange)="debounceChange$.next()"
        [placeholder]="placeholder.key"
      />
      <input
        nz-input
        [disabled]="readonly"
        [(ngModel)]="item[1]"
        (ngModelChange)="debounceChange$.next()"
        [placeholder]="placeholder.value"
      />
      <i
        nz-icon
        nzType="minus-circle"
        nzTheme="outline"
        *ngIf="data.length > 1 && !readonly"
        (click)="delInput(i)"
      ></i>
    </div>
    <div class="input-group input-plus" *ngIf="!readonly" (click)="addInput()">
      <input nz-input [placeholder]="'新增' + placeholder.key" disabled />
      <input nz-input [placeholder]="'新增' + placeholder.value" disabled />
      <i nz-icon nzType="plus-circle" nzTheme="outline"></i>
      <div class="input-plus-shade"></div>
    </div>
  `,
  styleUrls: ['./form_map.styl'],
})
export class BpFormMapWidget extends WidgetComponent
  implements OnInit, OnDestroy {
  static meta = {
    type: 'preset_form',
    name: 'map',
  }

  @Input() entity: any
  @Input() value: object
  @Input() field: RowField

  change = new EventEmitter<object>()
  readonly = false
  data: MapArray = []
  placeholder = {
    key: '数据值',
    value: '显示值',
  }

  debounceChange$ = new Subject()

  private _subs = new Subscription()

  ngOnInit() {
    this.readonly = this.field.feature.includes('readonly')
    if (this.field.extra && this.field.extra.placeholder) {
      this.placeholder = this.field.extra.placeholder
    }

    const arr = this._objectToArray(this.value)
    this.data = !arr || !arr.length ? [['', '']] : arr

    this._subs.add(
      this.debounceChange$
        .pipe(
          debounceTime(200),
          tap(() => this.change.emit(this._arrayToObject(this.data))),
        )
        .subscribe(),
    )
  }

  ngOnDestroy() {
    this._subs.unsubscribe()
  }

  addInput() {
    if (this.readonly) return
    this.data.push(['', ''])
  }

  delInput(i) {
    if (this.readonly) return

    const data = this.data.splice(i, 1)[0]

    if (data[0]) this.change.emit(this._arrayToObject(this.data))
  }

  private _objectToArray(obj: object): MapArray {
    if (!obj) return []

    return Object.entries(obj).reduce(
      (prev, [key, val]) => [...prev, [key, val]],
      [],
    )
  }

  private _arrayToObject(arr: MapArray): object {
    if (!arr || !arr.length) return null

    return arr.reduce(
      (prev, [key, val]) => (!key ? prev : { ...prev, [key]: val }),
      {},
    )
  }
}
