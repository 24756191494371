import {
  Component,
  HostBinding,
  OnInit,
  OnDestroy,
  TemplateRef,
  EventEmitter,
  ViewChild,
} from '@angular/core'
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout'
import { ActivatedRoute, Router, Params } from '@angular/router'
import { FormGroup, FormControl, FormBuilder } from '@angular/forms'
import { Observable, Subscription } from 'rxjs'
import { map, take, tap, finalize } from 'rxjs/operators'
import { NzModalService, NzModalRef, NzMessageService } from 'ng-zorro-antd'
import { Store, select } from '@ngrx/store'
import { RelationData } from '@bp/http'

import { RouteData, ActionFeature } from '../../../config/model'
import { Payload } from '../../../interface'
import * as fromStore from '../../../store'
import * as ListActions from '../../../store/list_actions'
import { BpFormPage } from '../form/form_page'
import { ColorConfig } from '../pipes/list_color_pipe'
import { DataAccessorService } from '../../../init_accessor'
import { AclService } from '../../../acl'

function getDefaultPageParams() {
  return {
    index: 1,
    size: 20,
  }
}

export type TreeNodeInterface = {
  _level: number
  _expand: boolean
  _parent?: TreeNodeInterface
  _children?: TreeNodeInterface[]
}

@Component({
  selector: 'bp-list-page',
  templateUrl: './list_page.html',
  styleUrls: ['./list_page.styl'],
})
export class BpListPage implements OnInit, OnDestroy {
  @HostBinding('class.is-handset')
  hostHandset = false
  @ViewChild('formTpl', { static: false })
  private formTpl: TemplateRef<BpFormPage>

  loading = false
  isFullscreen = false
  webActionWidth = ''
  pageParams = {
    ...getDefaultPageParams(),
    total: 0,
  }
  // edit id
  formId: number | string = null
  // create parent id
  parentId: number | string = null

  // bp
  bpConfig = this._route.snapshot.data as RouteData
  bpModule = this.bpConfig.module
  bpField = this.bpModule.field || []
  bpRouteAction = this.bpConfig.actionRoute
  bpBatchActions = this.bpConfig.actionBatches
  bpEntryActions = this.bpConfig.actionEntries
  bpAclActions = this.bpConfig.actionAcls
  bpPrimaryKey = this.bpModule.primary_key || 'id'
  visibleEntryActions = this.bpEntryActions.filter(e => !e.entry.hide)
  hideEntryActions = this.bpEntryActions.filter(e => e.entry.hide)
  features = {
    create: false,
    drag: false,
    pagination: false,
    tree: false,
  }
  listField = this.bpField.filter(f => f.place.includes('list'))
  colorConfs: ColorConfig[]
  aclPrefix = this._aclService.getModuleAcl(this.bpModule).split('.')
  acls = {
    create: [...this.aclPrefix, 'store'],
    delete: [...this.aclPrefix, 'destroy'],
    update: [...this.aclPrefix, 'update'],
    batch: [...this.aclPrefix, 'batch'],
  }

  // sort
  sortParams: {
    [props: string]: 'descend' | 'ascend'
  } = {}

  // search
  searchField = this.bpField.filter(f => f.feature.includes('search'))
  searchForm = new FormGroup({})
  mapStoreData = []
  mapStoreDataWithoutChild = []

  // batch
  mapOfCheckedId = {}
  isAllDisplayDataChecked = false
  isIndeterminate = false
  numberOfChecked = 0

  // delete
  showStringDelete = false
  stringDeleteItem = null
  stringDeleteInput = ''

  isHandset$: Observable<boolean>
  storeData$: Observable<any[]>

  private _requiredParamKeys = ['index', 'size']
  private _subs: Subscription = new Subscription()
  private _actionModalRef: NzModalRef = null
  private _formModalRef: NzModalRef = null
  private _afterCloseModal = new EventEmitter()

  constructor(
    private _breakpointObserver: BreakpointObserver,
    private _route: ActivatedRoute,
    private _router: Router,
    private _store: Store<fromStore.State>,
    private _dataAccessorService: DataAccessorService,
    private _modalService: NzModalService,
    private _msgService: NzMessageService,
    private _aclService: AclService,
    private _fb: FormBuilder,
  ) {
    this._initActionFeature()
    this._initActionWidth()

    // init breakpoint
    this.isHandset$ = _breakpointObserver
      .observe(Breakpoints.Handset)
      .pipe(map(({ matches }) => matches))

    // init fullscreen
    this._subs.add(
      this._afterCloseModal.subscribe(() => {
        this.formId = null
        this.isFullscreen = false
      }),
    )
    this._initSearch()
    this._initStoreData()

    _dataAccessorService.init({
      route: _route,
      fields: this.bpField,
    })

    if (this.features.pagination) {
      // Fetch data havn't required query data
      this._initQuery()
    } else {
      this._fetchData()
    }
  }

  ngOnInit() {
    this._subs.add(this.isHandset$.subscribe(bool => (this.hostHandset = bool)))
    this._initColor()
  }

  ngOnDestroy() {
    this._actionModalRef && this._actionModalRef.destroy()
    this._formModalRef && this._formModalRef.destroy()
    this._subs.unsubscribe()
  }

  dragConfirm(data: RelationData[]) {
    this._dataAccessorService.accessor
      .sequence(data)
      .pipe(
        tap(() => {
          this._msgService.success('排序成功!')
          this._fetchData()
        }),
      )
      .subscribe()
  }

  closeTree(data: TreeNodeInterface) {
    if (data._expand || !data._children || !data._children.length) return

    data._children.forEach(d => {
      d._expand = false
      this.closeTree(d)
    })
  }

  // fullscreen
  toggleFullscreen() {
    if (!this._formModalRef) return

    const modalElement = this._formModalRef.getInstance().modalContainer
      .nativeElement

    if (this.isFullscreen) {
      modalElement.setAttribute('class', 'ant-modal')
    } else {
      modalElement.setAttribute('class', 'ant-modal-fullscreen')
    }

    this.isFullscreen = !this.isFullscreen
  }

  openStringDelete(item: any) {
    this.stringDeleteItem = item
    this.showStringDelete = true
  }

  stringDelete() {
    const pv = this.stringDeleteItem[this.bpPrimaryKey]
    const userInput = this.stringDeleteInput

    if (pv !== userInput) {
      return
    }

    this.dispatch({ type: 'delete', data: this.stringDeleteItem })
    this.showStringDelete = false
    this.stringDeleteInput = ''
  }

  // handset
  openActions(content: TemplateRef<any>) {
    this._actionModalRef = this._modalService.create({
      nzFooter: null,
      nzClosable: false,
      nzContent: content,
      nzClassName: 'bp-list-page-handset-action-btn',
    })
  }

  showDeleteConfirm(data: any): void {
    this._modalService.confirm({
      nzTitle: '确定删除此项？',
      nzContent: '删除后，相关对象将无法恢复',
      nzOkText: '是',
      nzOkType: 'danger',
      nzOnOk: () => {
        this.handsetDispatch({ type: 'delete', data: data })
      },
      nzCancelText: '否',
      nzOnCancel: () => {},
    })
  }

  dispatch(payload: Payload) {
    switch (payload.type) {
      case 'search':
        // TODO: optimize
        // Search payload: { key: xxx, value: xxx }
        if (payload.data) {
          const { key, value } = payload.data
          const c = this.searchForm.get(key)

          if (c) {
            c.patchValue(value)
          } else {
            this.searchForm.addControl(key, this._fb.control(value))
          }
        }
        return this.searchSubmit()
      case 'delete':
        return this._delete(payload.data[this.bpPrimaryKey])
      case 'create':
        return this._create(payload)
      case 'batch':
        return this._batch(payload)
      default:
        this._fetchData()
    }
  }

  private _delete(id) {
    this._dataAccessorService.accessor
      .deleteById(id)
      .pipe(
        tap(() => {
          this._msgService.success('删除成功!')
          this._fetchData()
        }),
      )
      .subscribe()
  }

  private _create(payload: Payload) {
    this._dataAccessorService.accessor
      .create(payload.data)
      .pipe(
        tap(() => {
          this.afterFormConfirm()
        }),
      )
      .subscribe()
  }

  private _batch(payload: Payload) {
    const keys = Object.entries(this.mapOfCheckedId)
      .filter(([k, v]) => v)
      .map(([k]) => k)

    this._dataAccessorService.accessor
      .batch(payload.batchType, keys, payload.data)
      .pipe(
        tap(res => {
          this._actionModalRef && this._actionModalRef.close()
          this._msgService.success('批量操作成功!')
          this._fetchData()

          payload.callback && payload.callback(res)
        }),
      )
      .subscribe()
  }

  handsetDispatch(payload: Payload) {
    this._actionModalRef && this._actionModalRef.close()
    this.dispatch(payload)
  }

  sort(e: { key: string; value: 'descend' | 'ascend' | null }) {
    if (!e.value) {
      delete this.sortParams[e.key]
    } else {
      this.sortParams[e.key] = e.value
    }
    this._resetPagination()
    this._fetchData()
  }

  // batch
  checkAll(value: boolean) {
    this.storeData$.pipe(take(1)).subscribe(data => {
      data.forEach(
        item => (this.mapOfCheckedId[item[this.bpPrimaryKey]] = value),
      )
      this.refreshBatchStatus()
    })
  }

  refreshBatchStatus() {
    this.storeData$.pipe(take(1)).subscribe(data => {
      this.isAllDisplayDataChecked = data.every(
        item => this.mapOfCheckedId[item[this.bpPrimaryKey]],
      )
      this.isIndeterminate =
        data.some(item => this.mapOfCheckedId[item[this.bpPrimaryKey]]) &&
        !this.isAllDisplayDataChecked
      this.numberOfChecked = data.filter(
        item => this.mapOfCheckedId[item[this.bpPrimaryKey]],
      ).length
    })
  }

  private _clearChecked() {
    this.mapOfCheckedId = {}
    this.isAllDisplayDataChecked = false
    this.isIndeterminate = false
    this.numberOfChecked = 0
  }

  // search
  searchSubmit() {
    const searchValues = Object.entries(this.searchForm.value)
      .filter(([k, v]) => v !== null && v !== '')
      .reduce((prev, [k, v]) => ({ ...prev, [k]: v }), {})

    this._resetPagination()
    this._updateQuery(searchValues)
    this._fetchData()
  }

  searchReset() {
    this.searchForm.reset()
    this._resetPagination()
    this._fetchData()
  }

  // table
  pageIndexChange(index: number) {
    if (!(index > 0)) return

    this.pageParams.index = index
    this._updateQuery({ index })
    this._fetchData()
  }

  pageSizeChange(size: number) {
    this.pageParams.size = size
    this._updateQuery({ size })
    this._fetchData()
  }

  // form
  create(parentId?: string | number) {
    this.parentId = parentId
    this._createForm({ title: '新增' })
  }

  edit(id: number | string) {
    this._actionModalRef && this._actionModalRef.close()
    this.formId = id
    this._createForm({ title: '编辑' })
  }

  afterFormConfirm() {
    if (this._formModalRef) this._formModalRef.close()

    if (this.formId) {
      // edit mode
      this.formId = null
    } else {
      // create mode
      this.parentId = null
      this._resetPagination()
    }
    this._fetchData()
  }

  private _resetPagination() {
    const defaultPageParams = getDefaultPageParams()

    this.pageParams = {
      ...this.pageParams,
      ...defaultPageParams,
    }
    this._updateQuery(defaultPageParams)
  }

  private async _fetchData() {
    let params: any = {
      ...this.searchForm.value,
    }

    // Sort
    if (Object.keys(this.sortParams).length) {
      const pKv = Object.entries(this.sortParams)

      params = {
        ...params,
        sort: pKv.reduce((prev, [key, val], index) => {
          return `${prev}${val === 'ascend' ? key : '-' + key}${
            pKv.length - 1 === index ? '' : ','
          }`
        }, ''),
      }
    }

    // Filter empty data
    params = Object.entries(params)
      .filter(([key, val]) => {
        return val !== '' && val !== null
      })
      .reduce((prev, [key, val]) => ({ ...prev, [key]: val }), {})

    this.loading = true
    this._clearChecked()

    if (this.features.pagination) {
      // 如果初始没有index 或者 size 数据,填入默认
      if (!this.pageParams.index || !this.pageParams.size ) {
        this._resetPagination();
      }
      params = {
        ...params,
        _meta: {
          ...this.pageParams,
        },
      }
    }
    console.log('_fetchData params', params, '...this.pageParams', this.pageParams)
    this._dataAccessorService.accessor
      .getList(params)
      .pipe(
        take(1),
        tap(res => {
          this._loadData(res.data)
          console.log('res.meta', res.meta)
          if (this.features.pagination) this.pageParams.total = res.meta.total
        }),
        finalize(() => (this.loading = false)),
      )
      .subscribe()
  }

  private _initActionWidth() {
    const actionWidth = 50 * this.visibleEntryActions.length
    this.webActionWidth = `${
      (this.hideEntryActions.length || this.features.tree
        ? actionWidth + 65
        : actionWidth) + 40
    }px`
  }

  private _initActionFeature() {
    const features: ActionFeature[] = ['create', 'drag', 'pagination', 'tree']

    features.forEach(feature => {
      this.features[feature] = this.bpRouteAction.route.feature.includes(
        feature,
      )
    })
  }

  private _initSearch() {
    const searchForm = this.searchForm

    this.searchField.forEach(field => {
      searchForm.addControl(
        field.identifier,
        new FormControl(field.type === 'relation' ? null : ''),
      )
    })
  }

  private _initStoreData() {
    this._loadData([])
    this.storeData$ = this._store.pipe(
      select(fromStore.selectAllData(this.bpModule.identifier)),
      tap(data => {
        /** Clone list for display data */
        const cloneData = data.map(d => ({ ...d }))

        this.mapStoreData = cloneData.reduce((prev, current) => {
          return [
            ...prev,
            this._convertTreeLevel(
              current,
              cloneData.filter(d => d !== current),
            ),
          ]
        }, [])
        this.mapStoreDataWithoutChild = this.mapStoreData.filter(
          d => !d.parent_id,
        )
      }),
    )
  }

  // _level: node._level + 1,
  // _expand: false,
  // _parent: node,
  // _haveChildren: boolean,
  private _convertTreeLevel(current: any, list: any[]): TreeNodeInterface {
    let parent = null
    let meta = {
      _level: 0,
      _expand: false,
      _parent: null,
    }

    if (current.parent_id)
      parent = list.find(d => d[this.bpPrimaryKey] === current.parent_id)

    if (parent)
      meta = {
        _level: parent._level + 1,
        _expand: false,
        _parent: parent,
      }

    Object.assign(current, meta)

    const children = list.filter(
      d => d.parent_id === current[this.bpPrimaryKey],
    )

    if (children) Object.assign(current, { _children: children })

    return current
  }

  private _loadData(data: any[]) {
    if (data.length > 20) {
      this._loadLargeData(data)
    } else {
      this._store.dispatch(
        new ListActions.LoadAll(
          this.bpModule.primary_key,
          this.bpModule.identifier,
          { data },
        ),
      )
    }
  }

  private _loadLargeData(data: any[]) {
    const i = 20

    this._store.dispatch(
      new ListActions.LoadAll(
        this.bpModule.primary_key,
        this.bpModule.identifier,
        { data: data.slice(0, i) },
      ),
    )

    this._asyncRenderData(data, i)
  }

  private _asyncRenderData(data: any[], index: number) {
    const increase = 20
    const nextIndex = index + increase

    setTimeout(() => {
      this._store.dispatch(
        new ListActions.AddMany(this.bpModule.identifier, {
          data: data.slice(index, nextIndex),
        }),
      )

      if (data.length >= nextIndex) this._asyncRenderData(data, nextIndex)
    }, 0)
  }

  private _initQuery() {
    console.log('_initQuery');
    const { queryParams } = this._route.snapshot
    // Fetch data have required query data
    // const haveRequiredKeys = this._requiredParamKeys.every(
    //   key => !!queryParams[key],
    // )
    const haveRequiredKeys = true;
    // Update required query and fetch data when there's lack of required query data.
    this._subs.add(
      this._route.queryParams.subscribe(params => {
        if (!haveRequiredKeys) {
          console.log('getDefaultPageParams(),', getDefaultPageParams())
          this._updateQuery(getDefaultPageParams())
          this._fetchData()
        }
      }),
    )

    if (haveRequiredKeys) {
      this.pageParams = {
        ...this.pageParams,
        index: queryParams.index,
        size: queryParams.size,
      }

      // TODO: optimize
      // Search field
      Object.entries(queryParams)
        .filter(([k]) => k !== 'size' && k !== 'index')
        .forEach(([k, v]) => {
          const c = this.searchForm.get(k)

          if (c) {
            c.patchValue(v)
          } else {
            this.searchForm.addControl(k, this._fb.control(v))
          }
        })

      this._fetchData()
    }
  }

  private _updateQuery(queryParams: Params) {
    console.log('updateQuery', queryParams, 'this.pageParams', this.pageParams)
    this._router.navigate([], {
      relativeTo: this._route,
      queryParams: {
        size: this.pageParams.size,
        index: this.pageParams.index,
        ...queryParams,
      },
      replaceUrl: true,
    })
  }

  private _createForm({ title }: FormConfig) {
    this._formModalRef = this._modalService.create({
      nzTitle: title,
      nzFooter: null,
      nzContent: this.formTpl,
      nzBodyStyle: { padding: 0 },
      nzAfterClose: this._afterCloseModal,
    })
  }

  private _initColor() {
    if (!this.bpRouteAction.route.color) return

    const colorConfs = Object.entries(this.bpRouteAction.route.color).reduce(
      (prev, [value, condition]) => [...prev, { value, condition }],
      [],
    )
    if (!colorConfs || !colorConfs.length) return
    const defaultFn = function() {
      return false
    }

    this.colorConfs = colorConfs.map(color => {
      let fn: Function

      try {
        fn = new Function(`return ${color.condition}`)
      } catch (e) {
        fn = defaultFn
      }

      return {
        ...color,
        fn,
      }
    })
  }
}

type FormConfig = {
  title: string
}
