import {
  Component,
  Input,
  ChangeDetectionStrategy,
  OnInit,
  OnChanges,
  ChangeDetectorRef,
  SimpleChanges,
} from '@angular/core'

import { WidgetComponent } from '../../view'
import { RowField } from '../../config'
import { isNone } from '../../util/is_none'

/**
 * can use for: template
 */
@Component({
  selector: 'bp--preset--template-widget',
  template: `
    <span [innerHTML]="templateStr | template: entity | safeHTML"></span>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BpGeneralTemplateWidget extends WidgetComponent implements OnInit {
  static meta = {
    type: 'preset_general',
    name: 'template',
  }

  @Input() entity: any
  @Input() value: string
  @Input() field: RowField

  nodata = false
  templateStr: string

  constructor() {
    super()
  }

  ngOnInit() {
    this.templateStr = this.field.list.template
  }
}
