import {
  Component,
  Input,
  OnInit,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from '@angular/core'
import { format } from 'date-fns'

import { WidgetComponent } from '../../view'
import { RowField } from '../../config'

/**
 * can use for: time
 */
@Component({
  selector: 'bp--preset--form-time-widget',
  template: `
    <nz-time-picker
      [nzDisabled]="readonly"
      [ngModel]="time"
      (ngModelChange)="onChange($event)"
    ></nz-time-picker>
  `,
})
export class BpFormTimeWidget extends WidgetComponent
  implements OnInit, OnChanges {
  static meta = {
    type: 'preset_form',
    name: 'time',
  }

  @Input() entity: any
  @Input() value: string
  @Input() field: RowField

  change = new EventEmitter<string>()
  readonly = false
  time = null

  ngOnChanges(changes: SimpleChanges) {
    if (changes.value) {
      const currentValue = changes.value.currentValue

      if (currentValue) {
        this.time = this._timeTransform(currentValue)
      } else {
        this.time = null
      }
    }
  }

  ngOnInit() {
    this.readonly = this.field.feature.includes('readonly')
  }

  onChange(time: Date) {
    this.change.emit(time ? format(time, 'HH:mm:ss') : null)
  }

  private _timeTransform(val: string) {
    return val && val.replace(' ', 'T')
  }
}
