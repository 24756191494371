import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import {
  // NzAffixModule,
  // NzAlertModule,
  // NzAnchorModule,
  // NzAutocompleteModule,
  // NzBackTopModule,
  // NzBadgeModule,
  // NzBreadCrumbModule,
  NzAvatarModule,
  // NzCascaderModule,
  // NzCalendarModule,
  // NzCardModule,
  // NzCarouselModule,
  // NzCollapseModule,
  // NzCommentModule,
  // NzDrawerModule,
  NzDropDownModule,
  // NzEmptyModule,
  // NzGridModule,
  // NzLayoutModule,
  // NzListModule,
  // NzMentionModule,
  // NzPageHeaderModule,
  // NzProgressModule,
  // NzRateModule,
  // NzSkeletonModule,
  // NzSliderModule,
  // NzStatisticModule,
  // NzStepsModule,
  // NzTimelineModule,
  // NzTransferModule,
  // NzTreeSelectModule,
  // NzUploadModule,
} from 'ng-zorro-antd'
import { CommonModule as BpCommonModule } from '@bp/common'

import { COMPONENTS } from './components'

const ANTD_MODULES = [NzAvatarModule, NzDropDownModule]

@NgModule({
  declarations: [COMPONENTS],
  imports: [BpCommonModule, ANTD_MODULES, CommonModule],
  providers: [],
  exports: [BpCommonModule, ANTD_MODULES],
})
export class SharedModule {}
