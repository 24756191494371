import {
  Directive,
  Input,
  DoCheck,
  KeyValueDiffer,
  KeyValueDiffers,
  ViewContainerRef,
  TemplateRef,
  ɵisObservable,
} from '@angular/core'
import { Observable, of } from 'rxjs'
import { UserService } from '@bp/common'

/**
 * usage: <div *bpExpr="expression bind data"></div>
 * `this` point to data in expression
 */
@Directive({ selector: '[bpExpr][bpExprBind]' })
export class BpExpr implements DoCheck {
  @Input()
  set bpExpr(value: string) {
    this._context.$implicit = value
    this._updateView()
  }
  @Input()
  set bpExprBind(value: any) {
    this._context.data = value
    if (!this._differ && value)
      this._differ = this._differs.find(value).create()

    this._updateView()
  }

  private _context = new BpExprContext()
  private _differ: KeyValueDiffer<string, any> = null

  constructor(
    private _differs: KeyValueDiffers,
    private _vcRef: ViewContainerRef,
    private _tplRef: TemplateRef<BpExprContext>,
    private _userService: UserService,
  ) {}

  ngDoCheck() {
    if (this._differ) {
      const changes = this._differ.diff(this._context.data)
      if (changes) this._updateView()
    }
  }

  private _updateView() {
    const { $implicit: expr, data } = this._context
    let condition

    // If no expr, should show views
    if (!(expr && data)) {
      this._vcRef.clear()
      this._vcRef.createEmbeddedView(this._tplRef, this._context)
      return
    }

    this._vcRef.clear()

    try {
      condition = new Function(`return (${expr})`).call({
        ...data,
        USER: this._userService.get(),
      })
    } catch (e) {
      throw new Error(
        `Wrong expression in [bpExpr] directive, expression: ${expr}, data: ${JSON.stringify(
          data,
        )}`,
      )
    }

    if (!ɵisObservable(condition)) condition = of(condition)

    condition.subscribe(c => {
      if (c) {
        this._vcRef.createEmbeddedView(this._tplRef, this._context)
      } else {
        this._vcRef.clear()
      }
    })
  }
}

export class BpExprContext {
  public $implicit: any = null
  public data: any = null
}
