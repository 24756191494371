import { environment } from 'src/environments/environment'

export const globalConfig = {
  logo: '优颜',
  loginLogo: '优颜',
  footer: 'Copyright © 2022 YuanWorld Tech',
  // graphql | http
  netType: 'http',
  baseUrl: environment.production
    ? 'https://api.youface.tech/admin/'
    : 'https://api-dev.youface.tech/admin/',
}
