import {
  Component,
  Input,
  EventEmitter,
  OnInit,
  OnDestroy,
} from '@angular/core'
import { BehaviorSubject, Subscription } from 'rxjs'
import { debounceTime, switchMap, filter } from 'rxjs/operators'

import { WidgetComponent } from '../../view'
import { RowField } from '../../config'
import { DataAccessorService } from '../../init_accessor'
import { Payload } from '../../interface'

/**
 * can use for: text
 */
@Component({
  selector: 'bp--preset--search-text-widget',
  template: `
    <input
      nz-input
      [nzAutocomplete]="autoComplete ? auto : noauto"
      [placeholder]="field.search.placeholder || field.form.placeholder || ''"
      [ngModel]="value"
      (ngModelChange)="change.emit($event)"
      (input)="searchChange$.next($event.target?.value)"
    />
    <nz-autocomplete nzBackfill [nzDefaultActiveFirstOption]="false" #auto (selectionChange)="onSelect($event)">
      <ng-container *ngIf="!loading; else loadingTpl">
        <nz-auto-option
          *ngFor="let option of options"
          [nzValue]="option[field.search.relation_key] || option[field.identifier]"
        >
          <span
            *ngIf="field.search.template; else strTpl"
            [innerHTML]="field.search.template | template: option | safeHTML"
          ></span>
          <ng-template #strTpl>
            <span>{{ option[field.identifier] }}</span>
          </ng-template>
        </nz-auto-option>
      </ng-container>
      <ng-template #loadingTpl>
        <nz-auto-option nzDisabled>
          <i
            nz-icon
            nzType="loading"
            nzTheme="outline"
            class="loading-icon"
          ></i>
          加载中...
        </nz-auto-option>
      </ng-template>
    </nz-autocomplete>
    <nz-autocomplete #noauto> </nz-autocomplete>
  `,
})
export class BpSearchTextWidget extends WidgetComponent
  implements OnInit, OnDestroy {
  static meta = {
    type: 'preset_search',
    name: 'text',
  }

  @Input() value: string
  @Input() field: RowField

  change = new EventEmitter()
  autoComplete = false
  options: any[] = []
  loading = false
  dispatch = new EventEmitter<Payload>()

  searchChange$ = new BehaviorSubject('')

  private _subs = new Subscription()

  constructor(private _dataAccessorService: DataAccessorService) {
    super()
  }

  onSelect(e) {
    this.change.emit(e.nzValue)
    this.dispatch.emit({ type: 'search' })
  }

  ngOnInit() {
    this.autoComplete = this.field.search.advise

    if (!this.autoComplete) return

    // Init advise
    this._subs.add(
      this.searchChange$
        .pipe(
          debounceTime(500),
          filter(value => {
            if (!value) this.options = []
            return !!value
          }),
          switchMap(value => {
            this.loading = true

            return this._dataAccessorService.accessor.relate({
              type: 'advise',
              field: [
                ...this._dataAccessorService.moduleIdentifiers,
                this.field.identifier,
              ].join('.'),
              keyword: value,
              limit: 10,
            })
          }),
        )
        .subscribe(res => {
          this.loading = false
          this.options = res.data
        }),
    )
  }

  ngOnDestroy() {
    this._subs.unsubscribe()
  }
}
