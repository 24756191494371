import { Injectable, Inject } from '@angular/core'
import { Observable, of } from 'rxjs'
import { map, take, tap } from 'rxjs/operators'

import {
  AclAccessor,
  BP_ACL_ACCESSOR,
  AclField,
  AclValue,
} from './acl_accessor'
import { CurrentConfig } from '../config/config'
import { Module, RowAction } from '../config/model'

const MATCH_SYMBOL_ALL = '*'
const MATCH_SYMBOL_NEG = '!'
const MODULE_ICON = 'anticon anticon-block'

export type Permission = {
  name: string
  identifier: string
}

@Injectable({
  providedIn: 'root',
})
export class AclService {
  private _cache: {
    [props: string]: boolean
  } = {}

  constructor(
    @Inject(BP_ACL_ACCESSOR) private _aclAccessor: AclAccessor,
    private _currentConfig: CurrentConfig,
  ) {}

  /**
   * user acl: [
   *   'test.confirm',   // accurate acl
   *   'test.*',         // asterisk acl
   *   '*',              // asterisk acl
   *   '!test.confirm',  // negative acl
   *   '!test.*',        // negative acl
   * ]
   */
  check(acl: AclField): Observable<boolean> {
    const cacheID = acl.join('.')
    const haveCache = Object.keys(this._cache).includes(cacheID)

    if (haveCache) return of(this._cache[cacheID])

    return this._aclAccessor.get().pipe(
      take(1),
      map(acls => {
        if (!acls) return false

        const posAcls = acls.filter(a => !a[0].includes(MATCH_SYMBOL_NEG))
        const negAcls = acls.filter(a => a[0].includes(MATCH_SYMBOL_NEG))

        const isNeg = this._checkAclField(
          acl,
          // Remove ! string
          negAcls.map(a => [a[0].slice(1), ...a.slice(1)]),
        )
        if (isNeg) return false

        const ok = this._checkAclField(acl, posAcls)
        return ok
      }),
      tap(ok => (this._cache[cacheID] = ok)),
    )
  }

  getModuleAcl(module: Module) {
    let current = module
    const acls = [current.identifier]

    while (current.parent) {
      current = current.parent
      acls.unshift(current.identifier)
    }

    return acls.join('.')
  }

  getPermissions(): Observable<Permission[]> {
    return this._currentConfig.pipe(
      take(1),
      map(c => {
        const m = c.module.filter(
          // Exclude module acl
          _m => _m.level !== 'system' && !_m.exclude_acl,
        )
        const rst = []

        m.forEach(_m =>
          rst.push(
            ...this._walkList(_m, '', (action, acc) => ({
              name: action.name,
              identifier: `${acc}${action.identifier}`,
            })),
          ),
        )

        return rst
      }),
    )
  }

  getPermissionTree() {
    return this._currentConfig.pipe(
      take(1),
      map(nodes => {
        const m = nodes.module.filter(
          // Exclude module acl
          _m => _m.level !== 'system' && !_m.exclude_acl,
        )

        return this._walkTree(m, '')
      }),
      map(t => {
        return [
          {
            title: '全选',
            key: '*',
            isLeaf: false,
            expanded: true,
            children: t,
          },
        ]
      }),
    )
  }

  clearCache() {
    this._cache = {}
  }

  private _checkAclField(checkedAcl: AclField, posAcls: AclValue): boolean {
    return posAcls.some(userAcl => {
      for (let i = 0; i < checkedAcl.length; i++) {
        const userAclField = userAcl[i]
        const checkedAclField = checkedAcl[i]

        // asterisk acl
        if (userAclField === MATCH_SYMBOL_ALL) return true
        if (!userAclField || userAclField !== checkedAclField) return false
      }

      return true
    })
  }

  private _walkList<T>(
    node: Module,
    identifier: string,
    fn: (node: RowAction, acc: string) => T,
  ): T[] {
    const rst: T[] = []

    identifier = `${identifier}${node.identifier}.`

    if (node.action && node.action.length) {
      node.action
        // Exclude action acl
        .filter(a => !a.exclude_acl && a.type !== 'batch')
        .forEach(a => rst.push(fn(a, identifier)))
    }

    // Exclude children module acl
    const children = node.children.filter(m => !m.exclude_acl)

    if (children && children.length) {
      children.forEach(child =>
        rst.push(...this._walkList(child, identifier, fn)),
      )
    }

    return rst
  }

  private _walkTree(nodes: Module[], identifierPrefix: string) {
    if (!nodes || !nodes.length) return []

    return nodes.map(m => {
      const currentKey = `${identifierPrefix}${m.identifier}`
      const _children = m.children || []
      // Exclude children module acl
      const needAclChildren = _children.filter(c => !c.exclude_acl)
      const isLeaf = !(
        (needAclChildren && needAclChildren.length) ||
        (m.action && m.action.length)
      )

      if (isLeaf)
        return {
          title: m.name,
          key: `${currentKey}.*`,
          isLeaf,
          icon: MODULE_ICON,
        }

      const children = [
        ...this._walkTree(needAclChildren, `${currentKey}.`),
        ...m.action
          // Exclude action acl
          .filter(a => !a.exclude_acl && a.type !== 'batch')
          .map(a => ({
            title: a.name,
            key: `${currentKey}.${a.identifier}`,
            isLeaf: true,
          })),
      ]

      return {
        title: m.name,
        key: `${currentKey}.*`,
        isLeaf,
        icon: MODULE_ICON,
        children,
      }
    })
  }
}
