import { Injectable } from '@angular/core'
import { CosService } from './cos_service';
import { OssService } from './oss_service';
import { DirectService } from './direct_service';

@Injectable({
  providedIn: 'root',
})
export class UploadConfigService {
  private cache: UploadConfig

  constructor(
    private _cosService: CosService,
    private _ossService: OssService,
    private _directService: DirectService,
  ) {}

  set(config: UploadConfig) {
    this.cache = config
  }

  get(): UploadConfig {
    return this.cache
  }

  getService() {
    var uploadProvider = 'cos';
    if (this.cache.provider && this.cache.provider == 'oss') {
      return this._ossService
    }
    if (this.cache.provider && this.cache.provider == 'direct') {
      this._directService.setConfig(this.cache)
      return this._directService
    }
    this._cosService.setConfig(this.cache)
    return this._cosService
  }
}

export type UploadConfig = {
  provider?: string
  bucket: string
  region: string
  prefix: string
  img_prefix: string
}
