import { Pipe, PipeTransform } from '@angular/core'

import { parseISO, formatDistanceToNow } from 'date-fns'
import zhCN from 'date-fns/locale/zh-CN'

@Pipe({
  name: 'humanTime',
})
export class HumanTimePipe implements PipeTransform {
  transform(time: string | Date, addSuffix = true) {
    if (!(time instanceof Date)) {
      time = parseISO(time)
    }
    return formatDistanceToNow(time, {
      locale: zhCN,
      addSuffix,
    })
  }
}
