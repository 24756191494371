import { Component, OnInit, Input, EventEmitter } from '@angular/core'
import { ActionComponent, Module, Payload } from '@bp/core'
import { FormGroup, Validators, FormBuilder } from '@angular/forms'
import { SharedHttpService } from '~/shared/http/shared-http.service'
import { Router } from '@angular/router'
import { NzMessageService } from 'ng-zorro-antd'

@Component({
  selector: 'app-util-batch-process-contribution',
  templateUrl: './batch-process-contribution.component.html',
  styleUrls: [],
})
export class BatchProcessContributionComponent extends ActionComponent implements OnInit {
  static meta = {
    type: 'custom_action',
    name: 'batch-process-contribution',
  }

  dispatch = new EventEmitter<Payload>()

  @Input() entity: any
  @Input() module: Module

  constructor(
    private _fb: FormBuilder,
    private _router: Router,
    private _sharedHttp: SharedHttpService,
    private _msgService: NzMessageService,
  ) {
    super()
  }

  ngOnInit() {}

  bpOnClick = () => {
    this.handleOk()
  }

  handleOk() {
    this.dispatch.emit({
      type: 'batch',
      batchType: 'process',
      data: { processed: 1 },
    })
  }
}
