import {
  Component,
  Input,
  OnInit,
  EventEmitter,
  ViewEncapsulation,
  OnDestroy,
  OnChanges,
  SimpleChanges,
} from '@angular/core'

import './plugins'

import { WidgetComponent } from '../../../view'
import { RowField } from '../../../config'
import { UploadConfigService } from '@bp/common'
import { tap, map } from 'rxjs/operators'
import { zip } from 'rxjs'

/**
 * can use for: editor
 */
@Component({
  selector: 'bp--preset--form-editor-widget',
  template: `
    <div
      [froalaEditor]="options"
      [froalaModel]="innerValue || ''"
      (froalaModelChange)="updateValue($event)"
      (froalaInit)="initialize($event)"
    ></div>
  `,
  styles: [``],
  encapsulation: ViewEncapsulation.None,
})
export class BpFormEditorWidget extends WidgetComponent
  implements OnInit, OnDestroy, OnChanges {
  static meta = {
    type: 'preset_form',
    name: 'editor',
  }

  @Input() entity: any
  @Input() value: string
  @Input() field: RowField

  change = new EventEmitter<string>()
  initControls = null
  editorInited = false
  options = {
    heightMin: 400,
    language: 'zh_cn',
    toolbarSticky: false,
    imagePaste: false,
    events: {
      initialized: () => this._setDisabled(),
      'image.beforeUpload': images => this._beforeUpload(images),
    },
  }
  imgPrefix = this._uploadConfigService.get().img_prefix
  innerValue = ''
  inited = false

  constructor(
    private _uploadConfigService: UploadConfigService,
  ) {
    super()
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.value && !this.inited && this.value) {
      this.inited = true
      const loadAfterInit = setInterval(() => {
        if (this.editorInited) {
          this.innerValue = this.value
          clearInterval(loadAfterInit)
        }
      }, 100)
    }
  }

  ngOnInit() {}

  ngOnDestroy() {
    this.initControls && this.initControls.destroy()
  }

  initialize(initControls) {
    this.initControls = initControls

    initControls.initialize()
  }

  updateValue(t: string) {
    this.change.emit(
      t.replace(
        '<p data-f-id="pbf" style="text-align: center; font-size: 14px; margin-top: 30px; opacity: 0.65; font-family: sans-serif;">Powered by <a href="https://www.froala.com/wysiwyg-editor?pb=1" title="Froala Editor">Froala Editor</a></p>',
        '',
      ),
    )
  }

  private _setDisabled() {
    const editor = this.initControls.getEditor()
    this.editorInited = true
    if (this.field.feature.includes('readonly')) editor.edit.off()
  }

  private _beforeUpload(images) {
    const editor = this.initControls.getEditor()
    const imageArr: any[] = [].slice.call(images, 0)
    const imgObs = imageArr.map(img => this._uploadConfigService.getService().uploadFile(img))

    zip(...imgObs)
      .pipe(
        map(allRes =>
          allRes
            .filter(res => res && res.status === 'completed')
            .map(res => res.data.path),
        ),
        tap(urls => {
          urls.forEach(url => editor.image.insert(url && url.substring(0, 4) == 'http' ? url : this.imgPrefix + url))
        }),
      )
      .subscribe()

    return false
  }
}
