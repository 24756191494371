import { NgModule, Inject } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { CommonModule } from '@angular/common'

// import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg'
import { FroalaEditorModule } from 'angular-froala-wysiwyg/dist/editor/editor.module.js'
import { FroalaViewModule } from 'angular-froala-wysiwyg/dist/view/view.module.js'

import { CommonModule as BpCommonModule } from '@bp/common'
import { PRESET_WIDGETS } from './widgets'
import { DynamicComponentLoader, WIDGET_LOADER } from '../view'
import { ACL_WIDGETS } from '../acl'

@NgModule({
  declarations: [PRESET_WIDGETS, ACL_WIDGETS],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BpCommonModule,
    FroalaEditorModule.forRoot(),
    FroalaViewModule.forRoot(),
  ],
  exports: [],
  entryComponents: [PRESET_WIDGETS, ACL_WIDGETS],
})
export class LoadPresetModule {
  constructor(
    @Inject(WIDGET_LOADER) private _widgetLoader: DynamicComponentLoader,
  ) {
    _widgetLoader.loadComponents(PRESET_WIDGETS)
    _widgetLoader.loadComponents(ACL_WIDGETS)
  }
}
