import { Injectable } from '@angular/core'
import {
  CanActivate,
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
} from '@angular/router'
import { Observable, of } from 'rxjs'

import { RouteData } from '../config'
import { AclService } from './acl_service'

@Injectable({
  providedIn: 'root',
})
export class AclGuard implements CanActivate {
  constructor(private _aclService: AclService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> {
    const data = next.data as RouteData
    const acl = data.acl
    if (!acl || !acl.length) return of(false)

    return this._aclService.check(acl)
  }
}
