import { Component, Input, OnInit } from '@angular/core'
import { WidgetComponent, RowField } from '@bp/core'
import { ActivatedRoute } from '@angular/router'

import { ClipboardService } from '../../services/clipboard.service'

@Component({
  selector: 'bp--preset--module-list-acl',
  template: `
    <ng-container *ngIf="entity?.action?.length">
    <nz-tag nz-dropdown [nzDropdownMenu]="menu">显示</nz-tag>
      <nz-dropdown-menu #menu="nzDropdownMenu">
        <ul nz-menu>
          <li class="acl" *ngFor="let action of entity.action">
            <nz-tag
              [nzColor]="'blue'"
              (click)="
                clipboard.copy(entity.identifier + '.' + action.identifier)
              "
              >点击复制</nz-tag
            >{{ entity.identifier }}.{{ action.identifier }}
          </li>
        </ul>
      </nz-dropdown-menu>
    </ng-container>
  `,
  styles: [
    `
      .acl {
        padding: 5px 10px;
      }
    `,
  ],
})
export class BpModuleListAcl extends WidgetComponent implements OnInit {
  static meta = {
    name: 'module-list-acl',
  }

  @Input() entity: any
  @Input() field: RowField

  routeData = null

  constructor(
    public clipboard: ClipboardService,
    private _route: ActivatedRoute,
  ) {
    super()
  }

  ngOnInit() {}
}
