import { Injectable } from '@angular/core'

import { CascaderOption } from 'ng-zorro-antd'

import * as AreaDataModule from './china-area-data.v4.json'

const NO_DATA_TEXT = '暂无数据'
const CHINA_AREA_DATA = (AreaDataModule as any).default

@Injectable({
  providedIn: 'root',
})
export class DistrictService {
  constructor() {}

  getCascaderData(level: Level): CascaderOption[] {
    let casData: CascaderOption[]

    switch (level) {
      case 'province':
        casData = this.getCasProvince()
        break
      case 'city':
        casData = this.getCasCity()
        break
      case 'district':
        casData = this.getCasDistrict()
        break
      default:
        casData = this.getCasDistrict()
    }

    return casData
  }

  getValues(level: Level, code: string): string[] {
    const codeNum = parseInt(code, 10)
    const provinceNum = Math.floor(codeNum / 10000) * 10000
    const cityNum = Math.floor(codeNum / 100) * 100
    const province = provinceNum.toString()
    const city = cityNum.toString()
    const district = codeNum.toString()

    switch (level) {
      case 'province':
        return [province]
      case 'city':
        return [province, city]
      case 'district':
        return [province, city, district]
    }
  }

  getLabels(level: Level, code: string): string[] {
    const codeNum = parseInt(code, 10)
    const provinceNum = Math.floor(codeNum / 10000) * 10000
    const cityNum = Math.floor(codeNum / 100) * 100
    const province = provinceNum.toString()
    const city = cityNum.toString()
    const district = codeNum.toString()

    switch (level) {
      case 'province':
        return [this.getLabel('province', province)]
      case 'city':
        return [this.getLabel('province', province), this.getLabel('city', city)]
      case 'district':
        return [this.getLabel('province', province), this.getLabel('city', city), this.getLabel('district', district)]
    }
  }

  getLabel(level: Level, code: string): string {
    const codeNum = parseInt(code, 10)
    const provinceNum = Math.floor(codeNum / 10000) * 10000
    const cityNum = Math.floor(codeNum / 100) * 100

    switch (level) {
      case 'province':
        return CHINA_AREA_DATA[86][provinceNum] || NO_DATA_TEXT
      case 'city':
        const cities = CHINA_AREA_DATA[provinceNum]
        return (cities && cities[cityNum]) || NO_DATA_TEXT
      case 'district':
        const districts = CHINA_AREA_DATA[cityNum]
        return (districts && districts[codeNum]) || NO_DATA_TEXT
      default:
        return NO_DATA_TEXT
    }
  }

  private getCasProvince(isLeaf = true): CascaderOption[] {
    const casData = Object.entries(CHINA_AREA_DATA[86]).map(
      ([value, label]: [string, string]) => ({ value, label }),
    )

    if (isLeaf) this.setLeaf(casData)

    return casData
  }

  private getCasCity(isLeaf = true): CascaderOption[] {
    const casData = this.getCasProvince(false)

    casData.forEach(province => {
      province.children = Object.entries(
        CHINA_AREA_DATA[province.value] || {},
      ).map(([value, label]: [string, string]) => ({ value, label }))
    })

    if (isLeaf) this.setLeaf(casData)

    return casData
  }

  private getCasDistrict(): CascaderOption[] {
    const casData = this.getCasCity(false)

    casData.forEach(province => {
      province.children.forEach(city => {
        city.children = Object.entries(CHINA_AREA_DATA[city.value] || {}).map(
          ([value, label]: [string, string]) => ({ value, label }),
        )
      })
    })

    this.setLeaf(casData)

    return casData
  }

  private setLeaf(data: CascaderOption[]) {
    data.forEach(d => {
      if (d.children) {
        this.setLeaf(d.children)
      } else {
        d.isLeaf = true
      }
    })
  }
}

export type Level = 'province' | 'city' | 'district'
