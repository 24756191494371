import {
  Component,
  Input,
  OnInit,
  EventEmitter,
  OnDestroy,
  OnChanges,
  SimpleChanges,
} from '@angular/core'
import { BehaviorSubject, Subscription } from 'rxjs'
import { debounceTime, filter, switchMap } from 'rxjs/operators'

import { WidgetComponent } from '../../view'
import { RowField } from '../../config'
import { DataAccessorService } from '../../init_accessor'

/**
 * can use for: relation
 */
@Component({
  selector: 'bp--preset--form-relation-widget',
  template: `
    <nz-select
      nzAllowClear
      nzShowSearch
      nzNotFoundContent="输入关键词搜索"
      [nzPlaceHolder]="field.form.placeholder"
      [nzDisabled]="readonly"
      [nzServerSearch]="true"
      (nzOnSearch)="searchChange$.next($event)"
      [ngModel]="value"
      (ngModelChange)="valueChange($event)"
      (nzOpenChange)="open($event)"
      (nzScrollToBottom)="loadMore()"
    >
      <nz-option
        *ngFor="let option of options"
        [nzCustomContent]="haveTemplate"
        [nzLabel]="option[field.form.label_key]"
        [nzValue]="option[field.form.relation_key]"
      >
        <span
          [innerHTML]="field.form.template | template: option | safeHTML"
        ></span>
      </nz-option>
      <nz-option *ngIf="loading" nzCustomContent nzDisabled>
        <i nz-icon nzType="loading" nzTheme="outline" class="loading-icon"></i>
        加载中...
      </nz-option>
    </nz-select>
  `,
})
export class BpFormRelationWidget extends WidgetComponent
  implements OnInit, OnDestroy, OnChanges {
  static meta = {
    type: 'preset_form',
    name: 'relation',
  }

  @Input() entity: any
  @Input() value: any
  @Input() field: RowField

  change = new EventEmitter<string>()
  options: {
    [props: string]: string
  }[] = []
  haveTemplate = false
  loading = false
  nomore = false
  readonly = false

  searchChange$ = new BehaviorSubject('')

  private _subs = new Subscription()
  private _inited = false
  private _offset = 0
  private _keyword = ''

  constructor(private _dataAccessorService: DataAccessorService) {
    super()
  }

  // Get option list when init
  ngOnChanges(changes: SimpleChanges) {
    if (this._inited) return

    if (changes.value && changes.value.currentValue) {
      this._inited = true
      this.loading = true
      this._offset = 0

      this._dataAccessorService.accessor
        .relate({
          type: 'advise',
          field: [
            ...this._dataAccessorService.moduleIdentifiers,
            this.field.identifier,
          ].join('.'),
          // Get specific value of item
          key: this.value,
          offset: this._offset,
        })
        .subscribe(res => {
          this.loading = false
          this.options = res.data
        })
    }
  }

  loadMore(): void {
    // console.log('this.nomore', this.nomore)
    if (this.nomore) {
      return
    }
    this.loading = true
    this._offset += 20

    this._relate(this._keyword, this._offset).subscribe(res => {
      if (res.data.length === 0) {
        this.nomore = true
      }
      this.loading = false
      this.options = [...this.options, ...res.data]
    })
  }

  ngOnInit() {
    this.haveTemplate = !!this.field.form.template
    this.readonly = this.field.feature.includes('readonly')

    if (typeof this.value === "string") {
      this.value = parseInt(this.value)
    }

    // Init advise
    this._subs.add(
      this.searchChange$
        .pipe(
          debounceTime(500),
          switchMap(value => {
            this.loading = true
            this.nomore = false
            this._offset = 0
            this._keyword = value

            return this._relate(this._keyword, this._offset)
          }),
        )
        .subscribe(res => {
          this.loading = false
          this.options = res.data
        }),
    )
  }

  ngOnDestroy() {
    this._subs.unsubscribe()
  }

  valueChange(e: any) {
    if (!e) {
      this._offset = 0
      this._relate('', this._offset).subscribe(res => (this.options = res.data))
    }
    this.change.emit(e)
  }

  open(o: boolean) {
    this.nomore = false;
    if (this._inited || !o) return
    this._inited = true
    this._offset = 0

    // Fetch advise list when init, once
    this._relate('', this._offset).subscribe(res => (this.options = res.data))
  }

  private _relate(value: string, offset: number) {
    return this._dataAccessorService.accessor.relate({
      type: 'advise',
      field: [
        ...this._dataAccessorService.moduleIdentifiers,
        this.field.identifier,
      ].join('.'),
      keyword: value,
      limit: 20,
      offset,
    })
  }
}
