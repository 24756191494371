import {
  Component,
  Input,
  ChangeDetectionStrategy,
  OnInit,
  OnChanges,
  SimpleChanges,
  ChangeDetectorRef,
} from '@angular/core'

import { WidgetComponent } from '../../view'
import { RowField } from '../../config'

/**
 * can use for: checkbox
 */
@Component({
  selector: 'bp--preset--list-checkbox-widget',
  template: `
    <span>{{ displayStr }}</span>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BpListCheckboxWidget extends WidgetComponent
  implements OnInit, OnChanges {
  static meta = {
    type: 'preset_list',
    name: 'checkbox',
  }

  @Input() entity: any
  @Input() value: string[]
  @Input() field: RowField

  displayStr: string

  constructor(private _cdRef: ChangeDetectorRef) {
    super()
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.value) {
      this._updateValue()
      this._cdRef.detectChanges()
    }
  }

  ngOnInit() {}

  private _updateValue() {
    const data = this.value || []
    const extra = this.field.extra || {}

    if (!data.length) return (this.displayStr = '-')

    this.displayStr = data.reduce((prev, current) => {
      const label = extra[current]

      if (!prev) {
        return label ? `${label}` : prev
      } else {
        return label ? `${prev}, ${label}` : prev
      }
    }, '')
  }
}
