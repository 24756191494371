import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'

import { DataAccessorService } from '../../../init_accessor'
import { RouteData } from '../../../config/model'
import { tap } from 'rxjs/operators'

@Component({
  selector: 'bp-profile-page',
  templateUrl: './profile_page.html',
  styleUrls: ['./profile_page.styl'],
})
export class BpProfilePage implements OnInit {
  id: number
  loading = true
  data: any = null
  bpConfig = this._route.snapshot.data as RouteData
  bpModule = this.bpConfig.module
  bpField = this.bpModule.field || []
  profileField = this.bpField.filter(f => f.place.includes('profile'))

  constructor(
    private _route: ActivatedRoute,
    private _dataAccessorService: DataAccessorService,
  ) {
    this.id = this._route.snapshot.params.id

    _dataAccessorService.init({
      route: _route,
      fields: this.bpField,
    })

    _dataAccessorService.accessor
      .getOneById(this.id)
      .pipe(
        tap(data => {
          this.data = data
          this.loading = false
        }),
      )
      .subscribe()
  }

  ngOnInit() {}
}
