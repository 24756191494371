import { Component, Input, OnInit, EventEmitter } from '@angular/core'

import { WidgetComponent } from '../../view'
import { RowField } from '../../config'

/**
 * can use for: textarea
 */
@Component({
  selector: 'bp--preset--form-textarea-widget',
  template: `
    <textarea
      nz-input
      [placeholder]="field.form.placeholder || ''"
      [disabled]="readonly"
      [nzAutosize]="{ minRows: 4, maxRows: 4 }"
      [ngModel]="value"
      (ngModelChange)="change.emit($event)"
    ></textarea>
  `,
})
export class BpFormTextareaWidget extends WidgetComponent implements OnInit {
  static meta = {
    type: 'preset_form',
    name: 'textarea',
  }

  @Input() entity: any
  @Input() value: string
  @Input() field: RowField

  change = new EventEmitter<string>()
  readonly = false

  ngOnInit() {
    this.readonly = this.field.feature.includes('readonly')
  }
}
