import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { format } from 'date-fns'
import { HttpEvent, HttpEventType } from '@angular/common/http'

import { UploadHttpService, SaveFileSuccess } from './upload_http_service'

@Injectable({
  providedIn: 'root',
})
export class OssService {
  constructor(
    private uploadHttpService: UploadHttpService,
  ) {}

  uploadFile(file: File): Observable<OssResponse> {
    return new Observable(observer => {
      const fileName = file.name ? file.name : Math.random().toString(36).substr(2, 8) + '.jpg'
      this.uploadHttpService
        .getAuthorization({
          method: 'post',
          path: fileName,
        })
        .subscribe(config => {
          this.uploadHttpService.ossUpload(file, config).subscribe(res => {
            this.uploadHttpService
              .saveFile({ name: fileName, path: config.dir, mime: file.type })
              .subscribe(res => {
                observer.next({
                  status: 'completed',
                  data: res.data,
                })
                observer.complete()
              })
          })
        })
    })
  }
}

export type OssResponse = {
  status: 'progress' | 'completed' | 'error'
  data: SaveFileSuccess
}
