import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Response, GlobalConfig, CustomConfig } from '../models'
import { HttpParamsService } from '@bp/http'

@Injectable({
  providedIn: 'root',
})
export class SharedHttpService {
  constructor(
    private _http: HttpClient,
    private _paramsSergvice: HttpParamsService,
  ) {}

  config() {
    return this._http.get<Response<GlobalConfig<CustomConfig>>>('config')
  }

  getIndex() {
    return this._http.get('index')
  }

  modifyPassword(password, newPassword) {
    return this._http.post('session/password', {
      password,
      new_password: newPassword
    })
  }

  templateBatch(id, action, params) {
    return this._http.post(`template/batch`, {
      keys: [id],
      action,
      params
    })
  }
}
