import { Injectable } from '@angular/core'
import store from 'store'
import expirePlugin from 'store/plugins/expire'

store.addPlugin(expirePlugin)

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  constructor() {}

  set(value: string, expiredAt: number) {
    const expires = new Date().getTime() + parseInt('' + expiredAt, 10) * 1000
    return store.set('token', value, expires)
  }

  get() {
    return store.get('token')
  }

  remove() {
    return store.remove('token')
  }
}
