import {
  Component,
  OnInit,
  Input,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core'
import { NzTreeNodeOptions, NzTreeComponent, NzTreeNode } from 'ng-zorro-antd'
import { RelationData } from '@bp/http'

import { RowField } from '../../../config'

@Component({
  selector: 'bp-drag-tree',
  templateUrl: './drag_tree.html',
  styleUrls: ['./drag_tree.styl'],
})
export class BpDragTree implements OnInit {
  @Input() fields: RowField[]
  @Input() data: any[]
  @Input() primaryKey: string
  @Input() features: {
    drag: boolean
    pagination: boolean
    tree: boolean
  }
  @Output() confirm = new EventEmitter<RelationData[]>()

  @ViewChild('tree', { static: false }) treeRef: NzTreeComponent

  treeData = []
  modalVisible = false

  constructor() {}

  ngOnInit() {}

  showModal() {
    this.treeData = this._transformToTree(this.data)
    this.modalVisible = true
  }

  handleOk(): void {
    const relationData = this._transformToList(this.treeRef.getTreeNodes())

    this.modalVisible = false
    this.confirm.emit(relationData)
  }

  handleCancel(): void {
    this.modalVisible = false
  }

  private _transformToTree(data: any[]): NzTreeNodeOptions[] {
    if (!data || !data.length) return []

    const pk = this.primaryKey
    const firstField = this.fields[0]
    const fields = this.fields.slice(1, 3)

    return data.map(d => {
      const title = fields.reduce(
        (prev, current) => `${prev}, ${current.name}: ${d[current.identifier]}`,
        `${firstField.name}: ${d[firstField.identifier]}`,
      )

      return {
        title,
        key: d[pk],
        expanded: false,
        children: this._transformToTree(d._children),
        isLeaf: !this.features.tree,
      }
    })
  }

  private _transformToList(data: NzTreeNode[]): RelationData[] {
    const list = []

    data.forEach(d => {
      const relationData: RelationData = {
        id: d.key,
        parent_id: null,
      }

      if (d.parentNode) {
        relationData.parent_id = d.parentNode.key
      }

      list.push(relationData)

      if (d.children && d.children.length) {
        list.push(...this._transformToList(d.children))
      }
    })

    return list
  }
}
