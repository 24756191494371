import { SimpleChanges, EventEmitter } from '@angular/core'
import { FormGroup } from '@angular/forms'

import { DynamicComponent } from './dynamic_component'
import { RowField, Module } from '../../config'
import { Payload } from '../../interface'

export type WidgetState = 'edit' | 'create' | 'list' | 'search'

export interface WidgetComponentDef {
  // Input
  /** Value is field input value when it have */
  value: any | any[]
  /** Field is RowField */
  field: RowField
  /**
   * State: edit, create, list, search
   */
  state?: WidgetState
  /**
   * Entity is completely data in edit form or one row of list
   */
  entity?: any | any[]
  /**
   * FormGroup is edit form's ng FormGroup
   */
  formGroup?: FormGroup
  module?: Module

  // Output
  /** Value change */
  change?: EventEmitter<any | any[]>
  /** Form touched */
  touch?: EventEmitter<any | any[]>
  /** Actions: 'create' | 'delete' | 'update' | 'refresh' | 'search' */
  dispatch?: EventEmitter<Payload>

  ngOnChanges?: (changes: SimpleChanges) => void
}

export class WidgetComponent extends DynamicComponent {}
