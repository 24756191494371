import { BatchProcessContributionComponent } from './batch-process-contribution.component'
import { TemplateCopyComponent } from './template-copy.component'
import { TemplateEnabledSwitcherComponent } from './template-enabled-switcher.component'
import { TemplateRefreshCategoryIdComponent } from './template-refresh-category-id.component'
import { TemplateRefreshCountryGroupIdComponent } from './template-refresh-country-group-id.component'
import { TemplateRefreshCreatedAtComponent } from './template-refresh-created-at.component'
import { TemplateRefreshEnabledComponent } from './template-refresh-enabled.component'
import { TemplateRefreshFreeComponent } from './template-refresh-free.component'
import { TemplateRefreshSequenceComponent } from './template-refresh-sequence.component'
import { TemplateVideoComponent } from './template-video.component'
import { TranslationComponent } from './translation.component'

export const WIDGETS = [
    BatchProcessContributionComponent,
    TemplateVideoComponent,
    TranslationComponent,
    TemplateRefreshCreatedAtComponent,
    TemplateEnabledSwitcherComponent,
    TemplateCopyComponent,
    TemplateRefreshCategoryIdComponent,
    TemplateRefreshCountryGroupIdComponent,
    TemplateRefreshEnabledComponent,
    TemplateRefreshSequenceComponent,
    TemplateRefreshFreeComponent,
]
