import {
  Component,
  OnInit,
  Inject,
  OnDestroy,
  ViewChild,
  TemplateRef,
  AfterViewInit,
} from '@angular/core'
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout'
import { Location } from '@angular/common'
import { HttpBackend, HttpClient } from '@angular/common/http'
import { Observable, Subscription, interval, combineLatest } from 'rxjs'
import { map, tap } from 'rxjs/operators'
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  Router,
  ChildActivationEnd,
} from '@angular/router'
import {
  RouteData,
  CurrentConfig,
  Nav,
  ModuleLevel,
  BP_CONFIG_ACCESSOR,
  ConfigAccessor,
  DevConfigAccessor,
  AclService,
} from '@bp/core'
import { Store } from '@ngrx/store'
import { NzNotificationService } from 'ng-zorro-antd'

import { environment } from 'src/environments/environment'
import * as fromAuth from '~/auth/store'
import * as authAction from '~/auth/store/actions'
import { globalConfig } from '~/startup.config'
import { SharedHttpService } from '~/shared/http/shared-http.service'

type NavPaths = {
  [props: string]: NavPath
}
type NavPath = {
  nav: Nav
  parent: NavPath
}

@Component({
  selector: 'app-flat-layout',
  templateUrl: './flat.component.html',
  styleUrls: ['./flat.component.styl'],
})
export class FlatLayoutComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('noti', { static: true }) notiTpl: TemplateRef<any>

  isHandset$: Observable<boolean>
  notSameConfig$ = this._configAccessor.detect()

  routeData: RouteData & {
    // Custom route data
    title?: string
    back?: boolean
  }
  currentRoute: ActivatedRouteSnapshot
  navs: Nav[] = []
  navPaths: NavPaths = {}
  devNavPaths: NavPaths = {}
  navPath: Nav[] = []
  isProd = environment.production
  firstShowNoti = false
  showPasswordModal = false
  globalConfig = globalConfig

  passwordForm = {
    password: '',
    newPassword: '',
  }

  private _navs: Nav[] = []
  private _devNavs: Nav[] = []
  private _originNavs: Nav[] = []
  private _originDevNavs: Nav[] = []
  private _previousLevel: ModuleLevel = null
  private _subs = new Subscription()
  private _http: HttpClient
  private _configServerAddr = `http://${location.hostname}:4222`

  checkAcl = (acl: string) => {
    return this._aclService.check(acl.split('.'))
  }

  constructor(
    public currentConfig: CurrentConfig,
    public _location: Location,
    private _aclService: AclService,
    private _breakpointObserver: BreakpointObserver,
    private _route: ActivatedRoute,
    private _router: Router,
    @Inject(BP_CONFIG_ACCESSOR) private _configAccessor: ConfigAccessor,
    private _store: Store<fromAuth.AuthState>,
    private _handle: HttpBackend,
    private _notiService: NzNotificationService,
    private _shardHttpService: SharedHttpService,
  ) {
    this.isHandset$ = _breakpointObserver
      .observe(Breakpoints.Handset)
      .pipe(map(({ matches }) => matches))

    this._subs.add(
      this.currentConfig.subscribe(config => {
        this._originNavs = config.nav.filter(n => n.level !== 'system')
        this._originDevNavs = config.nav.filter(n => n.level === 'system')

        // Nav path for trace tree nav
        this._navs = this._getNavPath(this.navPaths, this._originNavs, null)
        this._devNavs = this._getNavPath(
          this.devNavPaths,
          this._originDevNavs,
          null,
        )
      }),
    )

    this._subs.add(
      _router.events.subscribe(event => {
        if (event instanceof ChildActivationEnd) {
          const route = this._getLatestChild(event.snapshot)

          this.currentRoute = event.snapshot
          this.routeData = route.data as RouteData
          this._updateNav()
        }
      }),
    )

    this._http = new HttpClient(_handle)
  }

  ngOnInit() {
    if (environment.production) return

    const notiInterval$ = interval(10000)
      .pipe(
        tap(() => {
          combineLatest(
            this._http.get(this._configServerAddr),
            this._configAccessor.get(),
          ).subscribe(([remote, local]: any) => {
            if (this.firstShowNoti) {
              notiInterval$.unsubscribe()
              return
            }

            if (remote.version > local.version) {
              this.firstShowNoti = true
              this._notiService.template(this.notiTpl, { nzDuration: 0 })
            }
          })
        }),
      )
      .subscribe()

    this._subs.add(notiInterval$)
  }

  ngAfterViewInit() {
    this._configAccessor.detect().subscribe(code => {
      if (code === 2) {
        this.firstShowNoti = true
        this._notiService.template(this.notiTpl, { nzDuration: 0 })
      }
    })
  }

  ngOnDestroy() {
    this._subs.unsubscribe()
  }

  syncRemote(notification) {
    const accessor = this._configAccessor as DevConfigAccessor

    accessor
      .applyRemoteConfig()
      .pipe(
        tap(() => {
          notification.close()
          location.reload()
        }),
      )
      .subscribe()
  }

  showPasswordModel() {
    this.showPasswordModal = true
  }

  handlePasswordModelCancel() {
    this.showPasswordModal = false
    this.passwordForm = {
      password: '',
      newPassword: ''
    }
  }

  modifyPassword() {
    this._shardHttpService
      .modifyPassword(this.passwordForm.password, this.passwordForm.newPassword)
      .subscribe((res) => {
        this.handlePasswordModelCancel()
      })
  }

  exportConfig() {
    const accessor = this._configAccessor as DevConfigAccessor

    accessor.getStr().subscribe(configStr => {
      this._http
        .post(this._configServerAddr, {
          content: configStr,
        })
        .subscribe(() => {
          location.reload()
        })
    })
  }

  logout() {
    this._store.dispatch(new authAction.Logout())
  }

  private _getLatestChild(
    route: ActivatedRouteSnapshot,
  ): ActivatedRouteSnapshot {
    let _route: ActivatedRouteSnapshot = route

    while (_route.firstChild) {
      _route = _route.firstChild
    }

    return _route
  }

  private _setNavs(level: ModuleLevel) {
    level === 'system' ? (this.navs = this._devNavs) : (this.navs = this._navs)
  }

  private _setPath(paths: NavPaths, routePath: string) {
    let currentPath = paths[routePath]

    if (currentPath) this.navPath = []

    while (currentPath) {
      this.navPath.unshift(currentPath.nav)
      currentPath = currentPath.parent
    }
  }

  private _updateNav() {
    const level =
      this.routeData && this.routeData.module
        ? this.routeData.module.level
        : 'user'

    if (this._previousLevel !== level) this._setNavs(level)

    const paths = level === 'system' ? this.devNavPaths : this.navPaths

    this._setPath(paths, this._getURL() || '/')

    this._previousLevel = level
  }

  private _getNavPath(paths: NavPaths, navs: any[], parent: Nav): any[] {
    const _navs = []

    navs.forEach(nav => {
      const current = { ...nav }
      let _parent = null

      if (nav.parent_id) {
        _parent = _navs.find(d => d.name === current.parent_id)

        if (!_parent.children) _parent.children = []

        _parent.children.push(current)
      } else {
        _navs.push(current)
      }

      if (!nav.path) return

      const currentPath = {
        nav: current,
        parent: null,
      }

      if (_parent) {
        currentPath.parent = paths[_parent.path]
      }

      paths[nav.path] = currentPath
    })

    return _navs
  }

  private _getURL() {
    const rootRoute = this.currentRoute.root
    let route = rootRoute
    const url = []

    url.push(...route.url.map(u => u.path))

    while (route && route.firstChild) {
      url.push(...route.firstChild.url.map(u => u.path))

      route = route.firstChild
    }

    return url.join('/')
  }
}
