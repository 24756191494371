import { Inject, Provider } from '@angular/core'
import { Observable, combineLatest } from 'rxjs'
import { map, take, tap } from 'rxjs/operators'
import { Store, select } from '@ngrx/store'
import { DataAccessor, ListData, AccessorEvent, RelationData } from '@bp/http'

import * as fromStore from './store/index'
import * as ListActions from './store/list_actions'
import {
  BP_STORE_MODULE_IDENTIFIER,
  BP_STORE_NAV_IDENTIFIER,
} from './store/provide'
import { BpInitializer } from './bp_initializer'
import { BP_CONFIG_ACCESSOR, ConfigAccessor } from './config'
import { ActivatedRoute } from '@angular/router'

const flatDataName = ['dev/module', 'dev/nav']

export class DevDataAccessor extends DataAccessor {
  private _flatDataIdentifierMap = {
    'dev/module': this._storeModuleIdentifier,
    'dev/nav': this._storeNavIdentifier,
  }
  /**
   * Actions, Fields data in module data
   * Do not need get data from module data when is flat
   */
  private _isFlat: boolean
  /**
   * Actions, Fields data in module data
   * Get data from module data when isn't flat
   */
  private _currentIdentifier: string

  constructor(
    private _store: Store<fromStore.State>,
    @Inject(BP_STORE_MODULE_IDENTIFIER) private _storeModuleIdentifier: string,
    @Inject(BP_STORE_NAV_IDENTIFIER) private _storeNavIdentifier: string,
    private _bpInitializer: BpInitializer,
    @Inject(BP_CONFIG_ACCESSOR) private _configAccessor: ConfigAccessor,
    private _accessorEvent: AccessorEvent,
    private _aroute: ActivatedRoute,
  ) {
    super(_aroute)
    this.afterInited.subscribe(() => {
      this._isFlat = flatDataName.some(
        name => name === this.config.module.identifier,
      )

      this._currentIdentifier = this._isFlat
        ? this._flatDataIdentifierMap[this.config.module.identifier]
        : this._storeModuleIdentifier
    })

    this._registerEvent()
  }

  getOne(): Observable<any> {
    return null
  }

  getOneById(id: number | string): Observable<any> {
    const config = this.config
    const lastParentId = this._getLastParentId()

    return this._isFlat
      ? this._getOneById(id)
      : /** Data from module data */
        this._getOneById(lastParentId).pipe(
          map(
            data =>
              data[config.module.identifier].filter(
                _d => _d.identifier === id,
              )[0],
          ),
        )
  }

  getList(): Observable<ListData<any>> {
    const list = this._store.pipe(
      select(fromStore.selectAllData(this._currentIdentifier)),
    )
    const lastParentId = this._getLastParentId()
    const listData = this._isFlat
      ? list
      : list.pipe(
          map(
            data =>
              data.filter(_d => _d.identifier === lastParentId)[0][
                this.config.module.identifier
              ],
          ),
        )

    return listData.pipe(
      map(data => ({
        data,
        meta: {
          count: data.length,
          limit: 0,
          offset: 0,
          total: data.length,
        },
      })),
    )
  }

  create(data: any): Observable<any> {
    return this._store
      .pipe(select(fromStore.selectAllData(this._currentIdentifier)))
      .pipe(
        take(1),
        tap(list => {
          const processedData = this._accessorEvent.emit(
            this.config.module.identifier,
            'create',
            { prev: list, current: data },
          )

          this._store.dispatch(
            new ListActions.LoadAll('', this._currentIdentifier, {
              data: processedData || this._createChild(list, data),
            }),
          )

          this._reloadBpConfig()
        }),
      )
  }

  update(data: any) {
    return null
  }

  updateById(id: number | string, data: any): Observable<any> {
    return this._store
      .pipe(select(fromStore.selectAllData(this._currentIdentifier)))
      .pipe(
        take(1),
        tap(list => {
          const processedData = this._accessorEvent.emit(
            this.config.module.identifier,
            'updateById',
            { prev: [...list], current: data, updateId: id },
          )

          this._store.dispatch(
            new ListActions.LoadAll('', this._currentIdentifier, {
              data: processedData || list,
            }),
          )

          this._reloadBpConfig()
        }),
      )
  }

  delete() {
    return null
  }

  deleteById(id: number | string): Observable<any> {
    const pk = this.config.module.primary_key

    return this._store
      .pipe(select(fromStore.selectAllData(this._currentIdentifier)))
      .pipe(
        take(1),
        tap(list => {
          const processedData = this._accessorEvent.emit(
            this.config.module.identifier,
            'delete',
            { prev: list, current: id },
          )

          this._store.dispatch(
            new ListActions.LoadAll('', this._currentIdentifier, {
              data:
                processedData ||
                list.filter(d => d[pk] !== id && d.parent_id !== id),
            }),
          )

          this._reloadBpConfig()
        }),
      )
  }

  batch(
    action: string,
    keys: number[] | string[],
    params: any,
  ): Observable<any> {
    return null
  }

  sequence(ids: RelationData[]): Observable<any> {
    return this._store
      .pipe(select(fromStore.selectAllData(this._currentIdentifier)))
      .pipe(
        take(1),
        tap(list => {
          const processedData = this._accessorEvent.emit(
            this.config.module.identifier,
            'sequence',
            { prev: list, current: ids },
          )

          this._store.dispatch(
            new ListActions.LoadAll('', this._currentIdentifier, {
              data: processedData || this._sequence(list, ids),
            }),
          )

          this._reloadBpConfig()
        }),
      )
  }

  private _sequence(list: any[], relationData: RelationData[]): any[] {
    const pk = this.config.module.primary_key

    return relationData.map(d => {
      const findData = list.find(_d => _d[pk] === d.id)

      return {
        ...findData,
        parent_id: d.parent_id,
      }
    })
  }

  relate(): Observable<any> {
    return null
  }

  private _getOneById(id: number | string) {
    return this._store.pipe(
      select(fromStore.selectDataEntities(this._currentIdentifier)),
      map(entities => entities[id]),
    )
  }

  private _reloadBpConfig() {
    /** Get module & nav config, set into localstorage */
    const moduleData = this._store.pipe(
      select(fromStore.selectAllData(this._storeModuleIdentifier)),
    )
    const navData = this._store.pipe(
      select(fromStore.selectAllData(this._storeNavIdentifier)),
    )

    combineLatest(moduleData, navData)
      .pipe(take(1))
      .subscribe(([module, nav]) => {
        this._configAccessor.set({
          module,
          nav,
        })
        this._bpInitializer.configInitializer()
      })
  }

  private _registerEvent() {
    // Create
    this._accessorEvent.subscribe(
      'dev/module',
      'create',
      ({ prev, current }) => {
        return this._createChild(prev, {
          ...current,
          action: current.action || [],
          field: current.field || [],
        })
      },
    )
    this._accessorEvent.subscribe('action', 'create', ({ prev, current }) => {
      prev.forEach(_d => {
        const lastParentId = this._getLastParentId()

        if (_d.identifier !== lastParentId) return

        _d.action = this._createChild(_d.action, current)
      })

      return prev
    })
    this._accessorEvent.subscribe('field', 'create', ({ prev, current }) => {
      prev.forEach(_d => {
        const lastParentId = this._getLastParentId()

        if (_d.identifier !== lastParentId) return

        _d.field = this._createChild(_d.field, current)
      })

      return prev
    })
    this._accessorEvent.subscribe('dev/nav', 'create', ({ prev, current }) => {
      return this._createChild(prev, { ...current, level: 'user' })
    })

    // delete
    this._accessorEvent.subscribe('action', 'delete', ({ prev, current }) => {
      prev.forEach(_d => {
        const lastParentId = this._getLastParentId()

        if (_d.identifier !== lastParentId) return

        _d.action = _d.action.filter(_a => _a.identifier !== current)
      })

      return prev
    })
    this._accessorEvent.subscribe('field', 'delete', ({ prev, current }) => {
      prev.forEach(_d => {
        const lastParentId = this._getLastParentId()

        if (_d.identifier !== lastParentId) return

        _d.field = _d.field.filter(_a => _a.identifier !== current)
      })

      return prev
    })

    // update
    this._accessorEvent.subscribe(
      'dev/module',
      'updateById',
      ({ prev, current, updateId }) => {
        const index = prev.findIndex(data => data.identifier === updateId)

        prev[index] = { ...prev[index], ...current }

        return prev
      },
    )
    this._accessorEvent.subscribe(
      'dev/nav',
      'updateById',
      ({ prev, current, updateId }) => {
        const index = prev.findIndex(data => data.name === updateId)

        prev[index] = { ...current }

        return prev
      },
    )
    this._accessorEvent.subscribe(
      'action',
      'updateById',
      ({ prev, current, updateId }) => {
        const lastParentId = this._getLastParentId()

        const parentIndex = prev.findIndex(
          data => data.identifier === lastParentId,
        )
        const list = prev[parentIndex].action
        const index = list.findIndex(data => data.identifier === updateId)

        list[index] = current

        return prev
      },
    )
    this._accessorEvent.subscribe(
      'field',
      'updateById',
      ({ prev, current, updateId }) => {
        const lastParentId = this._getLastParentId()

        const parentIndex = prev.findIndex(
          data => data.identifier === lastParentId,
        )
        const list = prev[parentIndex].field
        const index = list.findIndex(data => data.identifier === updateId)

        list[index] = current

        return prev
      },
    )

    // sequence
    this._accessorEvent.subscribe('action', 'sequence', ({ prev, current }) => {
      prev.forEach(_d => {
        const lastParentId = this._getLastParentId()

        if (_d.identifier !== lastParentId) return

        _d.action = this._sequence(_d.action, current)
      })

      return prev
    })
    this._accessorEvent.subscribe('field', 'sequence', ({ prev, current }) => {
      prev.forEach(_d => {
        const lastParentId = this._getLastParentId()

        if (_d.identifier !== lastParentId) return

        _d.field = this._sequence(_d.field, current)
      })

      return prev
    })
  }

  private _createChild(list: any[], data: any) {
    if (!data.parent_id) return [...list, data]

    const pid = data.parent_id
    const pk = this.config.module.primary_key
    const index = list.findIndex(d => d[pk] === pid)
    // add child after all children before
    const childrenLen = list.filter(d => d.parent_id === pid).length
    const offset = 1 + childrenLen

    if (index === -1) return [...list, data]

    return [
      ...list.slice(0, index + offset),
      data,
      ...list.slice(index + offset),
    ]
  }

  private _getLastParentId(): string {
    return this.config.parentIds[this.config.parentIds.length - 1]
  }
}

export function provideDevDataAccessor(isProd): Provider {
  return [
    isProd
      ? {
          provide: DevDataAccessor,
          useValue: null,
        }
      : {
          provide: DevDataAccessor,
          useClass: DevDataAccessor,
        },
  ]
}
