import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'

import { DefaultLayoutComponent } from './layout/default.component'
import { LoginComponent } from './views/login.component'
import { RegisterComponent } from './views/register.component'

const routes: Routes = [
  {
    path: 'auth',
    component: DefaultLayoutComponent,
    children: [
      {
        path: 'login',
        component: LoginComponent,
      },
      {
        path: 'register',
        component: RegisterComponent,
      },
      {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full',
      },
    ],
  },
]

@NgModule({
  declarations: [],
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthRoutingModule {}
