import { Component, OnInit, OnDestroy, Injector } from '@angular/core'
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router'
import { Subscription } from 'rxjs'

@Component({
  selector: 'bp-dev-info',
  template: `
    <a
      nz-popover
      nzPlacement="bottomRight"
      nzTitle="开发信息"
      [nzContent]="contentTemplate"
      *ngIf="routeData.module"
    >
      开发信息
    </a>
    <ng-template #contentTemplate>
      <div>
        <p>
          hook type: hook.{{ routeData.module.identifier }}.{{
            routeData.actionRoute.identifier
          }}.{{ '{' }}location{{ '}' }}
        </p>
      </div>
    </ng-template>
  `,
})
export class BpDevInfoComponent implements OnInit, OnDestroy {
  constructor(private _router: Router, private _injector: Injector) {}

  routeData

  private _subs = new Subscription()

  ngOnInit() {
    this._subs.add(
      this._router.events.subscribe(e => {
        if (e instanceof NavigationEnd) {
          this._getRouteData()
        }
      }),
    )

    this._getRouteData()
  }

  ngOnDestroy() {
    this._subs.unsubscribe()
  }

  private _getRouteData() {
    const snapshopt = this._injector.get(ActivatedRoute).snapshot
    let route = snapshopt

    while (route.firstChild) {
      route = route.firstChild
    }

    this.routeData = route.data
  }
}
