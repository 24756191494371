import {
  Component,
  Input,
  OnInit,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from '@angular/core'
import { CascaderOption } from 'ng-zorro-antd'
import { DistrictService, Level } from '@bp/common'

import { WidgetComponent } from '../../view'
import { RowField } from '../../config'
import { Payload } from '../../interface'

/**
 * can use for: district
 */
@Component({
  selector: 'bp--preset--search-district-widget',
  template: `
    <nz-cascader
      nzChangeOnSelect
      [nzOptions]="districtsData"
      [(ngModel)]="displayLabels"
      (ngModelChange)="onChanges($event)"
      (nzClear)="clear()"
    >
    </nz-cascader>
  `,
})
export class BpSearchDistrictWidget
  extends WidgetComponent implements OnInit, OnChanges {
  static meta = {
    type: 'preset_search',
    name: 'district',
  }

  @Input() value: string[]
  @Input() field: RowField

  change = new EventEmitter<string>()
  dispatch = new EventEmitter<Payload>()
  readonly = false
  districtsData: CascaderOption[]
  districtLevel: Level
  displayLabels: string[] = null

  constructor(private _districtService: DistrictService) {
    super()
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.value) {
      const currentValue = changes.value.currentValue

      if (currentValue) {
        if (this.field.extra) {
          this.districtLevel = this.field.extra as Level
        }
        this.displayLabels = this._districtService.getValues(
          this.districtLevel,
          currentValue,
        )
      } else {
        this.displayLabels = null
      }
    }
  }

  ngOnInit() {
    this.districtLevel = this.field.extra as Level

    this.readonly = this.field.feature.includes('readonly')
    this.districtsData = this._districtService.getCascaderData(
      this.districtLevel,
    )
  }

  onChanges(value: any): void {
    const lastValue = value[value.length - 1] || null

    this.change.emit(lastValue)

    this.dispatch.emit({ type: 'search' })
  }

  clear() {
    this.change.emit(null)

    this.dispatch.emit({ type: 'search' })
  }
}
