import { NgModule } from '@angular/core'
import { CommonModule as AngularCommonModule } from '@angular/common'

import {
  // NzAffixModule,
  // NzAlertModule,
  // NzAnchorModule,
  NzAutocompleteModule,
  NzButtonModule,
  // NzBackTopModule,
  // NzBadgeModule,
  // NzBreadCrumbModule,
  // NzAvatarModule,
  NzCascaderModule,
  // NzCalendarModule,
  // NzCardModule,
  // NzCarouselModule,
  NzCheckboxModule,
  NzNoAnimationModule,
  NzWaveModule,
  // NzCollapseModule,
  // NzCommentModule,
  // NzDrawerModule,
  NzDatePickerModule,
  NzDividerModule,
  NzDropDownModule,
  // NzEmptyModule,
  NzFormModule,
  NzGridModule,
  NzI18nModule,
  NzIconModule,
  NzInputModule,
  NzInputNumberModule,
  // NzLayoutModule,
  // NzListModule,
  // NzMentionModule,
  NzMenuModule,
  NzMessageModule,
  NzModalModule,
  NzNotificationModule,
  // NzPageHeaderModule,
  NzPaginationModule,
  NzPopconfirmModule,
  NzPopoverModule,
  // NzProgressModule,
  NzRadioModule,
  // NzRateModule,
  NzSelectModule,
  // NzSkeletonModule,
  // NzSliderModule,
  NzSpinModule,
  // NzStatisticModule,
  // NzStepsModule,
  NzSwitchModule,
  NzTableModule,
  NzTabsModule,
  NzTagModule,
  NzTimePickerModule,
  // NzTimelineModule,
  NzToolTipModule,
  // NzTransferModule,
  NzTreeModule,
  // NzTreeSelectModule,
  NzUploadModule,
  NzDescriptionsModule,
} from 'ng-zorro-antd'

import { PIPES } from './pipes'
import { COMPONENTS } from './components'

const ANTD_MODULES = [
  NzAutocompleteModule,
  NzButtonModule,
  NzCascaderModule,
  NzCheckboxModule,
  NzNoAnimationModule,
  NzWaveModule,
  NzDatePickerModule,
  NzDividerModule,
  NzDropDownModule,
  NzFormModule,
  NzI18nModule,
  NzIconModule,
  NzInputModule,
  NzInputNumberModule,
  NzGridModule,
  NzMenuModule,
  NzMessageModule,
  NzModalModule,
  NzNotificationModule,
  NzPaginationModule,
  NzPopconfirmModule,
  NzPopoverModule,
  NzRadioModule,
  NzSelectModule,
  NzSpinModule,
  NzSwitchModule,
  NzTableModule,
  NzTabsModule,
  NzTagModule,
  NzTimePickerModule,
  NzToolTipModule,
  NzTreeModule,
  NzUploadModule,
  NzDescriptionsModule,
]

@NgModule({
  declarations: [PIPES, COMPONENTS],
  imports: [AngularCommonModule, ANTD_MODULES],
  exports: [PIPES, COMPONENTS, ANTD_MODULES],
})
export class CommonModule {
  constructor() {}
}
