import {
  Component,
  Input,
  OnInit,
  EventEmitter,
  OnDestroy,
  OnChanges,
  SimpleChanges,
} from '@angular/core'
import { BehaviorSubject, Subscription } from 'rxjs'
import { debounceTime, filter, switchMap } from 'rxjs/operators'

import { WidgetComponent } from '../../view'
import { RowField } from '../../config'
import { DataAccessorService } from '../../init_accessor'

/**
 * can use for: cascader
 */
@Component({
  selector: 'bp--preset--form-cascader-widget',
  template: `
    TODO
  `,
})
export class BpFormCascaderWidget extends WidgetComponent
  implements OnInit, OnDestroy, OnChanges {
  static meta = {
    type: 'preset_form',
    name: 'cascader',
  }

  @Input() entity: any
  @Input() value: string
  @Input() field: RowField

  change = new EventEmitter<string>()
  options: {
    [props: string]: string
  }[] = []
  haveTemplate = false
  loading = false
  inited = false

  searchChange$ = new BehaviorSubject('')

  private _subs = new Subscription()

  constructor(private _dataAccessorService: DataAccessorService) {
    super()
  }

  // Get option list when init
  ngOnChanges(changes: SimpleChanges) {}

  ngOnInit() {
    this.haveTemplate = !!this.field.form.template
  }

  ngOnDestroy() {
    this._subs.unsubscribe()
  }

  valueChange(e: any) {
    this.change.emit(e)
  }
}
