import { NgModule, Provider, InjectionToken } from '@angular/core'
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'
import { ApolloModule, APOLLO_OPTIONS } from 'apollo-angular'
import { HttpLinkModule, HttpLink } from 'apollo-angular-link-http'
import { InMemoryCache } from 'apollo-cache-inmemory'

import { DataAccessor } from './data_accessor_interface'
import { GraphqlDataAccessor } from './graphql_data_accessor'
import { HttpDataAccessor } from './http_data_accessor'
import { BaseURLInterceptor } from './base-url-interceptor.service'

export type HttpOptions = {
  type: 'graphql' | 'http'
  baseURL: string
}

export const HTTP_OPTIONS = new InjectionToken<HttpOptions>('Bp Http Options')

export function apolloFactory(httpLink: HttpLink, options: HttpOptions) {
  return {
    link: httpLink.create({ uri: options.baseURL }),
    cache: new InMemoryCache(),
  }
}

export function interceptorFactory(options: HttpOptions) {
  return new BaseURLInterceptor(options.baseURL)
}

export function provideHttp(options: HttpOptions): Provider {
  return [
    {
      provide: HTTP_OPTIONS,
      useValue: options,
    },
    {
      provide: DataAccessor,
      useClass:
        options.type === 'graphql' ? GraphqlDataAccessor : HttpDataAccessor,
    },
    options.type === 'graphql'
      ? {
          provide: APOLLO_OPTIONS,
          useFactory: apolloFactory,
          deps: [HttpLink, HTTP_OPTIONS],
        }
      : {
          provide: HTTP_INTERCEPTORS,
          useFactory: interceptorFactory,
          deps: [HTTP_OPTIONS],
          multi: true,
        },
  ]
}

@NgModule({
  imports: [HttpClientModule, ApolloModule, HttpLinkModule],
})
export class HttpModule {}
