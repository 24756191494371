import { Params, RouterStateSnapshot } from '@angular/router'
import {
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
} from '@ngrx/store'
import * as fromRouter from '@ngrx/router-store'

export interface RouterState {
  url: string
  params: Params
  queryParams: Params
  data?: any
}

export class CustomSerializer
  implements fromRouter.RouterStateSerializer<RouterState> {
  serialize(routerState: RouterStateSnapshot): RouterState {
    let route = routerState.root

    while (route.firstChild) {
      route = route.firstChild
    }

    const {
      url,
      root: { queryParams },
    } = routerState
    const { params, data } = route

    // Only return an object including the URL, params and query params
    // instead of the entire snapshot
    return { url, params, queryParams, data }
  }
}

export interface State {
  router: fromRouter.RouterReducerState<RouterState>
}

export const reducers: ActionReducerMap<State> = {
  router: fromRouter.routerReducer,
}

export const selectRouter = createFeatureSelector<
  fromRouter.RouterReducerState<RouterState>
>('router')

export const selectRouterState = createSelector(
  selectRouter,
  currentRouter => currentRouter.state,
)
