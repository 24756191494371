import { Injectable, Inject } from '@angular/core'
import { Effect, Actions, ofType } from '@ngrx/effects'
import { of, throwError } from 'rxjs'
import { tap, catchError, map, switchMap } from 'rxjs/operators'
import { AclAccessor, BP_ACL_ACCESSOR, AclService } from '@bp/core'
import { LanguageService, RedirectService } from '~/core/services'
import { environment } from 'src/environments/environment'
import { UploadConfigService, UserService } from '@bp/common'

import * as authAction from './actions'
import { AuthHttpService } from '../http'
import { TokenService } from '../services'
import { SharedHttpService } from '~/shared/http/shared-http.service'

@Injectable()
export class AuthEffect {
  constructor(
    private actions$: Actions,
    private _authHttp: AuthHttpService,
    private _tokenService: TokenService,
    private _redirectService: RedirectService,
    @Inject(BP_ACL_ACCESSOR) private _aclAccessor: AclAccessor,
    private _userService: UserService,
    private _aclService: AclService,
    private _uploadConfigService: UploadConfigService,
    private _languageService: LanguageService,
    private _sharedHttp: SharedHttpService,
  ) {}

  @Effect()
  authenticate$ = this.actions$.pipe(
    ofType<authAction.Authenticate>(authAction.AUTHENTICATE),
    switchMap(() => this._authHttp.session()),
    map(user => new authAction.AuthSuccess({ user })),
    catchError(err => {
      console.error(err)
      return of(new authAction.AuthFailure(err))
    }),
  )

  @Effect()
  authSuccess$ = this.actions$.pipe(
    ofType<authAction.AuthSuccess>(authAction.AUTH_SUCCESS),
    tap(({ payload: { user } }) => {
      this._userService.set(user)
      this._redirectService.redirect()
      this._aclAccessor.set(
        user.acl
          ? user.acl.map(p => p.split('.'))
          : environment.production
          ? [[]]
          : [['*']],
      )
    }),
    map(() => new authAction.GetConfig()),
  )

  @Effect({ dispatch: false })
  getConfig$ = this.actions$.pipe(
    ofType<authAction.GetConfig>(authAction.GET_CONFIG),
    tap(() => {
      this._sharedHttp
        .config()
        .subscribe(res => {
          this._uploadConfigService.set(res.data.file)
          this._languageService.set(res.data.languages)
        })
      // TODO: payments config
    }),
  )

  @Effect({ dispatch: false })
  authFailure$ = this.actions$.pipe(
    ofType<authAction.AuthFailure>(authAction.AUTH_FAILURE),
    tap(() => {
      this._redirectService.redirectToLogin(true)
      this._tokenService.remove()
    }),
  )

  @Effect({ dispatch: false })
  logout$ = this.actions$.pipe(
    ofType<authAction.Logout>(authAction.LOGOUT),
    tap(() => {
      this._authHttp.logout().subscribe()
      this._tokenService.remove()
      this._aclService.clearCache()
      this._redirectService.redirectToLogin()
    }),
    catchError(err => {
      console.error(err)
      return of()
    }),
  )
}
