import {
  Component, Input, OnInit, EventEmitter, OnChanges, SimpleChanges } from '@angular/core'
import { UploadFile, UploadXHRArgs } from 'ng-zorro-antd'
import { of } from 'rxjs'
import { UploadConfigService } from '@bp/common'

import { FormGroup, FormControl } from '@angular/forms'
import { WidgetComponent } from '../../view'
import { RowField } from '../../config'

/**
 * can use for: image
 */
@Component({
  selector: 'bp--preset--form-file-widget',
  template: `
    <nz-upload
      nzListType="text"
      [(nzFileList)]="fileList"
      [nzShowButton]="limit ? fileList.length < limit : true"
      [nzMultiple]="limit == 1 ? false : true"
      [nzShowUploadList]="showUploadList"
      [nzDisabled]="readonly"
      [nzCustomRequest]="customRequest"
      [nzRemove]="remove"
      nzAccept="*"
    >
      <button nz-button><i nz-icon nzType="upload" style="font-size: 14px; color: rgba(0,0,0,.65);"></i><span>上传</span></button>
    </nz-upload>
  `,
  styles: [
    `
      i[nz-icon] {
        font-size: 32px;
        color: #999;
      }
      .ant-upload-text {
        margin-top: 8px;
        color: #666;
      }
    `,
  ],
})
export class BpFormFileWidget extends WidgetComponent implements OnInit, OnChanges {
  static meta = {
    type: 'preset_form',
    name: 'file',
  }

  @Input() entity: any
  @Input() value: string | any[]
  @Input() formGroup: FormGroup
  @Input() field: RowField

  change = new EventEmitter<string | string[]>()
  readonly = false
  limit = 0
  uid = 0
  showUploadList = {
    showPreviewIcon: true,
    showRemoveIcon: true,
    hidePreviewIconInNonImage: true,
  }
  fileIdsIdentifier = '_file_ids'
  fileIdsControl: FormControl
  fileList = []
  FormList = []

  constructor(
    private _uploadConfigService: UploadConfigService,
  ) {
    super()
    this.fileIdsControl = new FormControl([])
  }

  customRequest = (item: UploadXHRArgs) => {
    const prefix = this._uploadConfigService.get().img_prefix

    return this._uploadConfigService.getService().uploadFile(item.file as any).subscribe(res => {
      if (res.status === 'completed') {
        this.FormList.push({
          id: res.data.id,
          uid: item.file.uid,
          url: prefix + res.data.path,
        })
        this.change.emit(this.limit == 1 ? this.FormList[0].url : this.FormList.map(f => f.url))
        this.formGroup.get(this.fileIdsIdentifier).patchValue(this.limit == 1 ? this.FormList[0].id : this.FormList.map(f => f.id))
        item.onSuccess({}, item.file, res)
      }
    })
  }

  remove = (file: UploadFile) => {
    if (file.uid) {
      this.FormList = this.FormList.filter(e => e.uid !== file.uid)
    }
    if (this.limit == 1) {
      this.change.emit(this.FormList.length ? this.FormList[0].url : '')
      this.formGroup.get(this.fileIdsIdentifier).patchValue(this.FormList.length ? this.FormList[0].id : 0)
    } else {
      this.change.emit(this.FormList.map(f => f.url))
      this.formGroup.get(this.fileIdsIdentifier).patchValue(this.FormList.map(f => f.id))
    }
    return true
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.value) {
      let currentValue = changes.value.currentValue

      if (!currentValue) return
      this.loadData(currentValue)
    }
  }

  ngOnInit() {
    this.readonly = this.field.feature.includes('readonly')
    this.limit = this.field.extra
    this.fileIdsIdentifier = this.field.identifier + this.fileIdsIdentifier
    if (this.formGroup) {
      this.formGroup.registerControl(this.fileIdsIdentifier, this.fileIdsControl)
    }

    if (!this.value) return
    this.loadData(this.value)
  }

  loadData(data) {
    if (Array.isArray(data)) {
      data.forEach(item => {
        if (!this.FormList.some(f => f.id === item.id)) {
          this.fileList = [...this.fileList, {
            uid: --this.uid,
            status: 'done',
            name: item.name,
            url: item.path,
          }]
          this.FormList.push({
            id: item.id,
            uid: this.uid,
            url: item.path,
          })
        }
      })
      this.fileIdsControl.patchValue(this.FormList.map(f => f.id))
    } else {
      if (this.uid <= -1) {
        return
      }
      this.fileList = [...this.fileList, {
        uid: --this.uid,
        status: 'done',
        name: data.split('/').pop(),
        url: data,
      }]
      this.FormList.push({
        id: 0,
        uid: this.uid,
        url: data,
      })
    }
  }
}
