import {
  Component,
  Input,
  OnInit,
  EventEmitter,
  ViewChild,
  ElementRef,
} from '@angular/core'

import { WidgetComponent } from '../../view'
import { RowField } from '../../config'

/**
 * can use for: tag
 */
@Component({
  selector: 'bp--preset--form-tag-widget',
  template: `
    <ng-template ngFor [ngForOf]="tagValue" let-tag let-i="index">
      <nz-tag
        *ngIf="editIndex !== i"
        [nzMode]="'closeable'"
        (dblclick)="editTag(i, tag)"
        (nzAfterClose)="handleClose(tag)"
      >
        {{ tag | slice }}
      </nz-tag>
      <input
        nz-input
        nzSize="small"
        *ngIf="editIndex === i"
        type="text"
        [(ngModel)]="editValue"
        style="width: 78px; margin-right: 5px;"
        (blur)="cancelEdit()"
        (keydown.enter)="confirmEdit()"
      />
    </ng-template>
    <nz-tag *ngIf="!inputVisible" class="editable-tag" (click)="showInput()">
      <i nz-icon nzType="plus" nzTheme="outline"></i>新增
    </nz-tag>
    <input
      #inputElement
      nz-input
      nzSize="small"
      *ngIf="inputVisible"
      type="text"
      [(ngModel)]="inputValue"
      style="width: 78px;"
      (blur)="handleInputConfirm()"
      (keydown.enter)="handleInputConfirm()"
    />
  `,
  styles: [
    `
      .editable-tag {
        background: rgb(255, 255, 255);
        border-style: dashed;
      }
    `,
  ],
})
export class BpFormTagWidget extends WidgetComponent implements OnInit {
  static meta = {
    type: 'preset_form',
    name: 'tag',
  }
  @ViewChild('inputElement', { static: false }) inputElement: ElementRef
  @Input() entity: any
  @Input() value: string[]
  @Input() field: RowField

  inputVisible = false
  inputValue = ''
  editIndex = -1
  editValue = ''

  get tagValue() {
    return this.value || []
  }
  set tagValue(value: string[]) {
    if (this.readonly) return
    this.change.emit(value)
  }

  change = new EventEmitter<string[]>()
  readonly = false

  ngOnInit() {
    this.readonly = this.field.feature.includes('readonly')
  }

  handleClose(removedTag: string): void {
    this.tagValue = this.tagValue.filter(tag => tag !== removedTag)
  }

  showInput() {
    this.inputVisible = true
    setTimeout(() => {
      this.inputElement.nativeElement.focus()
    }, 10)
  }

  handleInputConfirm(): void {
    if (this.inputValue && this.tagValue.indexOf(this.inputValue) === -1) {
      this.tagValue = [...this.tagValue, this.inputValue]
    }
    this.inputValue = ''
    this.inputVisible = false
  }

  editTag(index: number, value: string) {
    this.editIndex = index
    this.editValue = value
    this.inputVisible = false
  }

  cancelEdit() {
    this.editIndex = -1
    this.editValue = ''
  }

  confirmEdit() {
    this.tagValue[this.editIndex] = this.editValue
    this.cancelEdit()
  }
}
