import {
  createSelector,
  createFeatureSelector,
  ActionReducerMap,
} from '@ngrx/store'

import * as fromList from './list_reducer'

export interface State {
  list: fromList.State
}

export const reducers: ActionReducerMap<State> = {
  list: fromList.reducer,
}

export const selectFeature = createFeatureSelector<any, State>('blueprint')

export const selectListState = createSelector(
  selectFeature,
  state => state.list,
)

export const selectDataIds = (identifier: string) =>
  createSelector(
    selectListState,
    state => fromList.selectDataIds(state, identifier),
  )

export const selectDataEntities = (identifier: string) =>
  createSelector(
    selectListState,
    state => fromList.selectDataEntities(state, identifier),
  )

export const selectAllData = (identifier: string) =>
  createSelector(
    selectListState,
    fromList.selectAllData(identifier),
  )

export const selectDataTotal = (identifier: string) =>
  createSelector(
    selectListState,
    state => fromList.selectDataTotal(state, identifier),
  )
