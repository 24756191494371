import { Action } from '@ngrx/store'

import { User } from '../models'

export const AUTHENTICATE = '[Auth] Authenticate'
export const AUTH_SUCCESS = '[Auth] Success'
export const AUTH_FAILURE = '[Auth] Failure'
export const LOGOUT = '[Auth] Logout'
export const LOGIN_REDIRECT = '[Auth] Login Redirect'
export const SET_REDIRECT_URL = '[Auth] Set Redirect URL'
export const UPDATE_USER = '[Auth] Update User'
export const SET_LOADING = '[Auth] Set Loading'
export const GET_CONFIG = '[Auth] Get config'

export class Authenticate implements Action {
  readonly type = AUTHENTICATE
}

export class AuthSuccess implements Action {
  readonly type = AUTH_SUCCESS

  constructor(public payload: { user: User }) {}
}

export class AuthFailure implements Action {
  readonly type = AUTH_FAILURE

  constructor(public payload: any) {}
}

export class Logout implements Action {
  readonly type = LOGOUT
}

export class SetRedirectURL implements Action {
  readonly type = SET_REDIRECT_URL

  constructor(public payload: { url: string }) {}
}

export class UpdateUser implements Action {
  readonly type = UPDATE_USER

  constructor(public payload: User) {}
}

export class GetConfig implements Action {
  readonly type = GET_CONFIG

  constructor() {}
}

export type Actions =
  | AuthSuccess
  | AuthFailure
  | Logout
  | SetRedirectURL
  | UpdateUser
  | GetConfig
