import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { format } from 'date-fns'
import { HttpEvent, HttpEventType } from '@angular/common/http'

import { UploadHttpService, SaveFileSuccess } from './upload_http_service'

@Injectable({
  providedIn: 'root',
})
export class DirectService {
  constructor(
    private uploadHttpService: UploadHttpService,
  ) {}
  base64: string;
  private config: any

  setConfig(config) {
    this.config = config
  }

  uploadFile(file: File): Observable<OssResponse> {
    return new Observable(observer => {
      this.uploadHttpService.uploadFileViaApi(this.config.endpoint, file).subscribe(res => {
        observer.next({
          status: 'completed',
          data: res.data,
        })
        observer.complete()
      })
    })
  }

  getRandom(min, max) {
    return Math.random() * (max - min) + min
  }

  readAsBase64(file, cb) {
    const fileReader = new FileReader();
    const filter = /^(?:image\/bmp|image\/cis\-cod|image\/gif|image\/ief|image\/jpeg|image\/jpeg|image\/jpeg|image\/pipeg|image\/png|image\/svg\+xml|image\/tiff|image\/x\-cmu\-raster|image\/x\-cmx|image\/x\-icon|image\/x\-portable\-anymap|image\/x\-portable\-bitmap|image\/x\-portable\-graymap|image\/x\-portable\-pixmap|image\/x\-rgb|image\/x\-xbitmap|image\/x\-xpixmap|image\/x\-xwindowdump)$/i;

    if (filter.test(file.type)) {
      fileReader.onload = (e) => {
        this.base64 = (<any>e.target).result;
        cb();
      };
      fileReader.readAsDataURL(file);
    }
  }
}

export type OssResponse = {
  status: 'progress' | 'completed' | 'error'
  data: SaveFileSuccess
}
