import {
  NgModule,
  ModuleWithProviders,
  Type,
  Provider,
  Inject,
  ANALYZE_FOR_ENTRY_COMPONENTS,
} from '@angular/core'
import { HttpClientModule } from '@angular/common/http'
import { CommonModule } from '@angular/common'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { RouterModule } from '@angular/router'
import { DragDropModule } from '@angular/cdk/drag-drop'
import { StoreModule } from '@ngrx/store'
import { CommonModule as BpCommonModule } from '@bp/common'
import { HttpModule as BpHttpModule, provideHttp } from '@bp/http'

import { provideBpInitializer, DYNAMIC_COMPONENTS } from './bp_initializer'
import {
  VIEW_DIRECTIVES,
  provideLoader,
  WIDGET_LOADER,
  DynamicComponentLoader,
} from './view'
import {
  PRESET_PAGES,
  PIPES,
  LoadPresetModule,
  providePresetPagesMap,
} from './preset'
import { provideAclAccessor, ACL_DIRECTIVES } from './acl'
import { reducers } from './store'
import { provideIdentifier } from './store/provide'
import { ModuleConfig, LoadConfig } from './interface'
import { provideDevDataAccessor } from './dev_data_accessor'

const EXPORT_DECLARATIONS = [VIEW_DIRECTIVES, ACL_DIRECTIVES, PIPES]

export function provideDynamicComponents(components: Type<any>[]): Provider {
  return [
    {
      provide: ANALYZE_FOR_ENTRY_COMPONENTS,
      useValue: components,
      multi: true,
    },
    {
      provide: DYNAMIC_COMPONENTS,
      useValue: components,
      multi: true,
    },
  ]
}

function initialDynamicComponents(
  dynamicComps: Type<any>[][],
  widgetLoader: DynamicComponentLoader,
) {
  setTimeout(() => {
    const comps: Type<any>[] = []
    flat(comps, dynamicComps)
    widgetLoader.loadComponents(comps)

    // Clear comps
    dynamicComps.splice(0, dynamicComps.length)
  })
}

function flat<T>(output: T[], arr: any) {
  if (Array.isArray(arr)) {
    arr.forEach(_arr => flat(output, _arr))
  } else {
    if (!!arr) output.push(arr)
  }
}

@NgModule({
  declarations: EXPORT_DECLARATIONS,
  exports: EXPORT_DECLARATIONS,
})
export class BlueprintDeclarationModule {}

// load dynamic components
@NgModule()
export class BlueprintFeatureModule {
  constructor(
    @Inject(DYNAMIC_COMPONENTS) dynamicComps: Type<any>[][],
    @Inject(WIDGET_LOADER) widgetLoader: DynamicComponentLoader,
  ) {
    initialDynamicComponents(dynamicComps, widgetLoader)
  }
}

@NgModule({
  declarations: [PRESET_PAGES],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    HttpClientModule,
    StoreModule.forFeature('blueprint', reducers),
    DragDropModule,
    BpCommonModule,
    BlueprintDeclarationModule,
    BpHttpModule,
    LoadPresetModule,
  ],
  entryComponents: [PRESET_PAGES],
})
export class BlueprintModule {
  constructor(
    @Inject(DYNAMIC_COMPONENTS) dynamicComps: Type<any>[][],
    @Inject(WIDGET_LOADER) widgetLoader: DynamicComponentLoader,
  ) {
    initialDynamicComponents(dynamicComps, widgetLoader)
  }

  static forRoot(
    config: ModuleConfig & LoadConfig,
  ): ModuleWithProviders<BlueprintModule> {
    return {
      ngModule: BlueprintModule,
      providers: [
        provideLoader(),
        provideBpInitializer(config),
        provideDevDataAccessor(config.production),
        provideAclAccessor(),
        provideDynamicComponents(config.components),
        provideIdentifier(),
        providePresetPagesMap(),
        provideHttp(config.http),
      ],
    }
  }

  static load(config: LoadConfig): ModuleWithProviders<BlueprintFeatureModule> {
    return {
      ngModule: BlueprintFeatureModule,
      providers: [provideDynamicComponents(config.components)],
    }
  }
}
