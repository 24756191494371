import * as AuthAction from './actions'
import { User } from '../models'

export interface State {
  loggedIn: boolean
  user: User
}

export const initialState: State = {
  loggedIn: false,
  user: null,
}

export function reducer(
  state = initialState,
  action: AuthAction.Actions,
): State {
  switch (action.type) {
    case AuthAction.AUTH_SUCCESS: {
      return {
        ...state,
        loggedIn: true,
        user: action.payload.user,
      }
    }
    case AuthAction.UPDATE_USER: {
      return {
        ...state,
        user: action.payload,
      }
    }
    case AuthAction.LOGOUT: {
      return initialState
    }
    default: {
      return state
    }
  }
}

export const getLoggedIn = (state: State) => state.loggedIn
export const getUser = (state: State) => state.user
