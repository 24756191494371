import { Pipe, PipeTransform } from '@angular/core'

function transform(value: any, map: any): any {
  // template type's map
  if (!map || typeof map !== 'object') return value

  return map[value] || value
}

@Pipe({
  name: 'map',
})
export class MapPipe implements PipeTransform {
  static transform = transform
  transform = transform
}
