import { InjectionToken, Provider, Inject } from '@angular/core'
import { HttpClient, HttpBackend } from '@angular/common/http'
import { Observable, of, combineLatest, ReplaySubject } from 'rxjs'
import { map, catchError, mergeMap } from 'rxjs/operators'
import { stringify, parse } from 'zipson'

import { BpConfigRow, RowModule, RowNav } from './model'
import {
  BP_PRESET_DEV_MODULE,
  presetDevModule,
  presetDevNav,
  BP_PRESET_DEV_NAV,
} from './preset_dev_config'
import {
  BP_PRESET_PROD_MODULE,
  presetProdModule,
  BP_PRESET_PROD_NAV,
  presetProdNav,
} from './preset_prod_config'

const BP_DEV_CONFIG_TOKEN = '__bp_dev_config__' + process.env.npm_package_name

export interface ConfigAccessor {
  get(): Observable<BpConfigRow>
  set(config: BpConfigRow): Observable<BpConfigRow>
  detect(): Observable<number>
}

export const BP_CONFIG_ACCESSOR = new InjectionToken<ConfigAccessor>(
  'Config Accessor',
)
export const BP_PROD_CONFIG_URL = new InjectionToken<string>(
  'Production Config URL',
)

export class DevConfigAccessor implements ConfigAccessor {
  private _prodAccessor = new ProdConfigAccessor(
    this._handle,
    this._configURL,
    this._presetProdModule,
    this._presetProdNav,
  )
  private _pulse = new ReplaySubject(1)

  constructor(
    @Inject(BP_PRESET_DEV_MODULE) private _presetModule: RowModule[],
    @Inject(BP_PRESET_DEV_NAV) private _presetNav: RowNav[],
    @Inject(BP_PROD_CONFIG_URL) private _configURL: string,
    @Inject(BP_PRESET_PROD_MODULE) private _presetProdModule: RowModule[],
    @Inject(BP_PRESET_PROD_NAV) private _presetProdNav: RowNav[],
    private _handle: HttpBackend,
  ) {
    this._pulse.next(true)
  }

  getStr(): Observable<string> {
    return of(localStorage.getItem(BP_DEV_CONFIG_TOKEN))
  }

  get(): Observable<BpConfigRow> {
    const configStr = localStorage.getItem(BP_DEV_CONFIG_TOKEN)

    if (!configStr) {
      return this._prodAccessor.get().pipe(
        mergeMap(prodConfig => this.set(prodConfig)),
        map(config => ({
          ...config,
          module: [...this._presetModule, ...config.module],
          nav: [...this._presetNav, ...config.nav],
        })),
      )
    } else {
      const decompressedConfig: BpConfigRow = parse(configStr)

      return of({
        ...decompressedConfig,
        module: [
          ...this._presetModule,
          ...this._presetProdModule,
          ...decompressedConfig.module,
        ],
        nav: [
          ...this._presetNav,
          ...decompressedConfig.nav,
          ...this._presetProdNav,
        ],
      })
    }
  }

  set(config: BpConfigRow): Observable<BpConfigRow> {
    const moduleExcludeSystem = config.module.filter(
      m => m.level !== 'system' && !m._preset,
    )
    const navExcludeSystem = config.nav.filter(
      n => n.level !== 'system' && !n._preset,
    )
    const _config: BpConfigRow = {
      version: config.version || Date.now(),
      module: moduleExcludeSystem,
      nav: navExcludeSystem,
    }
    const compressedConfig = stringify(_config)

    localStorage.setItem(BP_DEV_CONFIG_TOKEN, compressedConfig)

    this._pulse.next(true)

    return of(_config)
  }

  applyRemoteConfig(): Observable<BpConfigRow> {
    return this._prodAccessor
      .get()
      .pipe(mergeMap(prodConfig => this.set(prodConfig)))
  }

  detect(): Observable<number> {
    return this._pulse.pipe(
      mergeMap(() =>
        combineLatest(this.get(), this._prodAccessor.get()).pipe(
          map(([devConfig, prodConfig]) => {
            const devVer = devConfig.version
            const prodVer = prodConfig.version

            if (devVer > prodVer) {
              // localstorage newest
              return 1
            } else if (devVer < prodVer) {
              // remote newest
              return 2
            } else {
              // same
              return 0
            }
          }),
        ),
      ),
    )
  }
}

export class ProdConfigAccessor implements ConfigAccessor {
  private _http: HttpClient
  private _defaultConfig = {
    version: 1,
    module: [],
    nav: [],
  }

  constructor(
    private _handle: HttpBackend,
    @Inject(BP_PROD_CONFIG_URL) private _configURL: string,
    @Inject(BP_PRESET_PROD_MODULE) private _presetModule: RowModule[],
    @Inject(BP_PRESET_PROD_NAV) private _presetNav: RowNav[],
  ) {
    this._http = new HttpClient(_handle)
  }

  get(): Observable<BpConfigRow> {
    return this._http.get(this._configURL, { responseType: 'text' }).pipe(
      map(compressedData => {
        const data = parse(compressedData.trim())

        return {
          ...data,
          module: [...this._presetModule, ...data.module],
          nav: [...data.nav, ...this._presetNav],
        }
      }),
      catchError(err => of(this._defaultConfig)),
    )
  }

  set(): Observable<BpConfigRow> {
    return of(null)
  }

  detect(): Observable<number> {
    return of(0)
  }
}

export function provideConfigAccessor(prodConfigURL, isProd): Provider {
  return [
    {
      provide: BP_PRESET_DEV_MODULE,
      useValue: isProd ? [] : presetDevModule,
    },
    {
      provide: BP_PRESET_DEV_NAV,
      useValue: isProd ? [] : presetDevNav,
    },
    {
      provide: BP_PRESET_PROD_MODULE,
      useValue: presetProdModule,
    },
    {
      provide: BP_PRESET_PROD_NAV,
      useValue: presetProdNav,
    },
    {
      provide: BP_PROD_CONFIG_URL,
      useValue: prodConfigURL,
    },
    {
      provide: BP_CONFIG_ACCESSOR,
      useClass: isProd ? ProdConfigAccessor : DevConfigAccessor,
    },
  ]
}
