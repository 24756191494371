import { EventEmitter } from '@angular/core'
import { Observable } from 'rxjs'
import { RowField, Module } from '@bp/core'
import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router'

export type Config = {
  baseURL: string
  module: Module
  parentIds: string[]
  fields?: RowField[]
}

export type ListData<T> = {
  data: T[]
  meta: {
    count: number
    limit: number
    offset: number
    total: number
  }
}

export type RelationData = {
  id: string | number
  parent_id?: string | number
}

export type RelateInfo = {
  type: 'advise' | 'cascader'
  // Search keywords of items, use search label, key is use search value
  keyword?: string
  // Get specific value of item for relation, cascader, get data when edit
  key?: string
  field: string
  limit?: number
  offset?: number
  related_id?: number
}

export abstract class DataAccessor {
  protected config: Config = null
  protected url: string
  protected afterInited = new EventEmitter()

  constructor(private _route: ActivatedRoute) {}

  init(config: Config) {
    this.config = config
    this.url = config.baseURL

    this.afterInited.emit()
  }

  // Use DataAccessor standalone
  clone(config: Config): DataAccessor {
    const obj: DataAccessor = Object.create(this)

    obj.init(config)

    return obj
  }

  // Related id for relate
  protected _getLatestSegmentId(): string {
    let currentRoute: ActivatedRouteSnapshot = this._route.snapshot

    while (currentRoute.firstChild) {
      currentRoute = currentRoute.firstChild
    }

    const urlSegments = currentRoute.url
    // List page module's previous module, so minus 2
    const latestSegment = urlSegments[urlSegments.length - 2]
    const segmentParamsId =
      latestSegment && latestSegment.parameters && latestSegment.parameters.id

    return segmentParamsId
  }

  abstract getOne<T = any>(): Observable<T>

  abstract getOneById<T = any>(id: number | string): Observable<T>

  // fields used in graphql accessor
  abstract getList<T = any>(prams?: any, fields?: any): Observable<ListData<T>>

  abstract create(data: any): Observable<any>

  abstract update<T = any>(data: any): Observable<any>

  abstract updateById<T = any>(id: number | string, data: any): Observable<any>

  abstract delete(): Observable<any>

  abstract deleteById(id: number | string): Observable<any>

  abstract batch(
    action: string,
    keys: number[] | string[],
    params: any,
  ): Observable<any>

  abstract sequence(ids: RelationData[]): Observable<any>

  /**
   * Get related data of keyword
   */
  abstract relate<T = any>(data: RelateInfo): Observable<T>
}
