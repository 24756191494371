import { Injectable } from '@angular/core'
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http'

import { Observable, throwError } from 'rxjs'
import { catchError } from 'rxjs/operators'

import { NzMessageService } from 'ng-zorro-antd'

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private messageService: NzMessageService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((event: HttpEvent<any>) => {
        this.errorMessage(event)
        return throwError(event)
      }),
    )
  }

  errorMessage(err) {
    let res = err.error

    if (typeof res !== 'object') {
      try {
        res = JSON.parse(res)
      } catch (e) {}
    }

    if (err.status === 422) {
      if (res.data) {
        const errors = res.data

        errors.forEach(error => this.messageService.error(error.content))
      } else {
        const error = res.message

        this.messageService.error(error)
      }
    } else if (res) {
      this.messageService.error(res.message)
    } else {
      this.messageService.error('未知错误，请重试')
    }
  }
}
