import { BpAcl } from './acl_directive'
import { BpAclRoleManage } from './components/role_manage'

export * from './acl_guard'
export * from './acl_accessor'
export * from './acl_service'

export const ACL_DIRECTIVES = [BpAcl]

export const ACL_WIDGETS = [BpAclRoleManage]
