import { Component, forwardRef, Input, OnInit } from '@angular/core'
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms'

@Component({
  selector: 'dev-type-select',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TypeSelectComponent),
      multi: true,
    },
  ],
  template: `
    <nz-select
      nzShowSearch
      [(nzOpen)]="selectOpen"
      [ngModel]="model"
      (ngModelChange)="onChange($event)"
      [nzDropdownRender]="render"
    >
      <nz-option
        *ngIf="customOption"
        [nzValue]="customOption"
        [nzLabel]="'自定义：' + customOption"
      ></nz-option>
      <nz-option
        *ngFor="let item of optionArr"
        [nzValue]="item[0]"
        [nzLabel]="item[1]"
      ></nz-option>
    </nz-select>
    <ng-template #render>
      <nz-divider style="margin: 4px 0;"></nz-divider>
      <div style="padding: 0 4px 4px;">
        <input
          nz-input
          placeholder="自定义组件名"
          [(ngModel)]="inputVal"
          (keydown.enter)="confirmCustomName($event)"
        />
      </div>
    </ng-template>
  `,
})
export class TypeSelectComponent implements OnInit, ControlValueAccessor {
  @Input() options: object

  model = ''
  inputVal = ''
  optionArr: string[][] = []
  customOption = ''
  selectOpen = false

  onChange: (value: string) => void = () => null
  onTouched: () => void = () => null

  constructor() {}

  ngOnInit() {
    if (!this.options) return

    this.optionArr = Object.entries(this.options).map(([key, val]) => [
      key,
      val,
    ])
  }

  // ControlValueAccessor
  writeValue(value: string) {
    this.model = value

    if (!Object.keys(this.options).includes(value)) {
      this.customOption = value
    }
  }
  setDisabledState(isDisabled: boolean) {}
  registerOnChange(fn: (value: any | any[]) => void) {
    this.onChange = fn
  }
  registerOnTouched(fn: () => void) {
    this.onTouched = fn
  }

  confirmCustomName() {
    this.selectOpen = false
    this.customOption = this.inputVal

    this.model = this.inputVal
    this.onChange(this.inputVal)

    this.inputVal = ''
  }
}
