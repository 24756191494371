import { MapPipe } from './map_pipe'
import { TemplatePipe } from './template_pipe'
import { SafeHTMLPipe } from './safe_html_pipe'
import { SafeURLPipe } from './safe_url_pipe'
import { DeepPipe } from './deep_pipe'
import { DistrictPipe } from './district_pipe'
import { SlicePipe } from './slice_pipe'
import { HumanTimePipe } from './human_time_pipe'
import { ImageCropPipe } from './crop.pipe'

export const PIPES = [
  MapPipe,
  TemplatePipe,
  SafeHTMLPipe,
  SafeURLPipe,
  DeepPipe,
  DistrictPipe,
  SlicePipe,
  HumanTimePipe,
  ImageCropPipe,
]
