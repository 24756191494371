import {
  Component,
  Input,
  OnInit,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from '@angular/core'
import { format } from 'date-fns'

import { WidgetComponent } from '../../view'
import { RowField } from '../../config'

/**
 * can use for: date, datetime
 */
@Component({
  selector: 'bp--preset--form-date-widget',
  template: `
    <nz-date-picker
      [nzShowTime]="isDatetime"
      [nzMode]="dateMode"
      [nzDisabled]="readonly"
      [ngModel]="date"
      (ngModelChange)="onChange($event)"
      (nzOnOpenChange)="handleDateOpenChange($event)"
    ></nz-date-picker>
  `,
})
export class BpFormDateWidget extends WidgetComponent
  implements OnInit, OnChanges {
  static meta = {
    type: 'preset_form',
    name: 'date',
  }

  @Input() entity: any
  @Input() value: string
  @Input() field: RowField

  change = new EventEmitter<string>()
  readonly = false
  isDatetime = false
  dateMode = 'time'
  date = null

  ngOnChanges(changes: SimpleChanges) {
    if (changes.value) {
      const currentValue = changes.value.currentValue

      if (currentValue) {
        this.date = this._dateTransform(currentValue)
      } else {
        this.date = null
      }
    }
  }

  ngOnInit() {
    this.readonly = this.field.feature.includes('readonly')
    this.isDatetime = this.field.extra === 'datetime'
  }

  onChange(date: Date) {
    const formatTpl = this.isDatetime ? 'yyyy-MM-dd HH:mm:ss' : 'yyyy-MM-dd'

    this.change.emit(date ? format(date, formatTpl) : null)
  }

  handleDateOpenChange(open: boolean): void {
    if (open && this.isDatetime) this.dateMode = 'time'
  }

  private _dateTransform(val: string) {
    return val && val.replace(' ', 'T')
  }
}
