import { Pipe, PipeTransform } from '@angular/core'

import { FieldType } from '../../../config'

export type PresetType =
  | 'preset_form'
  | 'preset_search'
  | 'preset_list'
  | 'preset_general'

@Pipe({
  name: 'widgetTransform',
})
export class WidgetTransformPipe implements PipeTransform {
  private _generalTypes = ['template']

  constructor() {}

  transform(
    data: { type: string; rootType: FieldType },
    presetType: PresetType,
  ) {
    const type = data.type
    let info

    if (
      data.rootType === 'custom' || type.includes('-')) {
      info = {
        type: 'custom',
        name: type,
      }
    } else {
      info = {
        type: presetType,
        name: type,
      }
    }

    if (this._isGeneralWidget(type))
      info = {
        type: 'preset_general',
        name: type,
      }

    return info
  }

  private _isGeneralWidget(type: string) {
    return this._generalTypes.includes(type)
  }
}
