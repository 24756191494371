import { Directive, Input, ViewContainerRef, TemplateRef } from '@angular/core'

import { AclField } from './acl_accessor'
import { AclService } from './acl_service'

export class BpAclContext {
  public $implicit: AclField
  public bpAcl: AclField
}

/**
 * usage: <div *bpAcl="aclField"></div>
 */
@Directive({
  selector: '[bpAcl]',
})
export class BpAcl {
  private _context = new BpAclContext()

  cache

  @Input()
  set bpAcl(field: AclField) {
    if (field === this.cache) return
    this.cache = field

    this._context.$implicit = this._context.bpAcl = field
    this._updateView()
  }

  constructor(
    private _aclService: AclService,
    private _vcRef: ViewContainerRef,
    private _tplRef: TemplateRef<any>,
  ) {}

  private _updateView() {
    this._vcRef.clear()

    if (this._context.bpAcl) {
      this._aclService.check(this._context.bpAcl).subscribe(ok => {
        if (ok) {
          this._vcRef.createEmbeddedView(this._tplRef, this._context)
        }
      })
    }
  }
}
