import { Component, OnInit } from '@angular/core'
import { SharedHttpService } from '~/shared/http/shared-http.service'

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.styl'],
})
export class AppHomeComponent implements OnInit {
  homeStat: any

  constructor(
    private _sharedHttp: SharedHttpService
  ) {

  }

  ngOnInit() {
    this._sharedHttp.getIndex().subscribe(res => {
      this.homeStat = res
    })
  }
}
