import { InjectionToken, Provider } from '@angular/core'
import { BpConfig } from '../config'

export const BP_STORE_MODULE_IDENTIFIER = new InjectionToken<BpConfig>(
  'Bp store module identifier',
)

export const BP_STORE_NAV_IDENTIFIER = new InjectionToken<BpConfig>(
  'Bp store nav identifier',
)

export function provideIdentifier(): Provider {
  return [
    {
      provide: BP_STORE_MODULE_IDENTIFIER,
      useValue: '__bp_module__',
    },
    {
      provide: BP_STORE_NAV_IDENTIFIER,
      useValue: '__bp_nav__',
    },
  ]
}
