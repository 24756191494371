import { Component, Input, OnInit, OnChanges, EventEmitter, SimpleChanges } from '@angular/core'

import { WidgetComponent } from '../../view'
import { RowField } from '../../config'

/**
 * can use for: switch
 */
@Component({
  selector: 'bp--preset--form-switch-widget',
  template: `
    <nz-switch
      [disabled]="readonly"
      [ngModel]="value"
      (ngModelChange)="change.emit($event ? 1 : 0)"
    ></nz-switch>
  `,
})
export class BpFormSwitchWidget extends WidgetComponent implements OnInit, OnChanges {
  static meta = {
    type: 'preset_form',
    name: 'switch',
  }

  @Input() entity: any
  @Input() value: boolean
  @Input() field: RowField

  change = new EventEmitter<0 | 1>()
  readonly = false

  ngOnChanges(changes: SimpleChanges) {
    if (changes.value) {
      const currentValue = changes.value.currentValue

      if (currentValue && currentValue == '1') {
        this.change.emit(1)
      } else {
        this.change.emit(0)
      }
    }
  }

  ngOnInit() {
    this.readonly = this.field.feature.includes('readonly')
  }
}
