import { BpListPage } from './list/list_page'
import { BpDragTree } from './list/drag_tree'
import { BpFormPage } from './form/form_page'
import { BpProfilePage } from './profile/profile_page'

import { PRESET_PAGES_MAP } from '../../provider'

export const PRESET_PAGES = [
  // list
  BpListPage,
  BpDragTree,
  // form
  BpFormPage,
  // profile
  BpProfilePage,
]

export { PIPES } from './pipes'

export function providePresetPagesMap() {
  return [
    {
      provide: PRESET_PAGES_MAP,
      useValue: {
        list: BpListPage,
        form: BpFormPage,
        profile: BpProfilePage,
      },
    },
  ]
}
