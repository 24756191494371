import { Injectable, Type, Inject } from '@angular/core'
import { Routes } from '@angular/router'

import { BpConfigRow, BpConfig, Module, RowModule, RouteData } from './model'
import { PRESET_PAGES_MAP } from '../provider'
import { AclGuard } from '../acl/acl_guard'

type PathCache = number[]

@Injectable({
  providedIn: 'root',
})
export class ConfigLoader {
  constructor(
    @Inject(PRESET_PAGES_MAP)
    private _presetPagesMap: { [props: string]: Type<any> },
  ) {}

  load(configRow: BpConfigRow): BpConfig {
    const m = this._resolveModule(configRow.module)
    const route = []

    this._resolveRoute(m, route)

    return {
      version: configRow.version,
      module: m,
      route,
      nav: configRow.nav,
    }
  }

  private _resolveModule(modules: RowModule[]): Module[] {
    const pathCache: {
      [props: number]: PathCache
    } = {}
    const nestModule: Module[] = []

    modules.forEach(m => {
      const module: Module = {
        ...m,
        parent: null,
      }

      if (!module.parent_id) {
        const i = nestModule.push(module) - 1
        pathCache[module.identifier] = [i]
      } else {
        const cache = pathCache[module.parent_id]
        const parentModule = this._getParentModule(nestModule, cache, 0)
        const i = parentModule.children.push(module) - 1

        // Set parent to child
        module.parent = parentModule

        pathCache[module.identifier] = [...cache, i]
      }
    })

    return nestModule
  }

  private _getParentModule(
    modules: Module[],
    cache: PathCache,
    i: number,
  ): Module {
    const m = modules[cache[i]]

    m.children = m.children || []

    if (i < cache.length - 1) {
      return this._getParentModule(m.children, cache, i + 1)
    } else {
      return m
    }
  }

  private _resolveRoute(modules: Module[], routes: Routes, path = ''): Routes {
    if (!modules || !modules.length) return routes

    modules.forEach(m => {
      const actions = m.action || []
      let _path = m.identifier
      if (path) _path = `${path}/${_path}`

      actions
        .filter(action => action.type === 'route' && action.enable)
        .forEach(action => {
          const { route, identifier } = action
          if (typeof route === 'undefined') {
            return
          }
          const actionPath =
            route.path[0] === '/' ? route.path.slice(1) : route.path
          const routePath = actionPath ? `${_path}/${actionPath}` : _path

          /**
           * Acl field is module or action's identifier array
           */
          const acl = _path.split('/').filter(s => !!s)

          acl.push(identifier)

          const routeData: RouteData = {
            module: m,
            acl,
            actionRoute: action,
            actionEntries: actions.filter(
              _action => _action.type === 'entry' && _action.enable,
            ),
            actionBatches: actions.filter(
              _action => _action.type === 'batch' && _action.enable,
            ),
            actionAcls: actions.filter(
              _action => _action.type === 'acl' && _action.enable,
            ),
            back: route.type !== 'list',
          }

          routes.push({
            path: routePath,
            component: this._presetPagesMap[route.type],
            canActivate: [AclGuard],
            data: routeData,
          })
        })

      if (m.children && m.children.length)
        this._resolveRoute(m.children, routes, _path)
    })
  }
}
