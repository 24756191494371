import {
  Injector,
  Injectable,
  Provider,
  APP_INITIALIZER,
  InjectionToken,
  Inject,
  Type,
} from '@angular/core'
import { Router, Routes } from '@angular/router'
import { Store } from '@ngrx/store'
import { Observable, Subject, ReplaySubject } from 'rxjs'

import {
  Event,
  ConfigLoaded,
  BeforeRouteReplace,
  RouteReplaced,
  BeforeConfigLoad,
} from './events'
import {
  BP_ROUTER_PLACEHOLDER,
  BP_DEFAULT_ROUTER_PLACEHOLDER,
  RouterLoader,
  ConfigLoader,
  provideConfigAccessor,
  BP_CONFIG_ACCESSOR,
  ConfigAccessor,
  provideConfig,
  CurrentConfig,
  BpConfig,
} from './config'
import { ModuleConfig } from './interface'
import * as fromStore from './store'
import * as ListActions from './store/list_actions'
import {
  BP_STORE_MODULE_IDENTIFIER,
  BP_STORE_NAV_IDENTIFIER,
} from './store/provide'

export const BP_INITIALIZER = new InjectionToken<BpInitializer>(
  'Bp Initializer',
)

export const DYNAMIC_COMPONENTS = new InjectionToken<Type<any>[]>(
  'Dynamic Components',
)

@Injectable({
  providedIn: 'root',
})
export class BpInitializer {
  readonly events: Observable<Event> = new Subject<Event>()

  constructor(
    public _currentConfig: CurrentConfig,
    private _injector: Injector,
    private _routerLoader: RouterLoader,
    private _configLoader: ConfigLoader,
    @Inject(BP_CONFIG_ACCESSOR) private _configAccessor: ConfigAccessor,
    private _store: Store<fromStore.State>,
    @Inject(BP_STORE_MODULE_IDENTIFIER) private _storeModuleIdentifier: string,
    @Inject(BP_STORE_NAV_IDENTIFIER) private _storeNavIdentifier: string,
  ) {}

  configInitializer() {
    const eventsSubject = this.events as Subject<Event>

    eventsSubject.next(new BeforeConfigLoad())

    this._configAccessor.get().subscribe(configRow => {
      const config = this._configLoader.load(configRow)
      const currentConfigSubject = this._currentConfig as ReplaySubject<
        BpConfig
      >

      /** Store modules & navs in ngrx */
      this._store.dispatch(
        new ListActions.LoadAll('identifier', this._storeModuleIdentifier, {
          data: configRow.module,
        }),
      )
      this._store.dispatch(
        new ListActions.LoadAll('name', this._storeNavIdentifier, {
          data: configRow.nav,
        }),
      )

      currentConfigSubject.next(config)
      eventsSubject.next(new ConfigLoaded(config, configRow))
    })
  }

  routerInitializer(routes: Routes) {
    const eventsSubject = this.events as Subject<Event>
    const router = this._injector.get(Router)
    const beforeReplacedConfig = router.config

    eventsSubject.next(new BeforeRouteReplace([...beforeReplacedConfig]))

    this._routerLoader.load(routes)
    eventsSubject.next(
      new RouteReplaced([...beforeReplacedConfig], [...router.config]),
    )
  }
}

export function bpInitializerFactory(bpInitializer: BpInitializer) {
  return () =>
    new Promise((resolve, reject) => {
      // init config
      bpInitializer.events.subscribe(e => {
        if (e instanceof ConfigLoaded) {
          bpInitializer.routerInitializer(e.config.route)
          resolve()
        }
      })
      bpInitializer.configInitializer()
    })
}

export function provideBpInitializer(config: ModuleConfig): Provider {
  return [
    provideConfigAccessor(config.staticConfig, config.production),
    provideConfig(),
    {
      provide: BP_ROUTER_PLACEHOLDER,
      useValue: BP_DEFAULT_ROUTER_PLACEHOLDER,
    },
    {
      provide: BP_INITIALIZER,
      useFactory: bpInitializerFactory,
      deps: [BpInitializer],
    },
    {
      provide: APP_INITIALIZER,
      useExisting: BP_INITIALIZER,
      multi: true,
    },
  ]
}
