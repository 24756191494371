import {
  Component,
  Input,
  OnInit,
  EventEmitter,
  OnDestroy,
} from '@angular/core'
import { BehaviorSubject, Subscription } from 'rxjs'
import { debounceTime, filter, switchMap } from 'rxjs/operators'

import { WidgetComponent } from '../../view'
import { RowField } from '../../config'
import { Payload } from '../../interface'
import { DataAccessorService } from '../../init_accessor'

/**
 * can use for: select, switch
 */
@Component({
  selector: 'bp--preset--search-relation-widget',
  template: `
    <nz-select
      nzAllowClear
      nzShowSearch
      nzNotFoundContent="输入关键词搜索"
      [nzPlaceHolder]="field.search.placeholder"
      [nzServerSearch]="true"
      (nzOnSearch)="searchChange$.next($event)"
      [ngModel]="value"
      (ngModelChange)="valueChange($event)"
      (nzOpenChange)="open($event)"
      (nzScrollToBottom)="loadMore()"
    >
      <nz-option
        *ngFor="let option of options"
        [nzCustomContent]="haveTemplate"
        [nzLabel]="option[field.search.label_key]"
        [nzValue]="option[field.search.relation_key]"
      >
        <span
          [innerHTML]="field.search.template | template: option | safeHTML"
        ></span>
      </nz-option>
      <nz-option *ngIf="loading" nzCustomContent nzDisabled>
        <i nz-icon nzType="loading" nzTheme="outline" class="loading-icon"></i>
        加载中...
      </nz-option>
    </nz-select>
  `,
})
export class BpSearchRelationWidget extends WidgetComponent
  implements OnInit, OnDestroy {
  static meta = {
    type: 'preset_search',
    name: 'relation',
  }

  @Input() entity: any
  @Input() value: string
  @Input() field: RowField

  change = new EventEmitter<string>()
  dispatch = new EventEmitter<Payload>()
  options: {
    [props: string]: string
  }[] = []
  haveTemplate = false
  loading = false
  nomore = false

  searchChange$ = new BehaviorSubject('')

  private _subs = new Subscription()
  private _firstOpen = false
  private _offset = 0
  private _keyword = ''

  constructor(private _dataAccessorService: DataAccessorService) {
    super()
  }

  loadMore(): void {
    if (this.nomore) {
      return
    }
    this.loading = true
    this._offset += 20

    this._relate(this._keyword, this._offset).subscribe(res => {
      if (res.data.length === 0) {
        this.nomore = true
      }
      this.loading = false
      this.options = [...this.options, ...res.data]
    })
  }

  ngOnInit() {
    this.haveTemplate = !!this.field.search.template

    // Init advise
    this._subs.add(
      this.searchChange$
        .pipe(
          debounceTime(500),
          switchMap(value => {
            this.loading = true
            this.nomore = false
            this._offset = 0
            this._keyword = value

            return this._relate(value, this._offset)
          }),
        )
        .subscribe(res => {
          this.loading = false
          this.options = res.data
        }),
    )
  }

  ngOnDestroy() {
    this._subs.unsubscribe()
  }

  valueChange(e: any) {
    this.change.emit(e)

    this.dispatch.emit({ type: 'search' })
  }

  open(o: boolean) {
    if (this._firstOpen || !o) return
    this._firstOpen = true
    this._offset = 0

    // Fetch advise list when init, once
    this._relate('', this._offset).subscribe(res => (this.options = res.data))
  }

  private _relate(value: string, offset: number) {
    return this._dataAccessorService.accessor.relate({
      type: 'advise',
      field: [
        ...this._dataAccessorService.moduleIdentifiers,
        this.field.identifier,
      ].join('.'),
      keyword: value,
      limit: 20,
      offset,
    })
  }
}
