import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { HttpClient } from '@angular/common/http'
import { ActivatedRoute } from '@angular/router'

import {
  DataAccessor,
  ListData,
  RelationData,
  RelateInfo,
} from './data_accessor_interface'

@Injectable()
export class HttpDataAccessor extends DataAccessor {
  constructor(private _http: HttpClient, private _aroute: ActivatedRoute) {
    super(_aroute)
  }

  getOne(): Observable<any> {
    return null
  }

  getOneById(id: number | string): Observable<any> {
    return this._http.get<any>(`${this.url}/${id}`).pipe(map(res => res.data))
  }

  getList(params: any): Observable<ListData<any>> {
    const meta: {
      index: number
      size: number
    } = params._meta

    delete params._meta
    console.log('meta', meta);
    if (meta)
      params = {
        ...params,
        offset: (meta.index - 1) * meta.size,
        limit: meta.size,
      }

    return this._http.get<ListData<any>>(this.url, { params })
  }

  create(data: any): Observable<any> {
    return this._http.post<any>(this.url, data)
  }

  update(data: any): Observable<any> {
    return null
  }

  updateById(id: number | string, data: any): Observable<any> {
    return this._http.put<any>(`${this.url}/${id}`, data)
  }

  delete(): Observable<any> {
    return null
  }

  deleteById(id: number | string): Observable<any> {
    return this._http.delete<any>(`${this.url}/${id}`)
  }

  batch(
    action: string,
    keys: number[] | string[],
    params: any,
  ): Observable<any> {
    return this._http.post<any>(`${this.url}/batch`, { action, keys, params })
  }

  sequence(ids: RelationData[]): Observable<any> {
    var data = ids.map(id => id.id)

    return this._http.post<any>(`${this.url}/sequence`, { ids: data })
  }

  relate(data: RelateInfo): Observable<any> {
    const params: any = data
    // Previous module id
    const related_id = this._getLatestSegmentId()

    if (related_id) {
      params.related_id = related_id
    }

    return this._http.get('advise', { params })
  }
}
