import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'

import { FlatLayoutComponent } from '~/core/layout/flat.component'
import {
  SelectivePreloadingStrategyService,
  RedirectService,
} from '~/core/services'
import { coreViews } from '~/core/views'
import { AuthGuard } from '~/auth/auth.guard'

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    component: FlatLayoutComponent,
    children: [
      { path: '$bp', children: [] },
      {
        path: '',
        component: coreViews.home,
        data: {
          title: '首页',
        },
      },
    ],
  },
]

const LOGIN_URL = '/auth/login'
const NEEDNT_SAVE_URL = [LOGIN_URL, '/auth/register']

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [SelectivePreloadingStrategyService],
})
export class AppRoutingModule {
  constructor(private redirectService: RedirectService) {
    redirectService.init({
      loginURL: LOGIN_URL,
      needtSaveURL: NEEDNT_SAVE_URL,
    })
  }
}
