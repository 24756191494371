// general
import { BpGeneralTemplateWidget } from './general_template'

// list
import { BpListTextWidget } from './list_text'
import { BpListTagWidget } from './list_tag'
import { BpListCheckboxWidget } from './list_checkbox'
import { BpListSwitchWidget } from './list_switch'
import { BpListImageWidget } from './list_image'

// form
import { BpFormTextWidget } from './form_text'
import { BpFormTextareaWidget } from './form_textarea'
import { BpFormDateWidget } from './form_date'
import { BpFormTimeWidget } from './form_time'
import { BpFormSelectWidget } from './form_select'
import { BpFormDistrictWidget } from './form_district'
import { BpFormCheckboxWidget } from './form_checkbox'
import { BpFormFileWidget } from './form_file'
import { BpFormImageWidget } from './form_image'
import { BpFormMediaWidget } from './form_media'
import { BpFormEditorWidget } from './form_editor'
import { BpFormSwitchWidget } from './form_switch'
import { BpFormMapWidget } from './form_map'
import { BpFormTagWidget } from './form_tag'
import { BpFormRelationWidget } from './form_relation'
import { BpFormRelationsWidget } from './form_relations'
import { BpFormCascaderWidget } from './form_cascader'

// list search
import { BpSearchTextWidget } from './search_text'
import { BpSearchSelectWidget } from './search_select'
import { BpSearchDateWidget } from './search_date'
import { BpSearchRelationWidget } from './search_relation'
import { BpSearchDistrictWidget } from './search_district'

export const PRESET_WIDGETS = [
  // general
  BpGeneralTemplateWidget,

  // list
  BpListTextWidget,
  BpListTagWidget,
  BpListCheckboxWidget,
  BpListSwitchWidget,
  BpListImageWidget,

  // form
  BpFormTextWidget,
  BpFormTextareaWidget,
  BpFormDateWidget,
  BpFormTimeWidget,
  BpFormSelectWidget,
  BpFormDistrictWidget,
  BpFormCheckboxWidget,
  BpFormFileWidget,
  BpFormImageWidget,
  BpFormMediaWidget,
  BpFormEditorWidget,
  BpFormSwitchWidget,
  BpFormMapWidget,
  BpFormTagWidget,
  BpFormRelationWidget,
  BpFormRelationsWidget,
  BpFormCascaderWidget,

  // list search
  BpSearchTextWidget,
  BpSearchSelectWidget,
  BpSearchDateWidget,
  BpSearchRelationWidget,
  BpSearchDistrictWidget,
]
