import { Injectable } from '@angular/core'
import { HttpParams } from '@angular/common/http'

@Injectable({
  providedIn: 'root',
})
export class HttpParamsService {
  constructor() {}

  transform(params: any): HttpParams {
    return Object.entries(params)
      .filter(([key, value]) => value !== null)
      .reduce((prev, [key, value]) => {
        return prev.set(key, String(value))
      }, new HttpParams())
  }

  cleanObject(params: any): any {
    return Object.entries(params)
      .filter(([key, value]) => value !== null)
      .reduce((prev, [key, value]) => {
        return {
          ...prev,
          [key]: value,
        }
      }, {})
  }
}
