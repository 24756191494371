export const defaultSelectIdFn = data => {
  const id = data.id

  if (!id) throw new Error(`Have no id property in ${data}`)

  return id
}

export interface EntityConfig<T> {
  selectId: (data: T) => number | string
  sortComparer?: Function | false
}

export class Entity<T> {
  ids: (string | number)[] = []
  entities: { [id: string]: T } = {}

  constructor(
    private data: T[],
    private config: EntityConfig<T> = {
      selectId: defaultSelectIdFn,
      sortComparer: false,
    },
  ) {
    this.addAll(data)
  }

  getOne(id: string | number) {
    return this.entities[id]
  }

  getAll() {
    return this.ids.map(id => this.entities[id])
  }

  addOne(data: T) {
    this._addOne(data)
    this._sort()
  }

  addMany(data: T[]) {
    data.forEach(d => {
      this._addOne(d)
    })
    this._sort()
  }

  addAll(data: T[]) {
    this.removeAll()
    this.addMany(data)
  }

  updateOne(data: T) {
    this._updateOne(data)
    this._sort()
  }

  updateMany(data: T[]) {
    data.forEach(d => {
      this._updateOne(d)
    })
    this._sort()
  }

  removeAll() {
    this.ids = []
    this.entities = {}
  }

  removeOne(id: string | number) {
    this.ids = this.ids.filter(_id => _id !== id)
    delete this.entities[id]
  }

  removeMany(ids: (string | number)[]) {
    ids.forEach(id => {
      this.removeOne(id)
    })
  }

  private _sort() {
    if (!this.config.sortComparer) return

    this.ids.sort(this.config.sortComparer as any)
  }

  private _addOne(data: T) {
    const id = this.config.selectId(data)

    this.entities[id] = data
    this.ids.push(id)
  }

  private _updateOne(data: T) {
    const id = this.config.selectId(data)
    const origin = this.entities[id]

    this.entities[id] = data
    if (!origin) this.ids.push(id)
  }
}
