import {
  Component,
  Input,
  ChangeDetectionStrategy,
  OnInit,
  OnChanges,
  ChangeDetectorRef,
  SimpleChanges,
} from '@angular/core'

import { WidgetComponent } from '../../view'
import { RowField } from '../../config'

/**
 * can use for: switch
 */
@Component({
  selector: 'bp--preset--list-switch-widget',
  template: `
    <i
      nz-icon
      nzType="check-circle"
      nzTheme="outline"
      *ngIf="value"
      class="switch-icon check"
    ></i>
    <i
      nz-icon
      nzType="close-circle"
      nzTheme="outline"
      *ngIf="!value"
      class="switch-icon close"
    ></i>
    <span>{{ value ? '是' : '否' }}</span>
  `,
  styles: [
    `
      .switch-icon {
        margin-right: 3px;
      }
      .switch-icon.check {
        color: #52c41a;
      }
      .switch-icon.close {
        color: #f5222d;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BpListSwitchWidget extends WidgetComponent
  implements OnInit, OnChanges {
  static meta = {
    type: 'preset_list',
    name: 'switch',
  }

  @Input() entity: any
  @Input() value: boolean
  @Input() field: RowField

  constructor(private _cdRef: ChangeDetectorRef) {
    super()
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.value) {
      this._cdRef.detectChanges()
    }
  }

  ngOnInit() {}
}
