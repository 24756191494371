import { Routes } from '@angular/router'

import { BpConfig, BpConfigRow } from './config'

export class BeforeConfigLoad {
  constructor() {}
}

export class ConfigLoaded {
  constructor(public config: BpConfig, public row: BpConfigRow) {}
}

export class BeforeRouteReplace {
  constructor(public routes: Routes) {}
}

export class RouteReplaced {
  constructor(public beforeRoutes: Routes, public routes: Routes) {}
}

export type Event =
  | BeforeConfigLoad
  | ConfigLoaded
  | BeforeRouteReplace
  | RouteReplaced
