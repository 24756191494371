import {
  Component,
  Input,
  ChangeDetectionStrategy,
  OnInit,
  OnChanges,
  SimpleChanges,
  ChangeDetectorRef,
} from '@angular/core'

import { WidgetComponent } from '../../view'
import { RowField } from '../../config'
import { isNone } from '../../util/is_none'

/**
 * can use for: text, select, district
 */
@Component({
  selector: 'bp--preset--list-text-widget',
  templateUrl: './list_text.html',
  styleUrls: ['./list_text.styl'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BpListTextWidget extends WidgetComponent
  implements OnInit, OnChanges {
  static meta = {
    type: 'preset_list',
    name: 'text',
  }

  @Input() entity: any
  @Input() value: string
  @Input() field: RowField

  nodata = false

  constructor(private _cdRef: ChangeDetectorRef) {
    super()
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.value) {
      this.nodata = isNone(this.value)
      this._cdRef.detectChanges()
    }
  }

  ngOnInit() {}
}
